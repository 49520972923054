import { ColorPalette, ToucanColors } from '@jointoucan/toucan-design';

import { TranslationStatus, WebPageProcessingStatus } from 'src/server/schema';

export const getTranslationPalette = ({
  translationStatus,
  processingStatus,
}: {
  translationStatus: TranslationStatus;
  processingStatus: WebPageProcessingStatus;
}): ColorPalette => {
  if (translationStatus === TranslationStatus.Valid && processingStatus === WebPageProcessingStatus.Done) {
    return ToucanColors.green;
  }
  if (processingStatus === WebPageProcessingStatus.Failed) {
    return ToucanColors.red;
  }
  if (processingStatus === WebPageProcessingStatus.Inprogress) {
    return ToucanColors.yellow;
  }
  if (processingStatus === WebPageProcessingStatus.Queued || translationStatus === TranslationStatus.Invalid) {
    return ToucanColors.teal;
  }
  if (translationStatus === TranslationStatus.Inactive) {
    return ToucanColors.gray;
  }
  return ToucanColors.blue;
};

export const isProcessing = (processingStatus: WebPageProcessingStatus): boolean =>
  processingStatus === WebPageProcessingStatus.Inprogress || processingStatus === WebPageProcessingStatus.Queued;
