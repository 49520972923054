import React, { useState, useEffect } from 'react';

import { ToucanColors, ToucanComponents } from '@jointoucan/toucan-design';
import { Alert, AlertTitle, Box, Paper } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useForm, Controller } from 'react-hook-form';

import { Typography } from 'src/components/Typography';
import { getDialogStyling } from 'src/functions/getDialogStyling';
import {
  IntervalUnit,
  SubscriptionPlan,
  UpdateSubscriptionPlanInput,
  useUpdateSubscriptionPlanMutation,
} from 'src/server/schema';

const { TextField, Button, Select, useColorScheme } = ToucanComponents;

type FormData = UpdateSubscriptionPlanInput;

interface EditPlanModalProps {
  plan?: SubscriptionPlan;
  onClose: () => void;
}

export const EditPlanDialog = ({ plan, onClose }: EditPlanModalProps) => {
  const { isDarkMode } = useColorScheme();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isTrialPeriodSectionVisible, setIsTrialPeriodSectionVisible] = useState(true);
  const [globalErrorMessage, setGlobalErrorMessage] = useState<string | null>(null);
  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors, isDirty },
  } = useForm<FormData>({
    defaultValues: {
      name: plan?.name,
      nameTranslationKey: plan?.nameTranslationKey,
      description: plan?.description,
      descriptionTranslationKey: plan?.descriptionTranslationKey,
      trialPeriod: {
        description: plan?.trialPeriod?.description,
        descriptionTranslationKey: plan?.trialPeriod?.descriptionTranslationKey,
        interval: {
          intervalUnit: plan?.trialPeriod?.interval.intervalUnit || undefined,
          count: plan?.trialPeriod?.interval?.count,
        },
      },
    },
  });
  const [updateSubscriptionPlanMutation, { loading, error }] = useUpdateSubscriptionPlanMutation();

  useEffect(() => {
    reset(plan);
    setIsModalVisible(!!plan);
  }, [plan]);

  if (!plan) {
    return null;
  }

  const onSavePlan = async (data: FormData) => {
    try {
      setGlobalErrorMessage(null);
      await updateSubscriptionPlanMutation({
        variables: {
          planId: plan.id,
          input: {
            name: data.name,
            nameTranslationKey: data.nameTranslationKey,
            description: data.description,
            descriptionTranslationKey: data.descriptionTranslationKey,
            trialPeriod: {
              description: data.trialPeriod?.description ?? '',
              descriptionTranslationKey: data.trialPeriod?.descriptionTranslationKey ?? '',
              interval: {
                intervalUnit: data.trialPeriod?.interval.intervalUnit ?? IntervalUnit.Day,
                count: data.trialPeriod?.interval.count ?? 1,
              },
            },
          },
        },
      });

      if (error) {
        throw new Error(error.message);
      }

      onClose();
    } catch (e: any) {
      const message = e && e.message ? e.message : 'Request failed';
      setGlobalErrorMessage(message);
    }
  };

  // Keep this for when we add the remove trial period button
  // const hideTrialPeriodSection = () => {
  //   setIsTrialPeriodSectionVisible(false);
  //   reset({
  //     trialPeriod: {
  //       description: '',
  //       descriptionTranslationKey: '',
  //       interval: {
  //         intervalUnit: IntervalUnit.Day,
  //         count: 1,
  //       },
  //     },
  //   });
  // };

  return (
    <Dialog
      open={isModalVisible}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      style={{
        backgroundColor: isDarkMode ? ToucanColors.gray[500] : ToucanColors.white,
        color: isDarkMode ? ToucanColors.gray[500] : ToucanColors.gray[100],
      }}
      sx={getDialogStyling(isDarkMode)}
    >
      <DialogTitle>
        <Typography variant="lg" font="secondary">
          Edit Plan
        </Typography>
        <Box mt={1}>
          <Typography variant="sm">Edit the plan details below and click save.</Typography>
        </Box>
      </DialogTitle>
      <DialogContent color={isDarkMode ? ToucanColors.gray[500] : ToucanColors.gray[100]}>
        <Box mt={1.25} display="flex" gap={1}>
          <Box flex={1}>
            <TextField
              label="Name"
              autoFocus
              hasError={!!errors.name}
              helperText={errors.name?.message}
              {...register('name', {
                required: 'Name is required',
              })}
            />
          </Box>
          <Box flex={1}>
            <TextField
              label="Name Translation Key"
              hasError={!!errors.nameTranslationKey}
              helperText={errors.nameTranslationKey?.message}
              {...register('nameTranslationKey', {
                required: 'Name translation key is required',
              })}
            />
          </Box>
        </Box>

        <Box mt={1.25} display="flex" gap={1}>
          <Box flex={1}>
            <TextField
              label="Description"
              hasError={!!errors.description}
              helperText={errors.description?.message}
              {...register('description', {
                required: 'Description is required',
              })}
            />
          </Box>
          <Box flex={1}>
            <TextField
              label="Description Translation Key"
              hasError={!!errors.descriptionTranslationKey}
              helperText={errors.descriptionTranslationKey?.message}
              {...register('descriptionTranslationKey', {
                required: 'Description translation key is required',
              })}
            />
          </Box>
        </Box>
        <Box mt={2}>
          <Paper variant="outlined">
            <Box p={2}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="md" isBold>
                  Trial Period
                </Typography>
                {isTrialPeriodSectionVisible ? (
                  <>
                    {/* Add remove button soon */}
                    {/* <Button type="alert" size="small" onClick={hideTrialPeriodSection}>
                        Remove
                      </Button> */}
                  </>
                ) : (
                  <Button size="small" onClick={() => setIsTrialPeriodSectionVisible(true)}>
                    + Add Free Trial Period
                  </Button>
                )}
              </Box>

              {isTrialPeriodSectionVisible && (
                <>
                  <Box mt={1.25} display="flex" gap={1}>
                    <Box flex={1}>
                      <TextField
                        label="Description"
                        hasError={!!errors.trialPeriod?.description}
                        helperText={errors.trialPeriod?.description?.message}
                        {...register('trialPeriod.description', {
                          required: 'Description is required',
                        })}
                      />
                    </Box>

                    <Box flex={1}>
                      <TextField
                        label="Description Translation Key"
                        hasError={!!errors.trialPeriod?.descriptionTranslationKey}
                        helperText={errors.trialPeriod?.descriptionTranslationKey?.message}
                        {...register('trialPeriod.descriptionTranslationKey', {
                          required: 'Description translation key is required',
                        })}
                      />
                    </Box>
                  </Box>

                  <Box mt={1.25} display="flex" gap={1}>
                    <Box flex={1}>
                      <TextField
                        label="Number of Intervals"
                        hasError={!!errors.trialPeriod?.interval?.count}
                        helperText={errors.trialPeriod?.interval?.count?.message}
                        type="number"
                        {...register('trialPeriod.interval.count', {
                          required: 'Number of intervals are required',
                          min: {
                            value: 0,
                            message: 'Count must be greater than 0',
                          },
                          valueAsNumber: true,
                        })}
                      />
                    </Box>
                    <Box flex={1} sx={{ '> div': { width: '100%' } }}>
                      <Controller
                        name="trialPeriod.interval.intervalUnit"
                        control={control}
                        rules={{ required: 'Interval unit is required' }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            value={field.value || ''}
                            label="Interval Unit"
                            emptyLabel="Select One"
                            isEmptyOptionVisible
                            hasError={!!errors.trialPeriod?.interval?.intervalUnit}
                            helperText={errors.trialPeriod?.interval?.intervalUnit?.message}
                            options={[
                              {
                                label: 'Day',
                                value: IntervalUnit.Day,
                              },
                              {
                                label: 'Week',
                                value: IntervalUnit.Week,
                              },
                              {
                                label: 'Month',
                                value: IntervalUnit.Month,
                              },
                              {
                                label: 'Year',
                                value: IntervalUnit.Year,
                              },
                            ]}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </Paper>
        </Box>
        {globalErrorMessage && (
          <Alert severity="error" sx={{ marginTop: 2 }}>
            <AlertTitle>Error</AlertTitle>
            {globalErrorMessage}
          </Alert>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          px: 2.5,
          pt: 0,
          pb: 2,
        }}
      >
        <Button onClick={onClose} type="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit(onSavePlan)} isDisabled={!isDirty || loading}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
