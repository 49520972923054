import { Box } from '@mui/material';

import { Typography } from '../Typography';

export const NoneSelected = () => (
  <Box display="flex" height="100%" alignItems="center" justifyContent="center" py={2} px={4} textAlign="center">
    <Typography variant="md" font="secondary" isBold>
      Please make a selection
    </Typography>
  </Box>
);
