import { useState } from 'react';

import { ToucanComponents } from '@jointoucan/toucan-design';
import { Box } from '@mui/material';
import { useParams } from 'react-router';

import { Typography } from 'src/components/Typography';
import { SubscriptionPlan, useGetSubscriptionProductQuery } from 'src/server/schema';

import { CreatePlanDialog } from './CreatePlanDialog';
import { EditPlanDialog } from './EditPlanDialog';
import { PlansList } from './PlansList';

const { Button } = ToucanComponents;

export const Plans = () => {
  const { id } = useParams<{ id: string }>();
  const { data, refetch } = useGetSubscriptionProductQuery({ variables: { productId: id } });
  const [isCreatePlanDialogOpen, setIsCreatePlanDialogOpen] = useState(false);
  const [planToEdit, setPlanToEdit] = useState<SubscriptionPlan>();

  const product = data?.getSubscriptionProduct;

  const handleCreatePlanStarted = () => {
    setIsCreatePlanDialogOpen(true);
  };

  const handleCreatePlanClosed = () => {
    setIsCreatePlanDialogOpen(false);
  };

  const handleCreatePlanSubmitted = () => {
    refetch({
      productId: id,
    });

    setIsCreatePlanDialogOpen(false);
  };

  const handleEditPlanStarted = (plan: SubscriptionPlan) => {
    setPlanToEdit(plan);
  };

  const handleEditPlanClosed = () => {
    setPlanToEdit(undefined);
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" p={2}>
        <Typography variant="lg" isBold>
          Plans
        </Typography>
        <Button size="small" onClick={handleCreatePlanStarted}>
          + Add New Plan
        </Button>
      </Box>
      {/* @ts-ignore */}
      <PlansList plans={product.plans} onEditList={handleEditPlanStarted} />
      <CreatePlanDialog
        onClose={handleCreatePlanClosed}
        onSubmit={handleCreatePlanSubmitted}
        open={isCreatePlanDialogOpen}
        productId={id}
      />
      <EditPlanDialog plan={planToEdit} onClose={handleEditPlanClosed} />
    </>
  );
};
