import { useEffect, useState } from 'react';

import { ApolloProvider } from '@apollo/client';
import { Global } from '@emotion/react';
import { ToucanColors, ToucanComponents } from '@jointoucan/toucan-design';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { useColorScheme } from 'use-color-scheme';

import { Authorization } from './components/Authorization';
import { Header } from './components/Header';
import { Layout } from './components/Layout';
import { Content } from './components/Main/Content';
import { NoneSelected } from './components/Main/NoneSelected';
import { Product } from './components/Main/Product/Product';
import { Translation } from './components/Main/Translation';
import { User } from './components/Main/User';
import { Sidebar } from './components/Sidebar';
import { StorageKeys } from './constants/storage';
import { RoutePath } from './enums/routePath';
import useLocalStorage from './hooks/useLocalStorage';
import { usePrevious } from './hooks/usePrevious';
import { ToucanEnvironments } from './lib/config';
import { createApolloInstance } from './server/apollo';

const { ColorScheme } = ToucanComponents;

function App() {
  const [env, setEnv] = useLocalStorage<ToucanEnvironments>(StorageKeys.Env, ToucanEnvironments.Staging);
  const [client, setClient] = useState(() => createApolloInstance(env));
  const prevEnv = usePrevious<ToucanEnvironments>(env);
  const { scheme } = useColorScheme();
  const isDarkMode = scheme === 'dark';

  useEffect(() => {
    if (env !== prevEnv) {
      client.clearStore().then(() => {
        setClient(createApolloInstance(env));
      });
    }
  }, [env, client, prevEnv]);

  return (
    <Router>
      <ApolloProvider client={client}>
        <ColorScheme isDarkMode={isDarkMode}>
          <link
            rel="stylesheet"
            type="text/css"
            href="https://fonts.googleapis.com/css?family=Karla:400,700|Lora:700&display=swap"
          />
          <Global
            styles={{
              'body, html, #root': {
                height: '100%',
                minHeight: '100%',
                maxHeight: '100%',
                width: '100%',
                minWidth: '100%',
                maxWidth: '100%',
                background: isDarkMode ? ToucanColors.gray[500] : ToucanColors.white,
              },
              '#root': {
                display: 'flex',
                flexDirection: 'row',
              },
            }}
          />
          <Authorization env={env} onEnvChange={setEnv}>
            <Layout headerContent={<Header />} sidebarContent={<Sidebar />}>
              <Switch>
                {/* TODO handled all */}
                <Route path={RoutePath.Product}>
                  <Product />
                </Route>
                <Route path={RoutePath.User}>
                  <User />
                </Route>
                <Route exact path={RoutePath.Translation}>
                  <Translation />
                </Route>
                <Route exact path={RoutePath.Content}>
                  <Content />
                </Route>
                <Route exact path={RoutePath.Home}>
                  Dashboard
                  <NoneSelected />
                </Route>
                <Route path={RoutePath.Product}>
                  <Content />
                </Route>
                <Route path={RoutePath.CatchAll}>
                  <NoneSelected />
                </Route>
              </Switch>
            </Layout>
          </Authorization>
        </ColorScheme>
      </ApolloProvider>
    </Router>
  );
}

export default App;
