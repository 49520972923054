export enum RoutePath {
  Audio = '/audio',
  CatchAll = '*',
  Content = '/content/:id',
  ContentExtensionStats = '/content/extension-stats',
  Contents = '/content',
  Hashtag = '#',
  Home = '/',
  Product = '/products/:id',
  ProductInfo = '/products/:id/info',
  ProductPlans = '/products/:id/plans',
  Products = '/products',
  Translation = '/translation/:url',
  Translations = '/translation',
  User = '/user/:id',
  UserInfo = '/user/:id/info',
  UserSubscription = '/user/:id/subscription',
  UserSaved = '/user/:id/saved',
  UserReports = '/user/:id/reports',
  UserErrors = '/user/:id/errors',
  UserExperiments = '/user/:id/experiments',
  Users = '/user',
}
