import { createContext, FC, useContext, useMemo } from 'react';

import { css } from '@emotion/css';
import { IconName, ToucanColors, ToucanComponents } from '@jointoucan/toucan-design';
import { Box, BoxProps, alpha } from '@mui/material';
import { motion } from 'framer-motion';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';

interface TabInterface {
  to: string;
  label: string;
  icon: IconName;
  onClick?: () => void;
}

const { useColorScheme, ToucanIcon, Typography } = ToucanComponents;

const context = createContext<{ id: string }>({ id: 'default ' });

export const Tab: FC<TabInterface> = ({ to, label, icon, onClick }) => {
  const { isDarkMode } = useColorScheme();
  const match = useRouteMatch({
    path: to,
  });
  const { id } = useContext(context);

  const isRoute = !!match;
  const colorActive = isDarkMode ? ToucanColors.teal[300] : ToucanColors.teal[500];
  const color = isDarkMode ? ToucanColors.gray[100] : ToucanColors.gray[500];
  const iconColorIdle = ToucanColors.gray[300];
  const iconColorActive = isDarkMode ? ToucanColors.teal[300] : ToucanColors.teal[500];

  return (
    <Link
      to={to}
      onClick={onClick}
      className={css({
        display: 'flex',
        flexDirection: 'column',
        margin: '16px 0 0 0',
        backgroundColor: 'transparent',
        alignItems: 'center',
        transition: 'all 0.2s ease-in-out',
        boxShadow: `inset 0px 0px 0px ${alpha(colorActive, 0.5)}`,
        '& h5': {
          color: `${isRoute ? colorActive : color}`,
        },
        '&:hover': {
          boxShadow: `inset 0px -3px 0px ${alpha(colorActive, 0.5)}`,
          textDecoration: 'none',
        },
      })}
    >
      <Box display="inline-flex" flexDirection="row" flex="1" pb={2} px={2}>
        <ToucanIcon
          icon={icon}
          className={css({
            width: '20px',
            height: '20px',
            marginRight: '8px',
            color: isRoute ? iconColorActive : iconColorIdle,
          })}
        />

        <Typography variant="xs" isBold>
          {label}
        </Typography>
      </Box>
      {isRoute && <Box component={motion.div} width="100%" height="3px" layoutId={id} bgcolor={iconColorActive} />}
    </Link>
  );
};

export const Tabs: FC<BoxProps & { layoutId: string }> = ({ children, layoutId, ...boxProps }) => {
  const contextValues = useMemo(() => ({ id: layoutId }), []);
  return (
    <context.Provider value={contextValues}>
      <motion.div layout>
        <Box display="flex" flexDirection="row" pt={2} {...boxProps}>
          {children}
        </Box>
      </motion.div>
    </context.Provider>
  );
};
