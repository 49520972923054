import { FC } from 'react';

import { Box } from '@mui/material';
import { format } from 'date-fns';

import { Typography } from 'src/components/Typography';

interface RatingProps {
  value: number;
  ratedAt: string;
}

const stars = [1, 2, 3, 4, 5];

export const Rating: FC<RatingProps> = ({ value, ratedAt }) => (
  <Box display="flex" flexDirection="column" flex="1">
    <Typography variant="sm" font="secondary">
      Rated at {format(new Date(ratedAt), 'MMM dd yyyy')}
    </Typography>
    <Box display="flex" flexDirection="row" pt={1}>
      {stars.map(starValue => (
        <Box
          component="img"
          // @ts-ignore
          src={`/images/rating/${starValue <= value ? 'star-clicked.svg' : 'star-default.svg'}`}
          alt="star"
          mr={2}
        />
      ))}
    </Box>
  </Box>
);
