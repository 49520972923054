import { useState } from 'react';

import { ToucanColors, ToucanComponents } from '@jointoucan/toucan-design';
import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Link,
  Paper,
} from '@mui/material';
import { capitalize } from 'lodash';
import { useForm, Controller } from 'react-hook-form';

import { Typography } from 'src/components/Typography';
import { getDialogStyling } from 'src/functions/getDialogStyling';
import {
  CreateSubscriptionPlanInput,
  CurrencyCode,
  IntervalUnit,
  PaymentCountryCode,
  useCreateSubscriptionPlanMutation,
} from 'src/server/schema';

const { TextField, Button, Select, useColorScheme } = ToucanComponents;

export type PlanDraft = CreateSubscriptionPlanInput;

interface CreatePlanDialogProps extends Omit<DialogProps, 'onSubmit'> {
  onClose: () => void;
  productId: string;
  onSubmit: ({ planId }: { planId?: string }) => void;
}

export const CreatePlanDialog = ({ onClose, onSubmit, productId, ...props }: CreatePlanDialogProps) => {
  const { isDarkMode } = useColorScheme();
  const [createSubscriptionPlan, { error, loading }] = useCreateSubscriptionPlanMutation();
  const [isTrialPeriodSectionVisible, setIsTrialPeriodSectionVisible] = useState(true);
  const [globalErrorMessage, setGlobalErrorMessage] = useState<string | null>(null);

  const {
    formState: { errors, isDirty },
    control,
    handleSubmit: createSubmitHandler,
    register,
  } = useForm<PlanDraft>({
    defaultValues: {
      description: '',
      descriptionTranslationKey: '',
      locale: '',
      name: '',
      nameTranslationKey: '',
      price: {
        amount: undefined,
        currencyCode: undefined,
      },
      recurringInterval: {
        count: undefined,
        intervalUnit: undefined,
      },
    },
    shouldUnregister: true,
  });

  const onCloseModal = () => {
    setGlobalErrorMessage(null);

    onClose();
  };

  const handleSubmitted = createSubmitHandler(async draft => {
    try {
      setGlobalErrorMessage(null);

      const { data } = await createSubscriptionPlan({
        variables: {
          input: {
            description: draft.description,
            descriptionTranslationKey: draft.descriptionTranslationKey,
            locale: draft.locale,
            name: draft.name,
            nameTranslationKey: draft.nameTranslationKey,
            price: draft.price,
            recurringInterval: draft.recurringInterval,
            ...(draft.trialPeriod?.description && {
              trialPeriod: {
                description: draft.trialPeriod?.description ?? '',
                descriptionTranslationKey: draft.trialPeriod?.descriptionTranslationKey ?? '',
                interval: {
                  intervalUnit: draft.trialPeriod?.interval.intervalUnit ?? IntervalUnit.Day,
                  count: draft.trialPeriod?.interval.count ?? 1,
                },
              },
            }),
          },
          productId,
        },
      });

      if (error) {
        throw new Error(error.message);
      }

      const createdPlan = data?.createSubscriptionPlan;

      onSubmit({ planId: createdPlan?.id });
    } catch (e: any) {
      const message = e && e.message ? e.message : 'Request failed';
      setGlobalErrorMessage(message);
    }
  });

  return (
    <Dialog maxWidth="sm" fullWidth sx={getDialogStyling(isDarkMode)} {...props}>
      <DialogTitle component="div">
        <Typography variant="lg" font="secondary">
          Create a Plan
        </Typography>
        <Box mt={1}>
          <Typography variant="sm" color={ToucanColors.gray[400]}>
            Provide third-party plan details
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="xs">
          Note: Translation key fields map to the&nbsp;
          <Link
            href="https://docs.google.com/spreadsheets/d/1lrvA2PhmPZoY-cdPxOnsHficbvCuTUHYECqkN9PHroc/edit#gid=0"
            target="_blank"
          >
            i18n translation sheets
          </Link>
        </Typography>
        <Box mt={1.25} display="flex" gap={1}>
          <Box flex={1}>
            <TextField
              label="Name"
              autoFocus
              hasError={!!errors.name}
              helperText={errors.name?.message}
              {...register('name', {
                required: 'Name is required',
              })}
            />
          </Box>
          <Box flex={1}>
            <TextField
              label="Name Translation Key"
              hasError={!!errors.nameTranslationKey}
              helperText={errors.nameTranslationKey?.message}
              {...register('nameTranslationKey', {
                required: 'Name translation key is required',
              })}
            />
          </Box>
        </Box>

        <Box mt={1.25} display="flex" gap={1}>
          <Box flex={1}>
            <TextField
              label="Description"
              hasError={!!errors.description}
              helperText={errors.description?.message}
              {...register('description', {
                required: 'Description is required',
              })}
            />
          </Box>
          <Box flex={1}>
            <TextField
              label="Description Translation Key"
              hasError={!!errors.descriptionTranslationKey}
              helperText={errors.descriptionTranslationKey?.message}
              {...register('descriptionTranslationKey', {
                required: 'Description translation key is required',
              })}
            />
          </Box>
        </Box>
        <Box mt={1.25} sx={{ '> div': { width: '100%' } }}>
          <Typography variant="xs">
            Note: This field will be used to specify which plans are seen in that country. Defaults to US plans.
          </Typography>
          <Controller
            name="locale"
            control={control}
            rules={{ required: 'Locale is required' }}
            render={({ field }) => (
              <Select
                {...field}
                label="Country Code"
                emptyLabel="Select One"
                isEmptyOptionVisible
                hasError={!!errors.locale}
                helperText={errors.locale?.message}
                options={Object.values(PaymentCountryCode).map(value => ({
                  label: capitalize(value),
                  value,
                }))}
              />
            )}
          />
        </Box>
        <Paper sx={{ mt: 2, p: 2 }} variant="outlined">
          <Typography isBold variant="md">
            Price
          </Typography>
          <Box mt={1.25} display="flex" gap={1}>
            <Box flex={1}>
              <TextField
                label="Amount"
                hasError={!!errors.price?.amount}
                helperText={errors.price?.amount?.message}
                type="number"
                {...register('price.amount', {
                  required: 'Amount is required',
                  min: {
                    value: 0,
                    message: 'Amount must at least be 0',
                  },
                  valueAsNumber: true,
                })}
              />
            </Box>
            <Box flex={1} sx={{ '> div': { width: '100%' } }}>
              <Controller
                name="price.currencyCode"
                control={control}
                rules={{ required: 'Currency code is required' }}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Currency Code"
                    emptyLabel="Select One"
                    isEmptyOptionVisible
                    hasError={!!errors.price?.currencyCode}
                    helperText={errors.price?.currencyCode?.message}
                    options={Object.values(CurrencyCode).map(value => ({
                      label: value.toUpperCase(),
                      value,
                    }))}
                  />
                )}
              />
            </Box>
          </Box>
        </Paper>
        <Paper sx={{ mt: 2, p: 2 }} variant="outlined">
          <Typography variant="md" isBold>
            Interval
          </Typography>
          <Box mt={1.25} display="flex" gap={1}>
            <Box flex={1}>
              <TextField
                label="Number of Intervals"
                hasError={!!errors.recurringInterval?.count}
                helperText={errors.recurringInterval?.count?.message}
                type="number"
                {...register('recurringInterval.count', {
                  required: 'Number of Intervals are required',
                  min: {
                    value: 0,
                    message: 'Number of Intervals must at least be 0',
                  },
                  valueAsNumber: true,
                })}
              />
            </Box>
            <Box flex={1} sx={{ '> div': { width: '100%' } }}>
              <Controller
                name="recurringInterval.intervalUnit"
                control={control}
                rules={{ required: 'Interval unit is required' }}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Unit"
                    emptyLabel="Select One"
                    isEmptyOptionVisible
                    hasError={!!errors.recurringInterval?.intervalUnit}
                    helperText={errors.recurringInterval?.intervalUnit?.message}
                    options={Object.values(IntervalUnit).map(value => ({
                      label: capitalize(value),
                      value,
                    }))}
                  />
                )}
              />
            </Box>
          </Box>
        </Paper>

        <Box mt={2}>
          <Paper variant="outlined">
            <Box p={2}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="md" isBold>
                  Trial Period
                </Typography>
                {isTrialPeriodSectionVisible ? (
                  <Button type="alert" size="small" onClick={() => setIsTrialPeriodSectionVisible(false)}>
                    Remove
                  </Button>
                ) : (
                  <Button size="small" onClick={() => setIsTrialPeriodSectionVisible(true)}>
                    + Add Free Trial Period
                  </Button>
                )}
              </Box>

              {isTrialPeriodSectionVisible && (
                <>
                  <TextField
                    label="Description"
                    margin="dense"
                    hasError={!!errors.trialPeriod?.description}
                    helperText={errors.trialPeriod?.description?.message}
                    {...register('trialPeriod.description', {
                      required: 'Description is required',
                    })}
                  />

                  <TextField
                    label="Description Translation Key"
                    margin="dense"
                    hasError={!!errors.trialPeriod?.descriptionTranslationKey}
                    helperText={errors.trialPeriod?.descriptionTranslationKey?.message}
                    {...register('trialPeriod.descriptionTranslationKey', {
                      required: 'Description translation key is required',
                    })}
                  />

                  <Box mt={1.25} display="flex" gap={1}>
                    <Box flex={1}>
                      <TextField
                        label="Number of Intervals"
                        hasError={!!errors.trialPeriod?.interval?.count}
                        helperText={errors.trialPeriod?.interval?.count?.message}
                        type="number"
                        {...register('trialPeriod.interval.count', {
                          required: 'Number of intervals are required',
                          min: {
                            value: 0,
                            message: 'Count must be greater than 0',
                          },
                          valueAsNumber: true,
                        })}
                      />
                    </Box>
                    <Box flex={1} sx={{ '> div': { width: '100%' } }}>
                      <Controller
                        name="trialPeriod.interval.intervalUnit"
                        control={control}
                        rules={{ required: 'Interval unit is required' }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            label="Interval Unit"
                            emptyLabel="Select One"
                            isEmptyOptionVisible
                            hasError={!!errors.trialPeriod?.interval?.intervalUnit}
                            helperText={errors.trialPeriod?.interval?.intervalUnit?.message}
                            options={[
                              {
                                label: 'Day',
                                value: IntervalUnit.Day,
                              },
                              {
                                label: 'Week',
                                value: IntervalUnit.Week,
                              },
                              {
                                label: 'Month',
                                value: IntervalUnit.Month,
                              },
                              {
                                label: 'Year',
                                value: IntervalUnit.Year,
                              },
                            ]}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </Paper>
        </Box>

        {globalErrorMessage && (
          <Alert severity="error" sx={{ marginTop: 2 }}>
            <AlertTitle>Error</AlertTitle>
            {globalErrorMessage}
          </Alert>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          px: 2.5,
          pt: 0,
          pb: 2,
        }}
      >
        <Button type="secondary" onClick={onCloseModal}>
          Cancel
        </Button>
        <Button onClick={handleSubmitted} disabled={loading || !isDirty} endIcon={loading ?? <CircularProgress />}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};
