import { RoutePath } from 'src/enums/routePath';

import { Tab, Tabs } from '../Tabs';

export const HeaderTabs = () => (
  <Tabs pt={0} layoutId="app">
    <Tab to={RoutePath.Users} label="Users" icon="user" />
    <Tab to={RoutePath.Content} label="Content" icon="pen" />
    <Tab to={RoutePath.Products} label="Products" icon="pen" />
    <Tab to={RoutePath.Audio} label="Audio" icon="play" />
  </Tabs>
);
