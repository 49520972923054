import { memo, useState } from 'react';

import { ToucanComponents } from '@jointoucan/toucan-design';
import { Alert, AlertTitle, Box } from '@mui/material';
import { useHistory } from 'react-router';

import { CreateProductDialog } from 'src/components/Main/Product/CreateProductDialog';
import { RoutePath } from 'src/enums/routePath';
import { useRouteParams } from 'src/hooks/useRouterParams';
import { SubscriptionProduct, useGetSubscriptionProductsQuery } from 'src/server/schema';

import { Typography } from '../../Typography';

import { ProductCard } from './ProductCard';

const { Button } = ToucanComponents;

export const ProductSidebar = memo(() => {
  const { data, error, loading, refetch } = useGetSubscriptionProductsQuery({
    variables: {
      filters: {},
    },
  });

  const history = useHistory();
  const [isCreateProductDialogOpen, setIsCreateProductDialogOpen] = useState(false);
  const { id: selectedId } = useRouteParams<{ id: string }>(RoutePath.Product);

  const handleCreateClicked = () => {
    setIsCreateProductDialogOpen(true);
  };

  const handleCreateCancelled = () => {
    setIsCreateProductDialogOpen(false);
  };

  const handleCreateSubmitted = ({ productId }: { productId?: string }) => {
    refetch({
      filters: {},
    });

    if (productId) {
      history.push(RoutePath.ProductInfo.replace(':id', productId));
    }

    setIsCreateProductDialogOpen(false);
  };

  const handleProductClicked = (product: SubscriptionProduct) => {
    history.push(RoutePath.ProductInfo.replace(':id', product.id));
  };

  const products = data?.getSubscriptionProducts || [];

  return (
    <>
      <Box display="flex" flexDirection="row" justifyContent="space-between" p={4}>
        <Typography variant="lg" isBold>
          Products
        </Typography>
        <Button size="small" onClick={handleCreateClicked} variant="contained">
          + Add New Product
        </Button>
      </Box>
      {products.map(product => (
        <ProductCard
          key={product.id}
          isActive={product.id === selectedId}
          onClick={handleProductClicked}
          product={product as SubscriptionProduct}
        />
      ))}
      {error && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {error.message}
        </Alert>
      )}
      {!loading && products.length === 0 && (
        <Box px={4} py={2}>
          <Typography variant="md" font="secondary">
            No products found
          </Typography>
        </Box>
      )}
      {loading && products.length === 0 && (
        <Box px={4} py={2}>
          <Typography variant="md" font="secondary">
            Loading products...
          </Typography>
        </Box>
      )}
      <CreateProductDialog
        onCancel={handleCreateCancelled}
        onSubmit={handleCreateSubmitted}
        open={isCreateProductDialogOpen}
      />
    </>
  );
});
