import React, { FC } from 'react';

import { css } from '@emotion/css';
import { ToucanColors, ToucanComponents } from '@jointoucan/toucan-design';
import { Box, CircularProgress, useTheme } from '@mui/material';
import { useParams } from 'react-router';

import { Break } from 'src/components/Break';
import { Flag } from 'src/components/Flag';
import { Typography } from 'src/components/Typography';
import { LanguageId, TranslationInaccuracyError, useGetUserInaccuraciesQuery } from 'src/server/schema';

const { Button, useColorScheme, ToucanIcon } = ToucanComponents;

/**
 *
 * @param variationId The variation for inaccuracies.
 * the format allows us to have source, and target language in the id `en:es:uuid`
 * so we just pull those off the id.
 */
const getSourceAndTargetLanguageById = (variationId: string) => {
  const [sourceLanguage, targetLanguage] = variationId.split(':');
  return { sourceLanguage: sourceLanguage as LanguageId, targetLanguage: targetLanguage as LanguageId };
};

const formatURL = (url: string, limit: number = 25): string => {
  let mutableUrl: string = url.split('://').pop()?.split('?').shift()?.split('#').shift() ?? url;
  const [domain, ...paths] = mutableUrl.split('/');
  const path = paths.join('/');
  if (path.length > limit) {
    mutableUrl = `${domain}/...${path.slice(-1 * limit)}`;
  }
  if (mutableUrl.match(/\/$/)) {
    mutableUrl = mutableUrl.slice(0, -1);
  }
  return mutableUrl;
};

export const ReportTag: FC<{ type: TranslationInaccuracyError }> = ({ type }) => {
  const info: Record<TranslationInaccuracyError, { color: string }> = {
    [TranslationInaccuracyError.Context]: {
      color: ToucanColors.red[300],
    },
    [TranslationInaccuracyError.Misspelling]: {
      color: ToucanColors.yellow[400],
    },
    [TranslationInaccuracyError.Grammar]: {
      color: ToucanColors.blue[300],
    },
  };

  return (
    <Box
      mr={2}
      alignItems="center"
      display="flex"
      p={0.7}
      className={css({
        backgroundColor: info[type].color,
        borderRadius: '8px',
      })}
    >
      <Typography
        variant="2xs"
        className={css({
          color: ToucanColors.white,
          textTransform: 'uppercase',
          letterSpacing: '0.1em',
        })}
        isBold
      >
        {type}
      </Typography>
    </Box>
  );
};

export const Reports = () => {
  const theme = useTheme();
  const { isDarkMode } = useColorScheme();
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useGetUserInaccuraciesQuery({ variables: { id } });
  const reports = (data?.user?.translationInaccuracies ?? []).map(report => {
    if (!report.variationId) {
      return {
        ...report,
        targetLanguage: report.language,
        sourceLanguage: null,
      };
    }
    const { sourceLanguage, targetLanguage } = getSourceAndTargetLanguageById(report.variationId ?? '');
    return {
      ...report,
      sourceLanguage,
      targetLanguage,
    };
  });
  return (
    <Box position="relative">
      <Box zIndex="1" position="sticky" top={0} bgcolor={isDarkMode ? ToucanColors.gray[500] : ToucanColors.white}>
        <Box px={4} py={2}>
          <Typography variant="md" font="secondary">
            Translation inaccuracies reported {reports.length ? `( ${reports.length} )` : ''}
          </Typography>
        </Box>
        <Break size={0.1} />
      </Box>
      {(() => {
        if (loading) {
          return (
            <Box p={4}>
              <CircularProgress />
            </Box>
          );
        }

        return (
          <>
            {reports.length === 0 && (
              <Box px={4} py={2}>
                <Typography variant="sm">User has no inaccuracies reports</Typography>
              </Box>
            )}
            {reports.map(report => (
              <React.Fragment key={report.word}>
                <Box px={4} py={3}>
                  <Box display="flex" flexDirection="column" pb={1}>
                    <Box display="flex">
                      <Box
                        pt={1}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        className={css({
                          '& svg': {
                            color: isDarkMode ? ToucanColors.gray[400] : ToucanColors.gray[200],
                            marginBottom: `${theme.spacing(0.5)}`,
                          },
                        })}
                      >
                        {report.sourceLanguage && (
                          <>
                            <Flag language={report.sourceLanguage} size={36} />
                            <ToucanIcon icon="chevron-down" />
                          </>
                        )}

                        {report.targetLanguage && <Flag language={report.targetLanguage} size={36} />}
                      </Box>
                      <Box pl={2}>
                        <Box display="flex">
                          <Box lang={report.language}>
                            <Typography variant="md" font="secondary">
                              {report.translatedPhrase}
                            </Typography>
                          </Box>
                        </Box>
                        <Typography variant="sm" isBold>
                          {report.word}
                        </Typography>
                        <Box pt={2} pb={2}>
                          <Typography variant="sm">{report.sentenceWithoutTranslation}</Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box pl={3} />
                  </Box>
                  <Box pl={6} display="flex" flexDirection="row" alignItems="center">
                    <Box alignItems="center" display="flex">
                      <ReportTag type={report.errorType as TranslationInaccuracyError} />
                    </Box>
                    <Button isDarkMode={isDarkMode} type="text" target="_blank" href={report.reportedUrl}>
                      {formatURL(report.reportedUrl ?? '', 30)}
                    </Button>
                  </Box>
                </Box>
                <Break size={0.1} />
              </React.Fragment>
            ))}
          </>
        );
      })()}
    </Box>
  );
};
