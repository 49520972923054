import { ChangeEvent, FormEvent, memo, useEffect, useRef, useState } from 'react';

import { css } from '@emotion/css';
import { ToucanColors, ToucanComponents } from '@jointoucan/toucan-design';
import { Box, CircularProgress } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';

import { useGetExtensionStatsQuery, useUpdateExtensionStatsMutation } from 'src/server/schema';

const { TextField, Typography, Button, useColorScheme } = ToucanComponents;

export const ExtensionStats = memo(() => {
  const { isDarkMode } = useColorScheme();
  const { data, error, refetch } = useGetExtensionStatsQuery();
  const [updateExtensionStats, { loading: mutationLoading }] = useUpdateExtensionStatsMutation();
  const [mutableUserAmount, setUserAmount] = useState(0);
  const [mutableReviewAmount, setReviewAmount] = useState(0);
  const [valuesUpdated, setValuesUpdated] = useState(false);
  const timestamp = useRef<number>(0);

  const userAmount = data?.extensionStat?.users;
  const reviewAmount = data?.extensionStat?.reviews;

  const canSubmit =
    (userAmount && mutableUserAmount !== userAmount) || (reviewAmount && mutableReviewAmount !== reviewAmount);

  const onSubmit = async (e: FormEvent<HTMLFormElement | HTMLButtonElement>) => {
    e.preventDefault();
    if (mutationLoading || !canSubmit) {
      return;
    }
    await updateExtensionStats({ variables: { attr: { users: mutableUserAmount, reviews: mutableReviewAmount } } });
    await refetch();
    clearTimeout(timestamp.current);
    setValuesUpdated(true);
    timestamp.current = window.setTimeout(() => {
      setValuesUpdated(false);
    }, 3000);
  };

  useEffect(() => {
    if (typeof userAmount === 'number' && typeof reviewAmount === 'number') {
      setUserAmount(userAmount);
      setReviewAmount(reviewAmount);
    }
  }, [userAmount, reviewAmount]);

  return (
    <Box display="flex" flexDirection="column" pt={5}>
      <Box pb={1}>
        <Typography
          variant="lg"
          font="secondary"
          isBold
          color={isDarkMode ? ToucanColors.white : ToucanColors.gray[600]}
        >
          Extension Stats
        </Typography>
      </Box>
      <Box pb={5}>
        <Typography variant="sm" color={isDarkMode ? ToucanColors.white : ToucanColors.gray[600]}>
          These stats are shown on the homepage of the website and represent the current number of users across all
          platforms.
        </Typography>
      </Box>
      {(() => {
        if (error) {
          return (
            <Box p={4} bgcolor={ToucanColors.red[300]} borderRadius="8px">
              <Typography variant="sm" color={ToucanColors.white}>
                {error.message}
              </Typography>
            </Box>
          );
        }

        return (
          <Box
            component="form"
            onSubmit={onSubmit}
            className={css({
              '& svg': {
                color: ToucanColors.white,
              },
            })}
          >
            <Box pb={3}>
              <TextField
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setUserAmount(Number(e.target.value));
                }}
                value={mutableUserAmount}
                type="number"
                label="Extension user amount"
                isDarkMode={isDarkMode}
              />
            </Box>
            <Box pb={3}>
              <TextField
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setReviewAmount(Number(e.target.value));
                }}
                value={mutableReviewAmount}
                type="number"
                label="Extension rating amount"
                isDarkMode={isDarkMode}
              />
            </Box>
            <Box
              pb={3}
              display="flex"
              className={css({
                opacity: !canSubmit ? 0.5 : 1,
              })}
              alignItems="center"
            >
              <Button disabled={!canSubmit} onClick={onSubmit}>
                Update
              </Button>

              <AnimatePresence>
                {mutationLoading ? (
                  <motion.div
                    key="loading"
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ ease: 'easeIn', duration: 0.3 }}
                    exit={{ opacity: 0, y: 10, transition: { ease: 'easeOut', duration: 0.1 } }}
                  >
                    <Box pl={2}>
                      <CircularProgress size="16px" />
                    </Box>
                  </motion.div>
                ) : null}
                {valuesUpdated && !mutationLoading ? (
                  <motion.div
                    key="msg"
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ ease: 'easeIn', duration: 0.3 }}
                    exit={{ opacity: 0, y: 10, transition: { ease: 'easeOut', duration: 0.1 } }}
                  >
                    <Box pl={2}>
                      <Typography variant="sm" color={isDarkMode ? ToucanColors.white : ToucanColors.gray[600]}>
                        Updated, check it out!
                      </Typography>
                    </Box>
                  </motion.div>
                ) : null}
              </AnimatePresence>
            </Box>
          </Box>
        );
      })()}
    </Box>
  );
});
