import { memo, useEffect, useState } from 'react';

import { css } from '@emotion/css';
import { ToucanColors, ToucanComponents } from '@jointoucan/toucan-design';
import { Box } from '@mui/material';
import { useParams } from 'react-router';

import { Break } from 'src/components/Break';
import { getFaviconUrl } from 'src/lib/favico';
import { filterNull } from 'src/lib/utils';
import { LanguageId, useWebPageResultQuery } from 'src/server/schema';

import { Table } from '../../Table';
import { Typography } from '../../Typography';
import { Languages } from '../User/Languages';

const { Button, useColorScheme } = ToucanComponents;

interface UserParams {
  url: string;
}

const InitialPageAmount = 300;

export const Translation = memo(() => {
  const { isDarkMode } = useColorScheme();
  const { url: encodedURL } = useParams<UserParams>();
  const [page, setPage] = useState<number>(0);
  const [pageAmount] = useState(InitialPageAmount);
  const url = decodeURIComponent(encodedURL);
  const { data, loading } = useWebPageResultQuery({
    variables: { input: { url, sourceLanguage: LanguageId.En, targetLanguage: LanguageId.Es } },
  });
  const { origin, pathname, hostname } = new URL(url);
  const translations = (
    data?.webPageTranslation?.replacements?.flatMap(replacement => replacement.translations) ?? []
  ).filter(filterNull);

  const isLargePage = translations.length > pageAmount;

  const visibleTranslations = isLargePage
    ? translations.slice(page * pageAmount, page * pageAmount + pageAmount)
    : translations;
  const amountOfPages = Math.floor(translations.length / pageAmount) + 1;

  useEffect(() => {
    setPage(0);
  }, [url]);

  if (loading) {
    return (
      <Box display="flex" py={4} px={4} flexDirection="column">
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box flex="1">
            <Typography variant="lg" font="secondary" isBold className={css({ letterSpacing: '-1px' })}>
              &#9608;&#9608;&#9608;&#9608; &#9608;&#9608;&#9608;&#9608;
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  const numberOfTranslations = translations.length ?? 0;
  const table = [
    ['Property', 'Value'],
    ['origin', origin],
    ['pathname', pathname],
    ['translations', data?.webPageTranslation.translationStatus],
    ['processing', data?.webPageTranslation.processingStatus],
  ].map(([key, value], i) => [
    <Typography variant="sm" isBold={i === 0}>
      {key}
    </Typography>,
    <Typography variant="sm" isBold={i === 0}>
      {value}
    </Typography>,
  ]);

  const translationsTable = [
    ['Source', 'Translation'],
    ...visibleTranslations.map(({ sourcePhrase, targetPhrase }) => [sourcePhrase, targetPhrase]),
  ].map(([key, value], i) => [
    <Typography variant="sm" isBold={i === 0}>
      {key}
    </Typography>,
    <Typography variant="sm" isBold={i === 0}>
      {value}
    </Typography>,
  ]);

  return (
    <>
      <Box display="flex" py={4} px={4} flexDirection="column" position="relative">
        <Box display="flex" flexDirection="row" alignItems="center" position="sticky" top={0}>
          <Box
            component="img"
            /* @ts-ignore */
            src={getFaviconUrl(hostname, 72 * 2)}
            borderRadius={72}
            width="72px"
            height="72px"
            mr={2}
          />
          <Box flex="1">
            <Typography variant="lg" font="secondary" isBold>
              {hostname}
              {pathname}
            </Typography>
            <Box pt={3}>
              <Button size="small" href={url} target="_blank">
                Visit site
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Break />
      <Box display="flex" py={2} px={4} pb={3} flexDirection="column">
        <Table rows={table} />
      </Box>
      <Break />
      <Box display="flex" py={2} px={4} flexDirection="row">
        <Languages sourceLanguage={LanguageId.En} targetLanguage={LanguageId.Es} />
      </Box>
      <Break />

      <Box display="flex" px={4} pb={3} flexDirection="column">
        <Box py={2} position="sticky" top={0} bgcolor={isDarkMode ? ToucanColors.gray[500] : ToucanColors.white}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box flex="1">
              <Typography variant="md" font="secondary">
                {isLargePage ? `Showing ${visibleTranslations.length} translations` : 'Translations'}
              </Typography>
              <Typography variant="sm">{numberOfTranslations} translations</Typography>
            </Box>
            {isLargePage && (
              <>
                <Button
                  size="small"
                  onClick={() => {
                    if (page !== 0) {
                      setPage(page - 1);
                    }
                  }}
                >
                  Prev
                </Button>
                <Box px={4}>
                  <Typography variant="sm">
                    {isLargePage ? `Page ${page + 1} of ${amountOfPages}` : 'Translations'}
                  </Typography>
                </Box>
                <Button
                  size="small"
                  onClick={() => {
                    if (page !== amountOfPages - 1) {
                      setPage(page + 1);
                    }
                  }}
                >
                  Next
                </Button>
              </>
            )}
          </Box>
        </Box>
        <Table rows={translationsTable} />
      </Box>
      <Break />
    </>
  );
});
