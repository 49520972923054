import { useState } from 'react';

import { ToucanColors, ToucanComponents } from '@jointoucan/toucan-design';
import { Sell } from '@mui/icons-material';
import { Alert, AlertTitle, Box } from '@mui/material';
import { format } from 'date-fns';
import { Route, Switch, useHistory, useParams } from 'react-router';

import { Break } from 'src/components/Break';
import { Table } from 'src/components/Table';
import { Typography } from 'src/components/Typography';
import { RoutePath } from 'src/enums/routePath';
import { ProductProvider, useGetSubscriptionProductQuery } from 'src/server/schema';

import { EditProductDialog } from './EditProductDialog';
import { Plans } from './Plans';
import { ProductTabs } from './ProductTabs';

const { Button, useColorScheme } = ToucanComponents;

export interface SubscriptionProductInfo {
  id: string;
  externalIds: {
    id: string;
    productProvider: ProductProvider;
  }[];
  name: string;
  nameTranslationKey: string;
  description: string;
  descriptionTranslationKey: string;
  addOns?: string[];
  isEnabled: boolean;
  createdAt: string;
  updatedAt: string;
}

export const Product = () => {
  const { isDarkMode } = useColorScheme();

  const { id: productId } = useParams<{ id: string }>();
  const { data, loading, refetch } = useGetSubscriptionProductQuery({ variables: { productId } });
  const history = useHistory();
  const [isEditProductDialogOpen, setIsEditProductDialogOpen] = useState(false);

  const product: SubscriptionProductInfo | undefined = data?.getSubscriptionProduct;

  const table = [
    ['Property', 'Value'],
    ['Id', productId],
    ['Created At', product?.createdAt ? `${format(new Date(product.createdAt), 'MMM do yyyy')}` : ''],
    ['Updated At', product?.updatedAt ? `${format(new Date(product.updatedAt), 'MMM do yyyy')}` : ''],
    ['Name - Internal', product?.name || ''],
    ['Name - Translation Key', product?.nameTranslationKey || ''],
    ['Description - Internal', product?.description || ''],
    ['Description - Translation Key', product?.descriptionTranslationKey || ''],
  ].map(([key, value], i) => [
    <Typography variant="sm" isBold={i === 0}>
      {key}
    </Typography>,
    <Typography variant="sm" isBold={i === 0}>
      {value}
    </Typography>,
  ]);

  const handleEditClicked = () => {
    setIsEditProductDialogOpen(true);
  };

  const handleEditCancelled = () => {
    setIsEditProductDialogOpen(false);
  };

  const handleEditSubmitted = async () => {
    const refetchResult = refetch({
      productId,
    });

    if (productId) {
      history.push(RoutePath.ProductInfo.replace(':id', productId));
    }

    setIsEditProductDialogOpen(false);

    await refetchResult;
  };

  return (
    <>
      {!product && !loading ? (
        <Alert severity="warning">
          <AlertTitle>Not Found</AlertTitle>
          Product not found with id ${productId}
        </Alert>
      ) : null}
      {product && (
        <>
          <Box display="flex" pt={2} px={4} flexDirection="column">
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box flex="0" pr={4}>
                <Sell
                  fontSize="large"
                  sx={{
                    color: isDarkMode ? ToucanColors.white : undefined,
                  }}
                />
              </Box>
              <Box flex="1">
                <Typography variant="lg" font="secondary" isBold>
                  {product.name}
                </Typography>
                <Typography variant="md">{product.description}</Typography>
              </Box>
              <Box flex={1} />
              <Button onClick={handleEditClicked} size="medium" variant="contained">
                Edit
              </Button>
            </Box>
            <ProductTabs />
          </Box>
          <Break />
          <Switch>
            <Route path={RoutePath.ProductInfo}>
              <Table rows={table} />
            </Route>
            <Route path={RoutePath.ProductPlans}>
              <Plans />
            </Route>
          </Switch>
          <EditProductDialog
            onCancel={handleEditCancelled}
            onSubmit={handleEditSubmitted}
            open={isEditProductDialogOpen}
            product={product}
          />
        </>
      )}
    </>
  );
};
