import { ToucanColors } from '@jointoucan/toucan-design';

// NOTE: Our design lib theme does not properly update its mode, hence the useColorScheme needed,
// so no theme.palette.mode checks in child components, hence this helper function instead of theme
export const getDialogStyling = (isDarkMode: boolean) => ({
  '& .MuiFormLabel-root': {
    color: isDarkMode ? ToucanColors.gray[400] : undefined,
  },
  '& .MuiInputBase-input': {
    color: isDarkMode ? ToucanColors.white : undefined,
  },
  '& .MuiInputBase-root': {
    borderColor: isDarkMode ? ToucanColors.white : undefined,
    '& .Mui-disabled': {
      backgroundColor: isDarkMode ? ToucanColors.gray[600] : undefined,
    },
  },
  '& .MuiMenuItem-root:hover': {
    backgroundColor: isDarkMode ? ToucanColors.gray[100] : undefined,
  },
  '& .MuiPaper-root': {
    backgroundColor: isDarkMode ? ToucanColors.gray[600] : ToucanColors.gray[100],
  },
});
