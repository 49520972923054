import { FC } from 'react';

import { css, cx } from '@emotion/css';
import Box from '@mui/material/Box';

import { getAvatarNumber } from 'src/lib/getAvatarNumber';

import { Flag } from './Flag';

enum ToucanAvatars {
  Beanie,
  Cap,
  Crown,
  Party,
  Scarf,
  Space,
  Sunglasses,
  Sunhat,
}

interface AvatarProps {
  className?: string;
  email?: string;
  language?: string;
  size?: number;
}

export const Avatar: FC<AvatarProps> = ({ className, email, language, size = 32 }) => {
  let avatarFileName = 'anonymous';
  const languageSize = size / 3;

  if (email) {
    const avatarIndex = getAvatarNumber(email, 7);
    avatarFileName = (ToucanAvatars[avatarIndex] ?? '').toLowerCase();
  }

  return (
    <Box
      className={cx(
        css({
          position: 'relative',
          backgroundImage: `url(/images/avatar/${avatarFileName}.svg)`,
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          width: `${size}px`,
          height: `${size}px`,
          minWidth: `${size}px`,
          minHeight: `${size}px`,
        }),
        className,
      )}
    >
      {language && (
        <Flag
          language={language}
          size={languageSize}
          className={css({
            position: 'absolute',
            bottom: '0px',
            right: '0px',
          })}
        />
      )}
    </Box>
  );
};
