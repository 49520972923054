const normalize = (n: number, start1: number, stop1: number, start2: number, stop2: number) =>
  ((n - start1) / (stop1 - start1)) * (stop2 - start2) + start2;

/**
 * getAvatarNumber gets a number between 0 and the number of avatars
 * based on the user emails first character
 * 97-122 is alpha
 * 48-57 in numeric
 * @param email
 * @param totalNumAvatars
 */
export const getAvatarNumber = (email: string, totalNumAvatars: number) => {
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/charCodeAt#Description
  const charCode = email.toLowerCase().charCodeAt(0);
  let min = 97;
  let max = 122;

  // Numeric range
  if (charCode >= 48 && charCode <= 57) {
    min = 48;
    max = 57;
  }

  return Math.max(Math.floor(normalize(charCode, min, max, 0, totalNumAvatars - 1)), 0); // number 0 through totalNumAvatars
};
