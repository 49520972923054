import React from 'react';

import { useParams } from 'react-router-dom';

import { Tabs, Tab } from 'src/components/Tabs';
import { RoutePath } from 'src/enums/routePath';

export const UserTabs = () => {
  const { id } = useParams<{ id: string }>();
  return (
    <Tabs layoutId="user">
      <Tab to={RoutePath.UserInfo.replace(':id', id)} label="Info" icon="user" />
      <Tab to={RoutePath.UserSubscription.replace(':id', id)} label="Subscription" icon="usd-circle" />
      <Tab to={RoutePath.UserSaved.replace(':id', id)} label="Saved" icon="bookmark-solid" />
      <Tab to={RoutePath.UserReports.replace(':id', id)} label="Reports" icon="flag" />
      <Tab
        to={RoutePath.Hashtag}
        onClick={() => {
          window.open(
            `https://sentry.io/organizations/toucan-io/issues/?query=is%3Aunresolved+user.id%3A${id}&statsPeriod=14d`,
            '_blank',
          );
        }}
        label="Error"
        icon="exclamation-circle"
      />
      <Tab to={RoutePath.UserExperiments.replace(':id', id)} label="Experiments" icon="book" />
    </Tabs>
  );
};
