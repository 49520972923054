import { memo } from 'react';

import { ToucanColors, ToucanComponents } from '@jointoucan/toucan-design';
import { Box } from '@mui/material';
import { useHistory } from 'react-router';

import { RoutePath } from 'src/enums/routePath';
import { useRouteParams } from 'src/hooks/useRouterParams';

import { Typography } from '../../Typography';

import { ContentCard } from './ContentCard';

const { useColorScheme } = ToucanComponents;

export const ContentSidebar = memo(() => {
  const { isDarkMode } = useColorScheme();
  const history = useHistory();
  const { id: selectedId } = useRouteParams<{ id: string }>(RoutePath.Content);

  const onContentSelected = (id: string) => {
    history.push(RoutePath.Content.replace(':id', id));
  };

  const borderColor = isDarkMode ? ToucanColors.gray[600] : ToucanColors.gray[100];

  return (
    <Box
      width="35vw"
      display="flex"
      flexDirection="column"
      boxShadow={`inset -1px 0px 0px ${borderColor}`}
      overflow="scroll"
    >
      <Box px={4} py={2} display="flex" flexDirection="column" bgcolor={ToucanColors.blue[400]} zIndex="1">
        <Typography variant="xs" isBold color={ToucanColors.white}>
          This will update the live products.
        </Typography>
        <Typography variant="xs" color={ToucanColors.white}>
          Please do not update unless you are sure this is the correct values.
        </Typography>
      </Box>
      <ContentCard
        id="extension-stats"
        title="Extension Stats"
        subtitle="Stats about the extension users, and reviews."
        onClick={onContentSelected}
        active={selectedId === 'extension-stats'}
      />
    </Box>
  );
});
