/* eslint-disable react/no-array-index-key */
import { FC, ReactNode } from 'react';

import { css, cx } from '@emotion/css';
import { ToucanComponents, ToucanColors } from '@jointoucan/toucan-design';
import { Table as TableMUI, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

const { useColorScheme } = ToucanComponents;

interface TableMatrix {
  rows: Array<Array<string | ReactNode>>;
  className?: string;
}

export const Table: FC<TableMatrix> = ({ rows, className }) => {
  const head = rows.slice(0, 1);
  const body = rows.slice(1);
  const { isDarkMode } = useColorScheme();
  return (
    <TableMUI
      className={cx(
        css({
          borderColor: isDarkMode ? ToucanColors.gray[600] : ToucanColors.gray[100],
          '.MuiTableCell-root': {
            borderColor: isDarkMode ? ToucanColors.gray[600] : ToucanColors.gray[100],
          },
        }),
        className,
      )}
    >
      <TableHead>
        {head.map((row, i) => (
          <TableRow key={`head-row-${i}`}>
            {row.map((value, y) => (
              <TableCell key={`head-cell-${i}-${y}`}>{value}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {body.map((row, i) => (
          <TableRow key={`body-row-${i}`}>
            {row.map((value, y) => (
              <TableCell key={`body-cell-${i}-${y}`}>{value}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </TableMUI>
  );
};
