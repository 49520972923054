import { useState, useEffect } from 'react';

import { ToucanColors, ToucanComponents } from '@jointoucan/toucan-design';
import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Paper,
} from '@mui/material';
import { startCase } from 'lodash';
import { Controller, useForm, useFieldArray } from 'react-hook-form';

import { Typography } from 'src/components/Typography';
import { getDialogStyling } from 'src/functions/getDialogStyling';
import {
  CreateSubscriptionProductInput,
  ProductProvider,
  useCreateSubscriptionProductMutation,
} from 'src/server/schema';

const { TextField, Button, Select, ToucanIcon, useColorScheme } = ToucanComponents;

export type ProductDraft = CreateSubscriptionProductInput;

interface CreateProductDialogProps extends Omit<DialogProps, 'onSubmit'> {
  onCancel: () => void;
  onSubmit: ({ productId }: { productId?: string }) => void;
}

export const CreateProductDialog = ({ onCancel, onSubmit, ...props }: CreateProductDialogProps) => {
  const [createSubscriptionProduct, { loading, error }] = useCreateSubscriptionProductMutation();
  const { isDarkMode, textError } = useColorScheme();
  const [globalErrorMessage, setGlobalErrorMessage] = useState<string | null>(null);

  const {
    formState: { errors, isDirty },
    handleSubmit: createSubmitHandler,
    control,
    register,
    watch,
    setValue,
  } = useForm<ProductDraft>({
    defaultValues: {
      description: '',
      descriptionTranslationKey: '',
      name: '',
      nameTranslationKey: '',
      externalIds: [],
    },
    shouldUnregister: true,
  });
  const { fields, remove, append } = useFieldArray({
    control,
    name: 'externalIds',
  });
  const externalIdsFormValue = watch('externalIds');

  useEffect(() => {
    if (!externalIdsFormValue || externalIdsFormValue.length < 2) {
      return;
    }

    const firstExternalIdProvider = externalIdsFormValue[0].productProvider;

    if (externalIdsFormValue.length === 2 && firstExternalIdProvider) {
      setValue(
        'externalIds.1.productProvider',
        firstExternalIdProvider === ProductProvider.Stripe ? ProductProvider.PayPal : ProductProvider.Stripe,
      );
    }
  }, [externalIdsFormValue]);

  const handleSubmitted = createSubmitHandler(async draft => {
    try {
      setGlobalErrorMessage(null);

      const { data } = await createSubscriptionProduct({
        variables: {
          input: {
            addOns: [],
            description: draft.description,
            descriptionTranslationKey: draft.descriptionTranslationKey,
            name: draft.name,
            nameTranslationKey: draft.nameTranslationKey,
            externalIds: draft.externalIds?.map(externalIdInfo => ({
              id: externalIdInfo.id,
              productProvider: externalIdInfo.productProvider,
            })),
            isEnabled: true,
            plans: [],
          },
        },
      });

      if (error) {
        throw new Error(error.message);
      }

      const createdProduct = data?.createSubscriptionProduct;
      onSubmit({ productId: createdProduct?.id });
    } catch (e: any) {
      const message = e && e.message ? e.message : 'Request failed';
      setGlobalErrorMessage(message);
    }
  });

  return (
    <Dialog onClose={onCancel} maxWidth="sm" fullWidth sx={getDialogStyling(isDarkMode)} {...props}>
      <DialogTitle>
        <Typography variant="lg" font="secondary">
          Create a Product
        </Typography>
        <Box mt={1}>
          <Typography variant="sm" color={ToucanColors.gray[400]}>
            Provide third-party product details
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mt={1.25} display="flex" gap={1}>
          <Box flex={1}>
            <TextField
              label="Name"
              autoFocus
              hasError={!!errors.name}
              helperText={errors.name?.message}
              {...register('name', {
                required: 'Name is required',
              })}
            />
          </Box>
          <Box flex={1}>
            <TextField
              label="Name Translation Key"
              hasError={!!errors.nameTranslationKey}
              helperText={errors.nameTranslationKey?.message}
              {...register('nameTranslationKey', {
                required: 'Name Translation Key is required',
              })}
            />
          </Box>
        </Box>

        <Box mt={1.25} display="flex" gap={1}>
          <Box flex={1}>
            <TextField
              label="Description"
              hasError={!!errors.description}
              helperText={errors.description?.message}
              {...register('description', {
                required: 'Description is required',
              })}
            />
          </Box>
          <Box flex={1}>
            <TextField
              label="Description Translation Key"
              hasError={!!errors.descriptionTranslationKey}
              helperText={errors.descriptionTranslationKey?.message}
              {...register('descriptionTranslationKey', {
                required: 'Description Translation Key is required',
              })}
            />
          </Box>
        </Box>

        <Paper sx={{ mt: 2, p: 2 }} variant="outlined">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="md" isBold>
              External IDs
            </Typography>
            {(!externalIdsFormValue || externalIdsFormValue.length < 2) && (
              <Button size="small" onClick={() => append({ id: '', productProvider: '' as ProductProvider })}>
                + Add new External ID
              </Button>
            )}
          </Box>

          {fields.map(({ id }, index) => (
            <Box key={id} mt={1.25} display="flex" gap={1.25}>
              <Box flex={1} sx={{ '> div': { width: '100%' } }}>
                <Controller
                  name={`externalIds.${index}.productProvider`}
                  control={control}
                  rules={{ required: 'Product provider is required' }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      label="Provider"
                      emptyLabel="Select One"
                      isEmptyOptionVisible
                      hasError={!!errors.externalIds?.[index]?.productProvider}
                      helperText={errors.externalIds?.[index]?.productProvider?.message}
                      options={Object.values(ProductProvider)
                        .filter(provider => {
                          // Filter out providers that are already in the form
                          if (!externalIdsFormValue) {
                            return true;
                          }
                          const isProviderAlreadyInForm = externalIdsFormValue.find(
                            externalIdInfo => externalIdInfo.productProvider === provider,
                          );
                          return !isProviderAlreadyInForm || provider === externalIdsFormValue[index].productProvider;
                        })
                        .map(value => ({
                          label: startCase(value),
                          value,
                        }))}
                    />
                  )}
                />
              </Box>
              <Box flex={1}>
                <TextField
                  label="ID"
                  hasError={!!errors.externalIds?.[index]?.id}
                  helperText={errors.externalIds?.[index]?.id?.message}
                  {...register(`externalIds.${index}.id`, {
                    required: 'ID is required',
                  })}
                />
              </Box>
              <Box
                onClick={() => remove(index)}
                display="flex"
                alignItems="center"
                justifyContent="center"
                flex={0.1}
                sx={{
                  cursor: 'pointer',
                }}
              >
                <ToucanIcon icon="times" color={textError} />
              </Box>
            </Box>
          ))}
        </Paper>

        {globalErrorMessage && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {globalErrorMessage}
          </Alert>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          px: 2.5,
          pt: 0,
          pb: 2,
        }}
      >
        <Button type="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={handleSubmitted} disabled={loading || !isDirty} endIcon={loading ?? <CircularProgress />}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};
