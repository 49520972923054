import { memo } from 'react';

import { ToucanColors, ToucanComponents } from '@jointoucan/toucan-design';
import { Box } from '@mui/material';
import { Switch, Route } from 'react-router';

import { RoutePath } from 'src/enums/routePath';

import { AudioSidebar } from './Audio/AudioSidebar';
import { ContentSidebar } from './Content';
import { ProductSidebar } from './Products/ProductSidebar';
import { TranslationSidebar } from './Translation';
import { UserSidebar } from './User';

const { useColorScheme } = ToucanComponents;

export const Sidebar = memo(() => {
  const { isDarkMode } = useColorScheme();
  const borderColor = isDarkMode ? ToucanColors.gray[600] : ToucanColors.gray[100];

  return (
    <Box
      width="35vw"
      display="flex"
      flexDirection="column"
      boxShadow={`inset -1px 0px 0px ${borderColor}`}
      height="100%"
      overflow="scroll"
    >
      <Switch>
        <Route path={RoutePath.Contents}>
          <ContentSidebar />
        </Route>
        <Route path={RoutePath.Users}>
          <UserSidebar />
        </Route>
        <Route path={RoutePath.Translations}>
          <TranslationSidebar />
        </Route>
        <Route path={RoutePath.Products}>
          <ProductSidebar />
        </Route>
        <Route path={RoutePath.Audio}>
          <AudioSidebar />
        </Route>
      </Switch>
    </Box>
  );
});
