import React, { ChangeEvent, useState } from 'react';

import { ToucanColors, ToucanComponents } from '@jointoucan/toucan-design';
import { Box, Dialog, DialogContent } from '@mui/material';

import { Flag } from 'src/components/Flag';
import { CrawlerWebPageMutation, LanguageId } from 'src/server/schema';

import { Typography } from '../../Typography';

const { useColorScheme, TextField, Button } = ToucanComponents;

interface CrawlModalProps {
  open: boolean;
  onRequestClose: () => void;
  crawlLoading: boolean;
  crawlWebPage: (url: string) => Promise<void>;
  crawlData?: CrawlerWebPageMutation;
}

export const CrawlModal: React.FC<CrawlModalProps> = ({
  open,
  onRequestClose,
  crawlLoading,
  crawlWebPage,
  crawlData,
}) => {
  const { isDarkMode } = useColorScheme();
  const [urlError, setUrlError] = useState<Error | null>(null);
  const [crawlUrl, setCrawlUrl] = useState('');

  const background = isDarkMode ? ToucanColors.gray[500] : ToucanColors.white;
  const labelColor = isDarkMode ? ToucanColors.gray[300] : ToucanColors.gray[400];

  const validateURL = (url: string) => {
    try {
      // This will throw if the URL is invalid
      // eslint-disable-next-line no-new
      new URL(url);
      setUrlError(null);
      return true;
    } catch (e) {
      setUrlError(new TypeError('Invalid url'));
      return false;
    }
  };

  const onCrawlWebPage = async () => {
    if (!validateURL(crawlUrl)) {
      return;
    }

    const { origin, pathname } = new URL(crawlUrl);

    await crawlWebPage(`${origin}${pathname}`);
  };

  return (
    <Dialog
      open={open}
      onClose={onRequestClose}
      aria-label="Crawl web page modal"
      maxWidth="md"
      PaperProps={{
        style: {
          backgroundColor: background,
        },
      }}
    >
      <DialogContent>
        <Box pt={1} pb={3} width="400px">
          <Box pb={2}>
            <Typography variant="md" font="secondary">
              Add web page to crawler queue
            </Typography>
          </Box>
          <Box bgcolor={ToucanColors.yellow[200]} borderRadius={4} px={2} py={2} mb={2}>
            <Box pb={1}>
              <Typography color={ToucanColors.gray[500]} variant="sm" isBold>
                Crawling can take a long time.
              </Typography>
            </Box>
            <Typography color={ToucanColors.gray[500]} variant="sm">
              Due to the amount of processing that happens to get translations, this crawl process can take a lot of
              time. To avoid slowing down these times or double crawling sites please do not resubmit a crawl util you
              are certain it has failed.
            </Typography>
          </Box>
          <form>
            <Box pb={2}>
              <TextField
                value={crawlUrl}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setUrlError(null);
                  setCrawlUrl(e.target.value);
                }}
                onBlur={e => {
                  validateURL(e.target.value);
                }}
                hasError={!!urlError}
                helperText={urlError ? urlError.message : null}
                label="Web page URL"
                isDarkMode={isDarkMode}
              />
            </Box>
            <Box pb={4}>
              <Typography variant="sm" color={labelColor}>
                Crawler currently only supports
              </Typography>
              <Box display="flex" pt={1} alignItems="center">
                <Flag language={LanguageId.En} />
                <Box px={1}>
                  <Typography>English to</Typography>
                </Box>
                <Flag language={LanguageId.Es} />
                <Box px={1}>
                  <Typography>Spanish</Typography>
                </Box>
              </Box>
            </Box>
          </form>
          <Button
            isDisabled={crawlLoading || !crawlUrl || !!urlError}
            disabled={crawlLoading || !crawlUrl || !!urlError}
            onClick={onCrawlWebPage}
          >
            {crawlLoading ? 'Queueing' : 'Crawl site'}
          </Button>
          {crawlData?.crawlWebPage && (
            <Box bgcolor={ToucanColors.green[300]} borderRadius={4} px={2} py={2} mt={2}>
              <Box pb={1}>
                <Typography color={ToucanColors.white} variant="sm" isBold>
                  Web page has been queued for crawling.
                </Typography>
              </Box>
              <Typography color={ToucanColors.white} variant="sm">
                Please give around 30 minutes for the web page to be crawled, and check again for info on site.
              </Typography>
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
