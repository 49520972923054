import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: string;
  Upload: any;
};

export type AccountCreditLedgerRecord = {
  __typename?: 'AccountCreditLedgerRecord';
  amount: Scalars['Float'];
  description: Scalars['String'];
  createdAt: Scalars['DateTime'];
};

export type AccountCreditRedemptionReward = {
  __typename?: 'AccountCreditRedemptionReward';
  id: Scalars['ID'];
  amount: Scalars['Float'];
  currencyCode: Scalars['String'];
  referredUser?: Maybe<ReferredUserInfo>;
  createdAt: Scalars['DateTime'];
  redeemedAt?: Maybe<Scalars['DateTime']>;
};

export type AccountCreditRewardInfo = RewardInfo & {
  __typename?: 'AccountCreditRewardInfo';
  rewardType: Scalars['String'];
  metadata: AccountCreditRewardMetadata;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type AccountCreditRewardMetadata = {
  __typename?: 'AccountCreditRewardMetadata';
  amount: Scalars['Float'];
  currencyCode: Scalars['String'];
};

export type AccountCreditRewardSummary = {
  __typename?: 'AccountCreditRewardSummary';
  rewardType: Scalars['String'];
  rewards: Array<AccountCreditRedemptionReward>;
  totalCredits: Scalars['Float'];
  totalAvailableCredits: Scalars['Float'];
  creditCurrencyCode: Scalars['String'];
};

export type Ad = {
  __typename?: 'Ad';
  id: Scalars['ID'];
  name: Scalars['String'];
  imgSrc: AdImgSrc;
  linkUrl: Scalars['String'];
};

export type AdImgSrc = {
  __typename?: 'AdImgSrc';
  light: Scalars['String'];
  dark?: Maybe<Scalars['String']>;
};

export type AddEventBusEventInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type AddVariationToWordListInput = {
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  variationId: Scalars['String'];
};

export type AddWordToVectoredWordListInput = {
  targetLanguage: LanguageId;
  sourceLanguage: LanguageId;
  sourcePhrase: Scalars['String'];
  sourceRoot: Scalars['String'];
  sourcePrefix?: Maybe<Scalars['String']>;
  sourceSuffix?: Maybe<Scalars['String']>;
  targetPhrase: Scalars['String'];
};

export type AddWordToWordListInput = {
  language: LanguageId;
  sourceLanguage?: Maybe<LanguageId>;
  translation: Scalars['String'];
};

export type AdditionalWordInfo = {
  __typename?: 'AdditionalWordInfo';
  definition?: Maybe<Scalars['String']>;
  pronunciation?: Maybe<Scalars['String']>;
};

export type AlwaysTranslateSavedWordsSettingInput = {
  isAlwaysTranslateSavedWordsEnabled: Scalars['Boolean'];
};

export type AnonymousUserInput = {
  anonymousId?: Maybe<Scalars['String']>;
  selectedLanguage?: Maybe<Scalars['String']>;
  sourceLanguage: Scalars['String'];
  targetLanguage?: Maybe<LanguageId>;
  source?: Maybe<Scalars['String']>;
  partner?: Maybe<Scalars['String']>;
};

export type AudioPreferencesInput = {
  playbackRate: Scalars['Float'];
  voiceNumber: Scalars['Int'];
};

export type AuthenticateExternalLoginInput = {
  code: Scalars['String'];
  redirectUrl: Scalars['String'];
  languageId?: Maybe<LanguageId>;
  source?: Maybe<Scalars['String']>;
  authProvider: ExternalAuthProvider;
  newUserInformation?: Maybe<NewUserInformationInput>;
};

export type AuthorizeInput = {
  refreshToken: Scalars['String'];
};

export type BadgeImage = {
  __typename?: 'BadgeImage';
  badgeImageUrl: Scalars['String'];
  badgeImageAltText: Scalars['String'];
};

export type BatchRecordVariationGamePointsInput = {
  variationGamePointsInputs: Array<RecordVariationGamePointsInput>;
};

export type BatchRecordVariationViewsInput = {
  variationViewsInputs: Array<RecordVariationViewsInput>;
};

export type BatchRecordVectorTranslationGamePointsInput = {
  vectorTranslationGamePointsInputs: Array<RecordVectorTranslationGamePointsInput>;
};

export type BatchRecordVectorTranslationViewsInput = {
  vectorTranslationViewsInputs: Array<RecordVectorTranslationViewsInput>;
};

export type BatchVectorizeWordListInput = {
  batchSize: Scalars['Int'];
};

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE',
}

export type CheckCronInput = {
  hour: Scalars['Int'];
  minute: Scalars['Int'];
};

export enum ChecklistItem {
  HasCreatedToucanAccount = 'hasCreatedToucanAccount',
  HasHoveredOverTranslation = 'hasHoveredOverTranslation',
  HasPracticedFirstWord = 'hasPracticedFirstWord',
  HasSavedFirstWord = 'hasSavedFirstWord',
  HasLearnedAboutPremium = 'hasLearnedAboutPremium',
}

export type Chunk = {
  __typename?: 'Chunk';
  text?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['Int']>;
  chunkType?: Maybe<Scalars['String']>;
  containsNamedEntity?: Maybe<Scalars['Boolean']>;
  fleschKincaidReadingEase?: Maybe<Scalars['Float']>;
  fleschKincaidGradeLevel?: Maybe<Scalars['Float']>;
};

export type ContextlessLearning = {
  learningType: ContextlessLearningType;
};

export type ContextlessLearningInput = {
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  supportedLearningTypes?: Maybe<Array<ContextlessLearningType>>;
};

export type ContextlessLearningMoment = {
  __typename?: 'ContextlessLearningMoment';
  learnings: Array<ContextlessLearning>;
};

export enum ContextlessLearningType {
  TargetPhraseMultipleChoice = 'TargetPhraseMultipleChoice',
  Flashcard = 'Flashcard',
}

export type ContextualizedOnboardingExampleSentencePracticeMoment = ContextualizedOnboardingExtraPracticeMoment & {
  __typename?: 'ContextualizedOnboardingExampleSentencePracticeMoment';
  momentType: ContextualizedOnboardingExtraPracticeMomentType;
  learningShortcut: ContextualizedOnboardingLearningShortcut;
  exampleSentences: Array<ContextualizedOnboardingExtraPracticeExampleSentence>;
  practiceQuestions: Array<ContextualizedOnboardingExtraPracticeQuestion>;
};

export type ContextualizedOnboardingExtraPracticeExampleSentence = {
  __typename?: 'ContextualizedOnboardingExtraPracticeExampleSentence';
  sourceLanguageSentence: Scalars['String'];
  targetPhrase: Scalars['String'];
};

export type ContextualizedOnboardingExtraPracticeMoment = {
  momentType: ContextualizedOnboardingExtraPracticeMomentType;
};

export enum ContextualizedOnboardingExtraPracticeMomentType {
  ContextualizedOnboardingExampleSentencePracticeMoment = 'ContextualizedOnboardingExampleSentencePracticeMoment',
}

export type ContextualizedOnboardingExtraPracticeQuestion = {
  questionType: ContextualizedOnboardingExtraPracticeQuestionType;
};

export enum ContextualizedOnboardingExtraPracticeQuestionType {
  ContextualizedOnboardingTargetPhraseMultipleChoiceExtraPracticeQuestion = 'ContextualizedOnboardingTargetPhraseMultipleChoiceExtraPracticeQuestion',
}

export type ContextualizedOnboardingLearningShortcut = {
  __typename?: 'ContextualizedOnboardingLearningShortcut';
  name: Scalars['String'];
  gerundTitle: Scalars['String'];
  infoTitle: Scalars['String'];
  infoDetails: Scalars['String'];
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
};

export type ContextualizedOnboardingTargetPhraseMultipleChoiceExtraPracticeQuestion =
  ContextualizedOnboardingExtraPracticeQuestion & {
    __typename?: 'ContextualizedOnboardingTargetPhraseMultipleChoiceExtraPracticeQuestion';
    questionType: ContextualizedOnboardingExtraPracticeQuestionType;
    targetPhrase: Scalars['String'];
    sourcePhraseAnswer: Scalars['String'];
    sourcePhraseOptions: Array<Scalars['String']>;
  };

export type CrawlWebPageForAllLanguagePairsInput = {
  url: Scalars['String'];
};

export type CrawlWebPageInput = {
  url: Scalars['String'];
  isHeadless: Scalars['Boolean'];
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
};

export type CreatePromoCodeInput = {
  code: Scalars['String'];
  maxRedemptions?: Maybe<Scalars['Int']>;
};

export type CreatePromotionInput = {
  name: Scalars['String'];
  description: Scalars['String'];
  prefix: Scalars['String'];
  eligibility?: Maybe<PromotionEligibility>;
  period: PromotionDuration;
  expiresAt?: Maybe<Scalars['DateTime']>;
  isEnabled: Scalars['Boolean'];
  isAdmin: Scalars['Boolean'];
  promoCodes?: Maybe<Array<PromoCodeInput>>;
};

export type CreateSubscriptionPlanInput = {
  name: Scalars['String'];
  nameTranslationKey: Scalars['String'];
  description: Scalars['String'];
  descriptionTranslationKey: Scalars['String'];
  price: PlanPriceInput;
  recurringInterval: IntervalInput;
  locale: Scalars['String'];
  trialPeriod?: Maybe<TrialPeriodInput>;
};

export type CreateSubscriptionProductInput = {
  externalIds?: Maybe<Array<ExternalIdInput>>;
  name: Scalars['String'];
  nameTranslationKey: Scalars['String'];
  description: Scalars['String'];
  descriptionTranslationKey: Scalars['String'];
  plans?: Maybe<Array<CreateSubscriptionPlanInput>>;
  addOns?: Maybe<Array<Scalars['String']>>;
  isEnabled?: Scalars['Boolean'];
};

export enum CurrencyCode {
  Aud = 'AUD',
  Brl = 'BRL',
  Cny = 'CNY',
  Cop = 'COP',
  Eur = 'EUR',
  Gbp = 'GBP',
  Idr = 'IDR',
  Inr = 'INR',
  Jpy = 'JPY',
  Mxn = 'MXN',
  Myr = 'MYR',
  Pen = 'PEN',
  Php = 'PHP',
  Pln = 'PLN',
  Rub = 'RUB',
  Try = 'TRY',
  Twd = 'TWD',
  Usd = 'USD',
  Vnd = 'VND',
}

export type DefaultBlacklistedDomains = {
  __typename?: 'DefaultBlacklistedDomains';
  blacklist?: Maybe<Array<Scalars['String']>>;
};

export type Definition = {
  __typename?: 'Definition';
  alternateDefinitions?: Maybe<Array<Maybe<Scalars['String']>>>;
  selectedDefinition?: Maybe<Scalars['String']>;
};

export type DependencyArc = {
  __typename?: 'DependencyArc';
  start?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  direction?: Maybe<Scalars['String']>;
};

export enum DifficultyTier {
  Beginner = 'Beginner',
  Intermediate = 'Intermediate',
  Advanced = 'Advanced',
}

export type DifficultyTierInput = {
  difficultyTier: DifficultyTier;
  densityLevel?: Maybe<Scalars['Float']>;
};

export type DifficultyWeight = {
  __typename?: 'DifficultyWeight';
  tier: DifficultyTier;
  weight: Scalars['Float'];
};

export type Duolingo = {
  __typename?: 'Duolingo';
  /** @deprecated Field no longer supported */
  languages?: Maybe<Array<Scalars['String']>>;
  /** @deprecated Field no longer supported */
  wordExist: Scalars['Boolean'];
};

export type DuolingoWordExistArgs = {
  word?: Maybe<Scalars['String']>;
};

export type EmailShareInfo = {
  __typename?: 'EmailShareInfo';
  to: Scalars['String'];
  hasBeenRedeemed: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ErrorReportAdditionalInfoInput = {
  variationId?: Maybe<Scalars['String']>;
  browserName?: Maybe<Scalars['String']>;
  browserVersion?: Maybe<Scalars['String']>;
  osName?: Maybe<Scalars['String']>;
  osVersion?: Maybe<Scalars['String']>;
  toucanVersion?: Maybe<Scalars['String']>;
};

export type ExamplePhrase = {
  __typename?: 'ExamplePhrase';
  sourcePhrase: Scalars['String'];
  targetPhrase: Scalars['String'];
};

export type ExampleSentencePracticeMoment = ExtraPracticeMoment & {
  __typename?: 'ExampleSentencePracticeMoment';
  momentType: ExtraPracticeMomentType;
  learningShortcut: LearningShortcut;
  learningShortcutTopic: LearningShortcutTopic;
  userHomonym: UserHomonym;
  exampleSentences: Array<ExtraPracticeExampleSentence>;
  practiceQuestions: Array<ExtraPracticeQuestion>;
};

export type ExpandTranslationInput = {
  sourcePhrase: Scalars['String'];
  sourcePhraseInstanceIndex: Scalars['Int'];
  sourceSentence: Scalars['String'];
  variationId: Scalars['String'];
};

export type ExpandedTranslation = {
  __typename?: 'ExpandedTranslation';
  expandedSourcePhrase?: Maybe<Scalars['String']>;
  targetPhrase?: Maybe<Scalars['String']>;
};

export type Experiment = {
  __typename?: 'Experiment';
  id: Scalars['ID'];
  experimentName: Scalars['String'];
  accepted: Scalars['Boolean'];
};

export type ExperimentInput = {
  experimentName?: Maybe<Scalars['String']>;
  accepted?: Maybe<Scalars['Boolean']>;
};

export type ExtensionStatAttributes = {
  users?: Maybe<Scalars['Int']>;
  reviews?: Maybe<Scalars['Int']>;
};

export enum ExternalAuthProvider {
  Google = 'Google',
  Apple = 'Apple',
}

export type ExternalId = IExternalId & {
  __typename?: 'ExternalId';
  id: Scalars['String'];
  productProvider: ProductProvider;
};

export type ExternalIdInput = {
  id: Scalars['String'];
  productProvider: ProductProvider;
};

export type ExternalPlanId = IExternalId & {
  __typename?: 'ExternalPlanId';
  id: Scalars['String'];
  productProvider: ProductProvider;
  hasTrialPeriod: Scalars['Boolean'];
};

export type ExtraPracticeExampleSentence = {
  __typename?: 'ExtraPracticeExampleSentence';
  sentenceOrder?: Maybe<Scalars['Int']>;
  sourceLanguageSentence: Scalars['String'];
  targetPhrase: Scalars['String'];
  variationId: Scalars['String'];
};

export type ExtraPracticeInput = {
  supportedExtraPracticeMomentTypes: Array<ExtraPracticeMomentType>;
  supportedQuestionTypes: Array<ExtraPracticeQuestionType>;
};

export type ExtraPracticeMoment = {
  momentType: ExtraPracticeMomentType;
};

export enum ExtraPracticeMomentType {
  ExampleSentencePracticeMoment = 'ExampleSentencePracticeMoment',
}

export type ExtraPracticeQuestion = {
  questionType: ExtraPracticeQuestionType;
};

export enum ExtraPracticeQuestionType {
  SourcePhraseMultipleChoiceExtraPracticeQuestion = 'SourcePhraseMultipleChoiceExtraPracticeQuestion',
  TargetPhraseMultipleChoiceExtraPracticeQuestion = 'TargetPhraseMultipleChoiceExtraPracticeQuestion',
  ListenExtraPracticeQuestion = 'ListenExtraPracticeQuestion',
}

export type FeedNotification = {
  __typename?: 'FeedNotification';
  layout: FeedNotificationLayout;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  cta?: Maybe<Scalars['String']>;
  ctaURL?: Maybe<Scalars['String']>;
  imageURL?: Maybe<Scalars['String']>;
  addedAt: Scalars['DateTime'];
};

export enum FeedNotificationLayout {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
}

export type Filter = {
  word?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type Flashcard = ContextlessLearning & {
  __typename?: 'Flashcard';
  learningType: ContextlessLearningType;
  sourcePhrase: Scalars['String'];
  sourceRoot: Scalars['String'];
  sourcePrefix?: Maybe<Scalars['String']>;
  sourceSuffix?: Maybe<Scalars['String']>;
  targetPhrase: Scalars['String'];
  variationId: Scalars['String'];
};

export type FluencyQuiz = {
  __typename?: 'FluencyQuiz';
  questions: Array<FluencyQuizQuestion>;
};

export type FluencyQuizInput = {
  targetLanguage: LanguageId;
  sourceLanguage?: Maybe<LanguageId>;
  targetPhrase: Scalars['String'];
  sourcePhrase: Scalars['String'];
  prefix?: Maybe<Scalars['String']>;
};

export type FluencyQuizQuestion = {
  questionType: FluencyQuizType;
};

export enum FluencyQuizType {
  SwapQuestion = 'SwapQuestion',
  ListenQuestion = 'ListenQuestion',
  LatinSpellingQuestion = 'LatinSpellingQuestion',
  NonLatinSpellingQuestion = 'NonLatinSpellingQuestion',
  MatchQuestion = 'MatchQuestion',
  SourcePhraseSwapQuestion = 'SourcePhraseSwapQuestion',
}

export type GenerateDataSetsInput = {
  forceRegenerate: Scalars['Boolean'];
};

export type GetRedisKeyInput = {
  prefix: Scalars['String'];
  key: Scalars['String'];
};

export type GetUsersInput = {
  limit?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};

export type HasCompletedContextualizedOnboardingInput = {
  hasCompletedContextualizedOnboarding: Scalars['Boolean'];
};

export type HeroImage = {
  __typename?: 'HeroImage';
  heroImageUrl: Scalars['String'];
  heroImageAltText: Scalars['String'];
};

export type Homonym = {
  __typename?: 'Homonym';
  id: Scalars['String'];
  variations: Array<Variation>;
  partOfSpeech?: Maybe<Scalars['String']>;
  isDefaultHomonym: Scalars['Boolean'];
};

export type IExternalId = {
  id: Scalars['String'];
  productProvider: ProductProvider;
};

export enum InDevLanguageId {
  Nl = 'nl',
  En = 'en',
  Fa = 'fa',
  El = 'el',
  Ga = 'ga',
  Pl = 'pl',
  Ru = 'ru',
  Sv = 'sv',
  Tr = 'tr',
  Vi = 'vi',
  Zu = 'zu',
  Other = 'other',
}

export type IncrementHomonymProgressionInput = {
  homonymRecordId: Scalars['String'];
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  progressionDifference: Scalars['Int'];
};

export type Interval = {
  __typename?: 'Interval';
  intervalUnit: IntervalUnit;
  count: Scalars['Int'];
};

export type IntervalInput = {
  intervalUnit: IntervalUnit;
  count: Scalars['Int'];
};

export enum IntervalUnit {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
}

export type InvalidateWebPageTranslationsInput = {
  url?: Maybe<Scalars['String']>;
  sourceLanguage?: Maybe<LanguageId>;
  targetLanguage?: Maybe<LanguageId>;
};

export type KnownTranslationInput = {
  language: LanguageId;
  sourceLanguage?: Maybe<LanguageId>;
  translation: Scalars['String'];
  isKnown: Scalars['Boolean'];
};

export enum LanguageId {
  De = 'de',
  Es = 'es',
  Fr = 'fr',
  It = 'it',
  Ja = 'ja',
  Ko = 'ko',
  Pt = 'pt',
  En = 'en',
  Zh = 'zh',
  Hi = 'hi',
  He = 'he',
  Ar = 'ar',
  JaKanji = 'jaKanji',
  JaRoman = 'jaRoman',
  HiRoman = 'hiRoman',
  KoRoman = 'koRoman',
  ArRoman = 'arRoman',
  HeRoman = 'heRoman',
  ZhRoman = 'zhRoman',
}

export type LanguageLeaderboardItem = {
  __typename?: 'LanguageLeaderboardItem';
  languageId: InDevLanguageId;
  votes: Scalars['Int'];
};

export enum LanguageLearningMotivation {
  GoingOnATripSoon = 'goingOnATripSoon',
  NeedItForWork = 'needItForWork',
  LearningForSchool = 'learningForSchool',
  ConnectWithLovedOne = 'connectWithLovedOne',
  WantToImproveMyself = 'wantToImproveMyself',
  SomethingElse = 'somethingElse',
}

export type LanguageLearningMotivationInput = {
  languageLearningMotivation: LanguageLearningMotivation;
};

export type LanguageSettingsInput = {
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
};

export type LanguageTranslation = {
  __typename?: 'LanguageTranslation';
  language?: Maybe<Scalars['String']>;
  translation?: Maybe<TranslationWithGenderFormsAndPrefixes>;
};

export type LastSeenTranslationCursorInput = {
  sourcePhrase: Scalars['String'];
  targetPhrase: Scalars['String'];
};

export type LatinSpellingPracticeQuestion = PracticeQuestion & {
  __typename?: 'LatinSpellingPracticeQuestion';
  questionType: PracticeType;
  sourcePhrase: Scalars['String'];
  targetPhraseAnswer: Scalars['String'];
  accentOptions: Array<Maybe<Scalars['String']>>;
  answerVariationId: Scalars['String'];
};

export type LatinSpellingQuestion = FluencyQuizQuestion & {
  __typename?: 'LatinSpellingQuestion';
  questionType: FluencyQuizType;
  sourcePhrase: Scalars['String'];
  targetPhraseAnswer: Scalars['String'];
  accentOptions: Array<Maybe<Scalars['String']>>;
};

export type LearningShortcut = {
  __typename?: 'LearningShortcut';
  id: Scalars['ID'];
  name: Scalars['String'];
  gerundTitle: Scalars['String'];
  infoTitle: Scalars['String'];
  infoDetails: Scalars['String'];
  learningShortcutTopics: Array<LearningShortcutTopic>;
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  examplePhrases: Array<ExamplePhrase>;
  heroImage: HeroImage;
  titleImage: TitleImage;
  badgeImage: BadgeImage;
  celebrationTitle: Scalars['String'];
  subject: LearningShortcutSubject;
  isRecommended?: Maybe<Scalars['Boolean']>;
};

export enum LearningShortcutSubject {
  Food = 'food',
  Friends = 'friends',
  Greetings = 'greetings',
  Survival = 'survival',
}

export type LearningShortcutTopic = {
  __typename?: 'LearningShortcutTopic';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type LearningShortcutsInput = {
  targetLanguage: LanguageId;
  sourceLanguage: LanguageId;
};

export type Lexicon = {
  __typename?: 'Lexicon';
  definitions?: Maybe<Definition>;
  pos?: Maybe<Scalars['String']>;
  translations?: Maybe<Array<Maybe<LanguageTranslation>>>;
  isDfltPos?: Maybe<Scalars['Boolean']>;
};

export type ListenExtraPracticeQuestion = ExtraPracticeQuestion & {
  __typename?: 'ListenExtraPracticeQuestion';
  questionType: ExtraPracticeQuestionType;
  sourcePhrase: Scalars['String'];
  targetPhraseAnswer: Scalars['String'];
  targetPhraseOptions: Array<Scalars['String']>;
  answerHomonymRecordId: Scalars['String'];
};

export type ListenPracticeQuestion = PracticeQuestion & {
  __typename?: 'ListenPracticeQuestion';
  questionType: PracticeType;
  sourcePhrase: Scalars['String'];
  targetPhraseAnswer: Scalars['String'];
  targetPhraseOptions: Array<Scalars['String']>;
  answerVariationId: Scalars['String'];
};

export type ListenQuestion = FluencyQuizQuestion & {
  __typename?: 'ListenQuestion';
  questionType: FluencyQuizType;
  sourcePhrase: Scalars['String'];
  targetPhraseAnswer: Scalars['String'];
  targetPhraseOptions: Array<Scalars['String']>;
};

export type LogInUserInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MachineTranslation = {
  __typename?: 'MachineTranslation';
  original?: Maybe<Scalars['String']>;
  translation?: Maybe<Scalars['String']>;
};

export type MachineTranslationInput = {
  sourceLanguage?: Maybe<Scalars['String']>;
  sourcePhrase?: Maybe<Scalars['String']>;
  phrase?: Maybe<Scalars['String']>;
  targetLanguage: Scalars['String'];
};

export type MatchPracticeOption = {
  __typename?: 'MatchPracticeOption';
  sourcePhrase: Scalars['String'];
  targetPhrase: Scalars['String'];
  variationId: Scalars['String'];
};

export type MatchPracticeQuestion = PracticeQuestion & {
  __typename?: 'MatchPracticeQuestion';
  questionType: PracticeType;
  options: Array<MatchPracticeOption>;
};

export type MatchQuestion = FluencyQuizQuestion & {
  __typename?: 'MatchQuestion';
  questionType: FluencyQuizType;
  options: Array<MatchQuizOption>;
};

export type MatchQuizOption = {
  __typename?: 'MatchQuizOption';
  sourcePhrase: Scalars['String'];
  targetPhrase: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  setRedisKey: Scalars['String'];
  putKinesisEvent: Scalars['Boolean'];
  addEventBusEvent: Scalars['Boolean'];
  checkCron: Scalars['Boolean'];
  sendSqsMessage: Scalars['Boolean'];
  sendSqsMessageOnce: Scalars['Boolean'];
  sendHydrateSubscriptionAnalytics: Scalars['Boolean'];
  updatePremiumSubscriptionStripePriceData: Scalars['Boolean'];
  updatePremiumSubscriptionPaypalPlanData: Scalars['Boolean'];
  updateSubscriptionsStatusUpdatedAtDate: Scalars['Boolean'];
  updateUserEventAttributesForAllCurrentPremiumSubscriptions: Scalars['Boolean'];
  contact?: Maybe<Scalars['String']>;
  addCEFRPhrases: Scalars['Boolean'];
  addCEFRDataToTranslationsV4: Scalars['Boolean'];
  addCognateFlagToTranslationsV4: Scalars['Boolean'];
  addPopulationBlacklistedDomain?: Maybe<Scalars['Boolean']>;
  removePopulationBlacklistedDomain?: Maybe<Scalars['Boolean']>;
  addUserBlacklistedDomain?: Maybe<Array<Scalars['String']>>;
  removeUserBlacklistedDomain?: Maybe<Array<Scalars['String']>>;
  emailExtensionDownloadLink: Scalars['Boolean'];
  updateExampleSentences: Scalars['Boolean'];
  expandTranslation: ExpandedTranslation;
  updateExtensionStat: UpdateExtensionStatResult;
  saveLanguageSelection?: Maybe<Scalars['Boolean']>;
  updateLearningShortcuts: Scalars['Boolean'];
  translatePhrase?: Maybe<MachineTranslation>;
  /** @deprecated End of Chunk MT Beta Test */
  translateChunk?: Maybe<MachineTranslation>;
  processTextThroughNlpPipeline: ProcessedText;
  maybeAddOnboardingChecklist: OnboardingChecklist;
  maybeMigrateOnboardingChecklist?: Maybe<Scalars['Boolean']>;
  createPremiumSubscription: Scalars['Boolean'];
  createPromotionalPremiumSubscription?: Maybe<PremiumSubscription>;
  createPremiumSubscriptionForUserWithAdminCode: Scalars['Boolean'];
  syncPremiumSubscriptionWithAppleAppStoreReceipt: Scalars['Boolean'];
  syncPremiumSubscriptionWithPayPalSubscriptionId: Scalars['Boolean'];
  cancelPremiumPayPalSubscription?: Maybe<Scalars['Boolean']>;
  /** @deprecated Field no longer supported */
  generateReferralCode?: Maybe<PublicPromoCode>;
  createPromotion: Promotion;
  updatePromotion: Promotion;
  createPromoCode: PromoCode;
  referUsersByEmail: ReferUsersByEmailResponse;
  setUserReferralRewardInfo: ReferralInfo;
  setUserReferralRewardInfoMetadata: ReferralInfo;
  _?: Maybe<Scalars['String']>;
  updateTranslationsDataSets: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  saveTranslationsFromCms: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  generateV3Translations: Scalars['Boolean'];
  generateTranslations: Scalars['Boolean'];
  /** @deprecated use authorize */
  reauthenticate?: Maybe<Tokens>;
  /** @deprecated use registerUser */
  anonymousSignUp?: Maybe<Tokens>;
  /** @deprecated use logInUser */
  signIn?: Maybe<Tokens>;
  logOut: Scalars['Boolean'];
  logInUser: Tokens;
  authenticateExternalLogin: Tokens;
  registerUser: Tokens;
  authorize: Tokens;
  updateUsersName?: Maybe<Scalars['String']>;
  updateUsersPasswordV2: Scalars['Boolean'];
  saveLanguageSettings: Scalars['Boolean'];
  saveLanguageLearningMotivation: Scalars['Boolean'];
  saveHasCompletedContextualizedOnboarding: Scalars['Boolean'];
  saveDarkModeSetting: Scalars['Boolean'];
  saveUnsubscribedToEmailsSetting: Scalars['Boolean'];
  lostPassword?: Maybe<Scalars['String']>;
  resetPassword?: Maybe<ResetPasswordReturn>;
  updateUserPersonalization?: Maybe<UpdatedUserPersonalization>;
  saveRating?: Maybe<Rating>;
  deleteEntirely: Scalars['Boolean'];
  deleteEntirelyBasedOnId: Scalars['Boolean'];
  saveAlwaysTranslateSavedWordsSetting: Scalars['Boolean'];
  setUserAudioPreferences: User;
  sendErrorReportEmail: Scalars['Boolean'];
  batchIncrementHomonymProgression: Array<UserHomonym>;
  saveUserLearningShortcutSetting: UserLearningShortcut;
  saveUserLearningShortcutIndicator: UserLearningShortcut;
  saveUserLearningShortcutCelebration: UserLearningShortcut;
  /** @deprecated use 'batchRecordVariationViews' */
  logTranslationViewCounts?: Maybe<Scalars['Boolean']>;
  /** @deprecated use 'recordVariationViews' */
  recordTranslationViews?: Maybe<UserTranslation>;
  /** @deprecated use 'batchRecordVariationGamePoints' */
  recordGamePoints: Array<UserTranslationPostGameInformation>;
  /** @deprecated use 'saveVariationIsKnown' */
  saveKnownTranslation?: Maybe<Scalars['Boolean']>;
  /** @deprecated use 'removeSourcePhrase' */
  saveTranslationIsRemoved?: Maybe<UserTranslation>;
  /** @deprecated use 'saveVectorTranslationIsFluent' */
  saveTranslationIsFluent?: Maybe<UserTranslation>;
  /** @deprecated use 'recordVariationAudioPlay' */
  recordTranslationAudioPlay?: Maybe<UserTranslation>;
  removeSourcePhrase: Array<Scalars['String']>;
  enqueueCreateTargetToSourceLookupJob?: Maybe<Scalars['Boolean']>;
  enqueueCreateVariationIdLookupJob?: Maybe<Scalars['Boolean']>;
  /** @deprecated use 'batchRecordVariationViews' */
  batchRecordVectorTranslationViews?: Maybe<Scalars['Boolean']>;
  /** @deprecated use 'recordVectorVariationViews' */
  recordVectorTranslationViews?: Maybe<UserTranslation>;
  /** @deprecated use 'batchRecordVariationGamePoints' */
  batchRecordVectorTranslationGamePoints?: Maybe<Scalars['Boolean']>;
  /** @deprecated use 'recordVariationAudioPlay' */
  recordVectorTranslationAudioPlay?: Maybe<UserTranslation>;
  /** @deprecated use 'recordVariationAudioPlay' */
  saveVectorTranslationIsKnown?: Maybe<UserTranslation>;
  /** @deprecated use 'saveVariationIsKnown' */
  saveVectorTranslationIsFluent?: Maybe<UserTranslation>;
  /** @deprecated use 'saveVariationIsPracticed' */
  saveVectorTranslationIsPracticed?: Maybe<Scalars['Boolean']>;
  batchRecordVariationViews?: Maybe<Scalars['Boolean']>;
  recordVariationViews?: Maybe<UserTranslation>;
  batchRecordVariationGamePoints?: Maybe<Scalars['Boolean']>;
  saveVariationIsPracticed?: Maybe<Scalars['Boolean']>;
  recordVariationAudioPlay?: Maybe<UserTranslation>;
  saveVariationIsKnown?: Maybe<UserTranslation>;
  saveVariationIsFluent?: Maybe<UserTranslation>;
  updateLastSeenProgressionScore?: Maybe<UserTranslation>;
  updateTranslationInaccuracies: Array<TranslationInaccuracies>;
  updateTranslationInaccuraciesDashboard: Scalars['Boolean'];
  crawlWebPage: WebPageTranslationsResponse;
  crawlWebPageForAllLanguagePairs: Array<WebPageTranslationsResponse>;
  incrementWebPageVisit: Scalars['Boolean'];
  invalidateWebPage?: Maybe<Scalars['Boolean']>;
  /** @deprecated use 'addWordToVectoredWordList' */
  addWordToWordList?: Maybe<Scalars['Boolean']>;
  /** @deprecated use 'removeWordFromVectoredWordList' */
  removeWordFromWordList?: Maybe<Scalars['Boolean']>;
  /** @deprecated use 'addVariationToWordList' */
  addWordToVectoredWordList?: Maybe<Scalars['Boolean']>;
  /** @deprecated use 'removeVariationFromWordList' */
  removeWordFromVectoredWordList?: Maybe<Scalars['Boolean']>;
  addVariationToWordList?: Maybe<Scalars['Boolean']>;
  removeVariationFromWordList?: Maybe<Scalars['Boolean']>;
  updateDifficultyTier: User;
  createSubscriptionProduct: SubscriptionProduct;
  updateSubscriptionProduct: SubscriptionProduct;
  createSubscriptionPlan: SubscriptionProduct;
  archiveSubscriptionPlan: SubscriptionProduct;
  updateSubscriptionPlan: SubscriptionProduct;
};

export type MutationSetRedisKeyArgs = {
  input: SetRedisKeyInput;
};

export type MutationPutKinesisEventArgs = {
  input: PutKinesisEventInput;
};

export type MutationAddEventBusEventArgs = {
  input: AddEventBusEventInput;
};

export type MutationCheckCronArgs = {
  input: CheckCronInput;
};

export type MutationContactArgs = {
  email: Scalars['String'];
  name: Scalars['String'];
  feedback: Scalars['String'];
  submissionType?: Maybe<SubmissionType>;
};

export type MutationAddPopulationBlacklistedDomainArgs = {
  domain?: Maybe<Scalars['String']>;
};

export type MutationRemovePopulationBlacklistedDomainArgs = {
  domain?: Maybe<Scalars['String']>;
};

export type MutationAddUserBlacklistedDomainArgs = {
  domain?: Maybe<Scalars['String']>;
};

export type MutationRemoveUserBlacklistedDomainArgs = {
  domain?: Maybe<Scalars['String']>;
};

export type MutationEmailExtensionDownloadLinkArgs = {
  email: Scalars['String'];
};

export type MutationUpdateExampleSentencesArgs = {
  input: UpdateExampleSentencesInput;
};

export type MutationExpandTranslationArgs = {
  input: ExpandTranslationInput;
};

export type MutationUpdateExtensionStatArgs = {
  attributes: ExtensionStatAttributes;
};

export type MutationSaveLanguageSelectionArgs = {
  input: SaveLanguageSelectionInput;
};

export type MutationTranslatePhraseArgs = {
  input: MachineTranslationInput;
};

export type MutationTranslateChunkArgs = {
  input: MachineTranslationInput;
};

export type MutationProcessTextThroughNlpPipelineArgs = {
  input: NlpPipelineInput;
};

export type MutationMaybeAddOnboardingChecklistArgs = {
  checklistItem: ChecklistItem;
};

export type MutationMaybeMigrateOnboardingChecklistArgs = {
  numberOfMonthsToLookBack: Scalars['Int'];
};

export type MutationCreatePremiumSubscriptionArgs = {
  stripePaymentMethodId: Scalars['String'];
  stripePriceId: Scalars['String'];
  includeFreeTrial?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type MutationCreatePremiumSubscriptionForUserWithAdminCodeArgs = {
  userId: Scalars['String'];
  promoCode: Scalars['String'];
};

export type MutationSyncPremiumSubscriptionWithAppleAppStoreReceiptArgs = {
  appReceipt: Scalars['String'];
};

export type MutationSyncPremiumSubscriptionWithPayPalSubscriptionIdArgs = {
  payPalSubscriptionId: Scalars['String'];
};

export type MutationCancelPremiumPayPalSubscriptionArgs = {
  payPalSubscriptionId: Scalars['String'];
};

export type MutationCreatePromotionArgs = {
  input: CreatePromotionInput;
};

export type MutationUpdatePromotionArgs = {
  promotionId: Scalars['ID'];
  input: UpdatePromotionInput;
};

export type MutationCreatePromoCodeArgs = {
  promotionId: Scalars['ID'];
  input: CreatePromoCodeInput;
};

export type MutationReferUsersByEmailArgs = {
  emails: Array<Scalars['String']>;
};

export type MutationSetUserReferralRewardInfoArgs = {
  input: ReferralRewardInfoInput;
};

export type MutationSetUserReferralRewardInfoMetadataArgs = {
  input: ReferralRewardInfoMetadataInput;
};

export type MutationUpdateTranslationsDataSetsArgs = {
  input: GenerateDataSetsInput;
};

export type MutationGenerateV3TranslationsArgs = {
  minUpdateRecencyDate?: Maybe<Scalars['DateTime']>;
};

export type MutationGenerateTranslationsArgs = {
  minUpdateRecencyDate?: Maybe<Scalars['DateTime']>;
};

export type MutationReauthenticateArgs = {
  refreshToken: Scalars['String'];
  authToken?: Maybe<Scalars['String']>;
};

export type MutationAnonymousSignUpArgs = {
  user: AnonymousUserInput;
};

export type MutationSignInArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MutationLogOutArgs = {
  refreshToken: Scalars['String'];
};

export type MutationLogInUserArgs = {
  input: LogInUserInput;
};

export type MutationAuthenticateExternalLoginArgs = {
  input?: Maybe<AuthenticateExternalLoginInput>;
};

export type MutationRegisterUserArgs = {
  input: RegisterUserInput;
};

export type MutationAuthorizeArgs = {
  input?: Maybe<AuthorizeInput>;
};

export type MutationUpdateUsersNameArgs = {
  name: Scalars['String'];
};

export type MutationUpdateUsersPasswordV2Args = {
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
};

export type MutationSaveLanguageSettingsArgs = {
  input: LanguageSettingsInput;
};

export type MutationSaveLanguageLearningMotivationArgs = {
  input: LanguageLearningMotivationInput;
};

export type MutationSaveHasCompletedContextualizedOnboardingArgs = {
  input: HasCompletedContextualizedOnboardingInput;
};

export type MutationSaveDarkModeSettingArgs = {
  enabled: Scalars['Boolean'];
};

export type MutationSaveUnsubscribedToEmailsSettingArgs = {
  isUnsubscribedToEmails: Scalars['Boolean'];
};

export type MutationLostPasswordArgs = {
  email: Scalars['String'];
};

export type MutationResetPasswordArgs = {
  token: Scalars['String'];
  newPassword: Scalars['String'];
};

export type MutationUpdateUserPersonalizationArgs = {
  input?: Maybe<UpdateUserPersonalizationInput>;
};

export type MutationSaveRatingArgs = {
  input: RatingInput;
};

export type MutationDeleteEntirelyBasedOnIdArgs = {
  id: Scalars['String'];
};

export type MutationSaveAlwaysTranslateSavedWordsSettingArgs = {
  input?: Maybe<AlwaysTranslateSavedWordsSettingInput>;
};

export type MutationSetUserAudioPreferencesArgs = {
  input: AudioPreferencesInput;
};

export type MutationSendErrorReportEmailArgs = {
  errorReason: Scalars['String'];
  translation: Scalars['String'];
  errorDetails?: Maybe<Scalars['String']>;
  additionalInfo?: Maybe<ErrorReportAdditionalInfoInput>;
};

export type MutationBatchIncrementHomonymProgressionArgs = {
  input: Array<IncrementHomonymProgressionInput>;
};

export type MutationSaveUserLearningShortcutSettingArgs = {
  input: SaveUserLearningShortcutSettingInput;
};

export type MutationSaveUserLearningShortcutIndicatorArgs = {
  input: SaveUserLearningShortcutIndicatorInput;
};

export type MutationSaveUserLearningShortcutCelebrationArgs = {
  input: UserLearningShortcutInput;
};

export type MutationLogTranslationViewCountsArgs = {
  input: Array<TranslationViewCountInput>;
};

export type MutationRecordTranslationViewsArgs = {
  input: RecordTranslationViewsInput;
};

export type MutationRecordGamePointsArgs = {
  input: Array<TranslationGamePointsInput>;
};

export type MutationSaveKnownTranslationArgs = {
  input: KnownTranslationInput;
};

export type MutationSaveTranslationIsRemovedArgs = {
  input: SaveTranslationIsRemovedInput;
};

export type MutationSaveTranslationIsFluentArgs = {
  input: SaveTranslationIsFluentInput;
};

export type MutationRecordTranslationAudioPlayArgs = {
  input: RecordTranslationAudioPlayInput;
};

export type MutationRemoveSourcePhraseArgs = {
  input: RemoveSourcePhraseInput;
};

export type MutationBatchRecordVectorTranslationViewsArgs = {
  input: BatchRecordVectorTranslationViewsInput;
};

export type MutationRecordVectorTranslationViewsArgs = {
  input: RecordVectorTranslationViewsInput;
};

export type MutationBatchRecordVectorTranslationGamePointsArgs = {
  input: BatchRecordVectorTranslationGamePointsInput;
};

export type MutationRecordVectorTranslationAudioPlayArgs = {
  input: RecordVectorTranslationAudioPlayInput;
};

export type MutationSaveVectorTranslationIsKnownArgs = {
  input: SaveVectorTranslationIsKnownInput;
};

export type MutationSaveVectorTranslationIsFluentArgs = {
  input: SaveVectorTranslationIsFluentInput;
};

export type MutationSaveVectorTranslationIsPracticedArgs = {
  input: BatchRecordVectorTranslationGamePointsInput;
};

export type MutationBatchRecordVariationViewsArgs = {
  input: BatchRecordVariationViewsInput;
};

export type MutationRecordVariationViewsArgs = {
  input: RecordVariationViewsInput;
};

export type MutationBatchRecordVariationGamePointsArgs = {
  input: BatchRecordVariationGamePointsInput;
};

export type MutationSaveVariationIsPracticedArgs = {
  input: BatchRecordVariationGamePointsInput;
};

export type MutationRecordVariationAudioPlayArgs = {
  variationId: Scalars['String'];
};

export type MutationSaveVariationIsKnownArgs = {
  input: SaveVariationIsKnownInput;
};

export type MutationSaveVariationIsFluentArgs = {
  input: SaveVariationIsFluentInput;
};

export type MutationUpdateLastSeenProgressionScoreArgs = {
  input: UpdateLastSeenProgressionScoreInput;
};

export type MutationUpdateTranslationInaccuraciesArgs = {
  input: TranslationInaccuraciesInput;
};

export type MutationCrawlWebPageArgs = {
  input: CrawlWebPageInput;
};

export type MutationCrawlWebPageForAllLanguagePairsArgs = {
  input: CrawlWebPageForAllLanguagePairsInput;
};

export type MutationIncrementWebPageVisitArgs = {
  input: WebPageVisitInput;
};

export type MutationInvalidateWebPageArgs = {
  input: InvalidateWebPageTranslationsInput;
};

export type MutationAddWordToWordListArgs = {
  input: AddWordToWordListInput;
};

export type MutationRemoveWordFromWordListArgs = {
  input: RemoveWordFromWordListInput;
};

export type MutationAddWordToVectoredWordListArgs = {
  input: AddWordToVectoredWordListInput;
};

export type MutationRemoveWordFromVectoredWordListArgs = {
  input: RemoveWordFromVectoredWordListInput;
};

export type MutationAddVariationToWordListArgs = {
  input: AddVariationToWordListInput;
};

export type MutationRemoveVariationFromWordListArgs = {
  input: RemoveVariationFromWordListInput;
};

export type MutationUpdateDifficultyTierArgs = {
  input: DifficultyTierInput;
};

export type MutationCreateSubscriptionProductArgs = {
  input: CreateSubscriptionProductInput;
};

export type MutationUpdateSubscriptionProductArgs = {
  productId: Scalars['ID'];
  input: UpdateSubscriptionProductInput;
};

export type MutationCreateSubscriptionPlanArgs = {
  productId: Scalars['ID'];
  input: CreateSubscriptionPlanInput;
};

export type MutationArchiveSubscriptionPlanArgs = {
  productId: Scalars['ID'];
  planId: Scalars['ID'];
};

export type MutationUpdateSubscriptionPlanArgs = {
  planId: Scalars['ID'];
  input: UpdateSubscriptionPlanInput;
};

export type NewUserInformationInput = {
  name: Scalars['String'];
  email: Scalars['String'];
};

export type NlpPipelineInput = {
  text: Scalars['String'];
};

export type NonLatinSpellingPracticeQuestion = PracticeQuestion & {
  __typename?: 'NonLatinSpellingPracticeQuestion';
  questionType: PracticeType;
  sourcePhrase: Scalars['String'];
  targetPhraseAnswer: Scalars['String'];
  targetPhraseOptions: Array<Scalars['String']>;
  answerVariationId: Scalars['String'];
};

export type NonLatinSpellingQuestion = FluencyQuizQuestion & {
  __typename?: 'NonLatinSpellingQuestion';
  questionType: FluencyQuizType;
  sourcePhrase: Scalars['String'];
  targetPhraseAnswer: Scalars['String'];
  targetPhraseOptions: Array<Scalars['String']>;
};

export type NotificationFeedInput = {
  sourceLanguage: LanguageId;
};

export type OnboardingChecklist = {
  __typename?: 'OnboardingChecklist';
  hasCreatedToucanAccount: Scalars['Boolean'];
  hasHoveredOverTranslation: Scalars['Boolean'];
  hasPracticedFirstWord: Scalars['Boolean'];
  hasSavedFirstWord: Scalars['Boolean'];
  hasLearnedAboutPremium: Scalars['Boolean'];
};

export type OnboardingTranslationDataSetInput = {
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
};

export type OnboardingTranslations = {
  __typename?: 'OnboardingTranslations';
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  translations: Array<TranslationInfo>;
};

export type Pagination = {
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  lastSeenId?: Maybe<Scalars['ID']>;
};

export type PaginationBySkip = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  filter?: Maybe<Filter>;
  orderBy?: Maybe<OrderBy>;
  orderAsc?: Maybe<Scalars['Boolean']>;
};

export enum PartOfSpeech {
  Adjective = 'adjective',
  Adverb = 'adverb',
  Conjunction = 'conjunction',
  Determiner = 'determiner',
  Interjection = 'interjection',
  Noun = 'noun',
  Number = 'number',
  Phrase = 'phrase',
  Preposition = 'preposition',
  Pronoun = 'pronoun',
  Verb = 'verb',
}

export enum PaymentCountryCode {
  Us = 'US',
  In = 'IN',
  Mx = 'MX',
}

export type PlanPrice = {
  __typename?: 'PlanPrice';
  amount: Scalars['Float'];
  currencyCode: CurrencyCode;
  updatedAt: Scalars['DateTime'];
};

export type PlanPriceInput = {
  amount: Scalars['Int'];
  currencyCode: CurrencyCode;
};

export type Practice = {
  __typename?: 'Practice';
  questions: Array<PracticeQuestion>;
};

export type PracticeInput = {
  supportedQuestionTypes?: Maybe<Array<PracticeType>>;
  variationIds: Array<Scalars['String']>;
};

export type PracticeQuestion = {
  questionType: PracticeType;
};

export type PracticeQuizInput = {
  translations: Array<PracticeTranslationVector>;
  supportedQuestionTypes?: Maybe<Array<FluencyQuizType>>;
};

export type PracticeTranslationVector = {
  targetLanguage: LanguageId;
  sourceLanguage?: Maybe<LanguageId>;
  targetPhrase: Scalars['String'];
  prefix?: Maybe<Scalars['String']>;
  sourcePhrase: Scalars['String'];
  sourceRoot?: Maybe<Scalars['String']>;
};

export enum PracticeType {
  SwapPracticeQuestion = 'SwapPracticeQuestion',
  ListenPracticeQuestion = 'ListenPracticeQuestion',
  LatinSpellingPracticeQuestion = 'LatinSpellingPracticeQuestion',
  NonLatinSpellingPracticeQuestion = 'NonLatinSpellingPracticeQuestion',
  MatchPracticeQuestion = 'MatchPracticeQuestion',
  SourcePhraseSwapPracticeQuestion = 'SourcePhraseSwapPracticeQuestion',
}

export type PremiumSubscription = {
  __typename?: 'PremiumSubscription';
  provider?: Maybe<SubscriptionPaymentProvider>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  appStoreOriginalTransactionId?: Maybe<Scalars['String']>;
  payPalSubscriptionId?: Maybe<Scalars['String']>;
  promoCode?: Maybe<PublicPromoCode>;
  planDisplayName?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  startDate: Scalars['DateTime'];
  currentPeriodStart: Scalars['DateTime'];
  currentPeriodEnd: Scalars['DateTime'];
  cancelAtPeriodEnd: Scalars['Boolean'];
  cancelAt?: Maybe<Scalars['DateTime']>;
  trialStart?: Maybe<Scalars['DateTime']>;
  trialEnd?: Maybe<Scalars['DateTime']>;
  isAdmin: Scalars['Boolean'];
};

export type ProcessedText = {
  __typename?: 'ProcessedText';
  sentences?: Maybe<Array<Maybe<Sentence>>>;
  chunks?: Maybe<Array<Maybe<Chunk>>>;
  tokens?: Maybe<Array<Maybe<Token>>>;
};

export enum ProductProvider {
  Stripe = 'stripe',
  PayPal = 'payPal',
}

export enum ProgressChartType {
  Learned = 'Learned',
  Fluent = 'Fluent',
}

export type ProgressReport = {
  __typename?: 'ProgressReport';
  id?: Maybe<Scalars['ID']>;
  userPercentileText?: Maybe<Scalars['String']>;
  totalWordsInReportingPeriod?: Maybe<Scalars['Int']>;
  uniqueWordsInReportingPeriod?: Maybe<Scalars['Int']>;
  totalWordsSinceAccountCreation?: Maybe<Scalars['Int']>;
  weekOf?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type PromoCode = {
  __typename?: 'PromoCode';
  code: Scalars['String'];
  promoId: Scalars['String'];
  redemptions: Scalars['Int'];
  maxRedemptions: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PromoCodeInput = {
  code: Scalars['String'];
  maxRedemptions?: Maybe<Scalars['Int']>;
};

export type Promotion = {
  __typename?: 'Promotion';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  eligibility?: Maybe<PromotionEligibility>;
  period: PromotionDuration;
  expiresAt?: Maybe<Scalars['DateTime']>;
  isEnabled: Scalars['Boolean'];
  isAdmin?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  promoCodes?: Maybe<Array<PromoCode>>;
};

export enum PromotionDuration {
  P1W = 'P1W',
  P30D = 'P30D',
  P1M = 'P1M',
  P2M = 'P2M',
  P3M = 'P3M',
  P4M = 'P4M',
  P5M = 'P5M',
  P6M = 'P6M',
  P7M = 'P7M',
  P8M = 'P8M',
  P9M = 'P9M',
  P10M = 'P10M',
  P11M = 'P11M',
  P1Y = 'P1Y',
}

export enum PromotionEligibility {
  OthersNeverSubscribed = 'OthersNeverSubscribed',
  SelfAndOthersNeverSubscribed = 'SelfAndOthersNeverSubscribed',
}

export type PublicPromoCode = {
  __typename?: 'PublicPromoCode';
  code: Scalars['String'];
  promoName: Scalars['String'];
  promoPeriod: Scalars['String'];
  promoEligibility: PromotionEligibility;
  isRedeemed: Scalars['Boolean'];
  referredByUserId?: Maybe<Scalars['String']>;
  referredByName?: Maybe<Scalars['String']>;
};

export type PutKinesisEventInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  ads: Array<Ad>;
  getSignUpsSince: RecentUsers;
  countUsersSince: Scalars['Int'];
  getRedisKey?: Maybe<Scalars['String']>;
  contextlessLearningMoment: ContextlessLearningMoment;
  getAdditionalWordInfoV4: AdditionalWordInfo;
  getOnboardingTranslationDataSet: OnboardingTranslations;
  defaultBlacklistedDomains?: Maybe<DefaultBlacklistedDomains>;
  userBlacklistedDomains?: Maybe<UserBlacklistedDomains>;
  /** @deprecated Field no longer supported */
  duolingo?: Maybe<Duolingo>;
  extensionStat?: Maybe<ExtensionStat>;
  extraPractice?: Maybe<ExtraPracticeMoment>;
  contextualizedOnboardingExtraPractice: ContextualizedOnboardingExtraPracticeMoment;
  fluencyQuiz?: Maybe<FluencyQuiz>;
  /** @deprecated use 'randomQuizQuestionV2' */
  randomQuizQuestion?: Maybe<FluencyQuizQuestion>;
  /** @deprecated use 'practiceQuizV2' */
  practiceQuiz?: Maybe<FluencyQuiz>;
  languageLeaderboard: Array<LanguageLeaderboardItem>;
  learningShortcuts: Array<Maybe<LearningShortcut>>;
  notificationFeed: Array<FeedNotification>;
  getCurrentSubscriptions?: Maybe<Array<Maybe<StripeSubscription>>>;
  getDefaultCardInfo?: Maybe<StripeCardInfo>;
  getPremiumSubscription?: Maybe<PremiumSubscription>;
  getQueuedPremiumSubscription?: Maybe<PremiumSubscription>;
  getStripeCustomerPortal?: Maybe<StripeCustomerPortal>;
  premiumStripePrices?: Maybe<Array<StripePrice>>;
  randomPracticeQuestion?: Maybe<PracticeQuestion>;
  practice?: Maybe<Practice>;
  getPromotionDurations: Array<Scalars['String']>;
  getAdminPromoCodes: Array<Scalars['String']>;
  userReferral: UserReferral;
  getReferrer: ReferralUserInfo;
  _?: Maybe<Scalars['String']>;
  /** @deprecated use 'translationsDataSetV4' */
  translationsDataSet: TranslationsDataSet;
  /** @deprecated use 'translationsDataSetV4' */
  translationsDataSetV2: TranslationsDataSet;
  /** @deprecated use 'translationsDataSetV4' */
  translationsDataSetV3: TranslationsDataSet;
  translationsDataSetV4: TranslationsDataSet;
  user?: Maybe<User>;
  me?: Maybe<User>;
  isResetTokenValid: Scalars['Boolean'];
  getUsers: Array<User>;
  getUserAccountCreditsInfo: UserAccountCredits;
  progressReportForCurrentUser?: Maybe<ProgressReport>;
  progressReportById: ProgressReport;
  /** @deprecated use 'vectorUserTranslation' */
  userTranslation?: Maybe<UserTranslation>;
  /** @deprecated use 'userTranslationVariation' */
  vectorUserTranslation?: Maybe<UserTranslation>;
  userTranslationVariation?: Maybe<UserTranslation>;
  removedSourcePhrases: Array<Scalars['String']>;
  userTranslationsWithin?: Maybe<UserTranslationsForProgression>;
  /** @deprecated No longer in use. */
  userTranslationProgressChart?: Maybe<Array<Scalars['Int']>>;
  /** @deprecated use 'userTranslationsByVariationId' */
  userTranslationsByPhraseVectorSet?: Maybe<Array<UserTranslation>>;
  userTranslationsByVariationIds?: Maybe<Array<UserTranslation>>;
  getTranslationInaccuracies: Array<TranslationInaccuracies>;
  webPageTranslation: WebPageTranslationsResponse;
  webPageTranslations: Array<WebPageTranslationsResponse>;
  wordList?: Maybe<WordList>;
  getSubscriptionProduct: SubscriptionProduct;
  getUsersPremiumProduct: SubscriptionProduct;
  getSubscriptionProducts: Array<SubscriptionProduct>;
};

export type QueryGetSignUpsSinceArgs = {
  input: RecentUsersSearchInput;
};

export type QueryCountUsersSinceArgs = {
  input: RecentUsersSearchInput;
};

export type QueryGetRedisKeyArgs = {
  input: GetRedisKeyInput;
};

export type QueryContextlessLearningMomentArgs = {
  input: ContextlessLearningInput;
};

export type QueryGetAdditionalWordInfoV4Args = {
  variationId?: Maybe<Scalars['String']>;
};

export type QueryGetOnboardingTranslationDataSetArgs = {
  input: OnboardingTranslationDataSetInput;
};

export type QueryExtraPracticeArgs = {
  input: ExtraPracticeInput;
};

export type QueryFluencyQuizArgs = {
  input: FluencyQuizInput;
};

export type QueryRandomQuizQuestionArgs = {
  input: RandomQuizQuestionInput;
};

export type QueryPracticeQuizArgs = {
  input: PracticeQuizInput;
};

export type QueryLearningShortcutsArgs = {
  input: LearningShortcutsInput;
};

export type QueryNotificationFeedArgs = {
  input: NotificationFeedInput;
};

export type QueryPremiumStripePricesArgs = {
  paymentCountryCode?: Maybe<PaymentCountryCode>;
};

export type QueryRandomPracticeQuestionArgs = {
  input: RandomPracticeQuestionInput;
};

export type QueryPracticeArgs = {
  input: PracticeInput;
};

export type QueryUserReferralArgs = {
  userId: Scalars['String'];
};

export type QueryGetReferrerArgs = {
  redeemCode: Scalars['String'];
};

export type QueryTranslationsDataSetArgs = {
  input: TranslationsDataSetInput;
};

export type QueryTranslationsDataSetV2Args = {
  input: TranslationsDataSetInput;
};

export type QueryTranslationsDataSetV3Args = {
  input: TranslationsDataSetV3Input;
};

export type QueryTranslationsDataSetV4Args = {
  input: TranslationsDataSetV4Input;
};

export type QueryUserArgs = {
  id: Scalars['ID'];
};

export type QueryIsResetTokenValidArgs = {
  token: Scalars['String'];
};

export type QueryGetUsersArgs = {
  input?: Maybe<GetUsersInput>;
};

export type QueryGetUserAccountCreditsInfoArgs = {
  userId: Scalars['ID'];
};

export type QueryProgressReportByIdArgs = {
  id: Scalars['ID'];
};

export type QueryUserTranslationArgs = {
  input: UserTranslationInput;
};

export type QueryVectorUserTranslationArgs = {
  input: VectorUserTranslationInput;
};

export type QueryUserTranslationVariationArgs = {
  variationId: Scalars['String'];
};

export type QueryRemovedSourcePhrasesArgs = {
  input: RemovedSourcePhrasesInput;
};

export type QueryUserTranslationsWithinArgs = {
  input: UserTranslationsWithinInput;
};

export type QueryUserTranslationProgressChartArgs = {
  type?: Maybe<ProgressChartType>;
};

export type QueryUserTranslationsByPhraseVectorSetArgs = {
  input: UserTranslationsByPhraseVectorSetInput;
};

export type QueryUserTranslationsByVariationIdsArgs = {
  input: UserTranslationsByVariationIdsInput;
};

export type QueryGetTranslationInaccuraciesArgs = {
  language: LanguageId;
};

export type QueryWebPageTranslationArgs = {
  input: WebPageQueryInput;
};

export type QueryWebPageTranslationsArgs = {
  filter?: Maybe<WebPagesSearchInput>;
};

export type QueryWordListArgs = {
  input: WordListInput;
};

export type QueryGetSubscriptionProductArgs = {
  productId: Scalars['ID'];
};

export type QueryGetUsersPremiumProductArgs = {
  userId: Scalars['ID'];
};

export type QueryGetSubscriptionProductsArgs = {
  filters: SubscriptionProductFilters;
};

export type RandomPracticeQuestionInput = {
  supportedQuestionTypes?: Maybe<Array<PracticeType>>;
  variationId: Scalars['String'];
};

export type RandomQuizQuestionInput = {
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  sourceRoot: Scalars['String'];
  sourcePrefix?: Maybe<Scalars['String']>;
  targetPhrase: Scalars['String'];
};

export type Rating = {
  __typename?: 'Rating';
  ratingValue: Scalars['Int'];
  ratedAt: Scalars['DateTime'];
};

export type RatingInput = {
  ratingValue: Scalars['Int'];
};

export type RecentUsers = {
  __typename?: 'RecentUsers';
  users: Array<Maybe<User>>;
  count: Scalars['Int'];
  since: Scalars['DateTime'];
};

export type RecentUsersSearchInput = {
  createdSince: Scalars['DateTime'];
  source?: Maybe<Scalars['String']>;
  excludeSource?: Maybe<Scalars['String']>;
  minWordCount?: Maybe<Scalars['Int']>;
  registrationStatus?: Maybe<RegistrationStatus>;
};

export type RecordTranslationAudioPlayInput = {
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  targetPhrase: Scalars['String'];
};

export type RecordTranslationViewsInput = {
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  targetPhrase: Scalars['String'];
  incrementalViews: Scalars['Int'];
};

export type RecordVariationGamePointsInput = {
  variationId: Scalars['String'];
  pointDifference?: Maybe<Scalars['Int']>;
};

export type RecordVariationViewsInput = {
  variationId: Scalars['String'];
  incrementalViews: Scalars['Int'];
};

export type RecordVectorTranslationAudioPlayInput = {
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  sourcePhrase: Scalars['String'];
  sourceRoot: Scalars['String'];
  sourcePrefix?: Maybe<Scalars['String']>;
  sourceSuffix?: Maybe<Scalars['String']>;
  targetPhrase: Scalars['String'];
};

export type RecordVectorTranslationGamePointsInput = {
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  sourcePhrase: Scalars['String'];
  sourceRoot: Scalars['String'];
  sourcePrefix?: Maybe<Scalars['String']>;
  sourceSuffix?: Maybe<Scalars['String']>;
  targetPhrase: Scalars['String'];
  pointDifference?: Maybe<Scalars['Int']>;
};

export type RecordVectorTranslationViewsInput = {
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  sourcePhrase: Scalars['String'];
  sourceRoot: Scalars['String'];
  sourcePrefix?: Maybe<Scalars['String']>;
  sourceSuffix?: Maybe<Scalars['String']>;
  targetPhrase: Scalars['String'];
  incrementalViews: Scalars['Int'];
};

export type ReferUsersByEmailResponse = {
  __typename?: 'ReferUsersByEmailResponse';
  referral: ReferralInfo;
};

export type ReferralInfo = UserReferralInfo & {
  __typename?: 'ReferralInfo';
  userId: Scalars['ID'];
  redeemCode: Scalars['String'];
  linkUrl: Scalars['String'];
  sharedTo: Array<EmailShareInfo>;
  rewardInfo: RewardInfo;
};

export type ReferralRewardInfoInput = {
  rewardType: Scalars['String'];
};

export type ReferralRewardInfoMetadataInput = {
  schoolDonation?: Maybe<SchoolDonationRewardMetadataInput>;
};

export type ReferralRewardSummary = {
  __typename?: 'ReferralRewardSummary';
  accountCreditRewards?: Maybe<AccountCreditRewardSummary>;
  schoolDonationRewards?: Maybe<SchoolDonationRewardSummary>;
};

export type ReferralUserInfo = {
  __typename?: 'ReferralUserInfo';
  redeemCode: Scalars['String'];
  isAnonymous: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  sourceLanguage?: Maybe<LanguageId>;
  targetLanguage?: Maybe<LanguageId>;
};

export type ReferredUserInfo = {
  __typename?: 'ReferredUserInfo';
  name?: Maybe<Scalars['String']>;
};

export type RegisterOptions = {
  redeemCode?: Maybe<Scalars['String']>;
  isUnsubscribedToEmails?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['String']>;
};

export type RegisterUserInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  sourceLanguage?: Maybe<LanguageId>;
  targetLanguage?: Maybe<LanguageId>;
  countryCode?: Maybe<Scalars['String']>;
  options?: Maybe<RegisterOptions>;
};

export enum RegistrationStatus {
  Anonymous = 'anonymous',
  Registered = 'registered',
}

export type RemoveSourcePhraseInput = {
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  sourcePhrase: Scalars['String'];
};

export type RemoveVariationFromWordListInput = {
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  variationId: Scalars['String'];
};

export type RemoveWordFromVectoredWordListInput = {
  targetLanguage: LanguageId;
  sourceLanguage: LanguageId;
  sourcePhrase: Scalars['String'];
  sourceRoot: Scalars['String'];
  sourcePrefix?: Maybe<Scalars['String']>;
  sourceSuffix?: Maybe<Scalars['String']>;
  targetPhrase: Scalars['String'];
};

export type RemoveWordFromWordListInput = {
  language: LanguageId;
  sourceLanguage?: Maybe<LanguageId>;
  translation: Scalars['String'];
};

export type RemovedSourcePhrasesInput = {
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
};

export type ResetPasswordReturn = {
  __typename?: 'ResetPasswordReturn';
  jwt?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['String']>;
};

export type RewardInfo = {
  rewardType: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SaveLanguageSelectionInput = {
  email: Scalars['String'];
  languageSelection: InDevLanguageId;
  otherLanguage?: Maybe<Scalars['String']>;
};

export type SaveTranslationIsFluentInput = {
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  targetPhrase: Scalars['String'];
  isFluent: Scalars['Boolean'];
};

export type SaveTranslationIsKnownInput = {
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  targetPhrase: Scalars['String'];
  isKnown: Scalars['Boolean'];
};

export type SaveTranslationIsRemovedInput = {
  targetLanguage: LanguageId;
  sourceLanguage: LanguageId;
  targetPhrase: Scalars['String'];
  isRemoved: Scalars['Boolean'];
};

export type SaveUserLearningShortcutIndicatorInput = {
  learningShortcutId: Scalars['String'];
  indicator: Scalars['String'];
};

export type SaveUserLearningShortcutSettingInput = {
  learningShortcutId: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type SaveVariationIsFluentInput = {
  variationId: Scalars['String'];
  isFluent: Scalars['Boolean'];
};

export type SaveVariationIsKnownInput = {
  variationId: Scalars['String'];
  isKnown: Scalars['Boolean'];
};

export type SaveVectorTranslationIsFluentInput = {
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  sourcePhrase: Scalars['String'];
  sourceRoot: Scalars['String'];
  sourcePrefix?: Maybe<Scalars['String']>;
  sourceSuffix?: Maybe<Scalars['String']>;
  targetPhrase: Scalars['String'];
  isFluent: Scalars['Boolean'];
};

export type SaveVectorTranslationIsKnownInput = {
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  sourcePhrase: Scalars['String'];
  sourceRoot: Scalars['String'];
  sourcePrefix?: Maybe<Scalars['String']>;
  sourceSuffix?: Maybe<Scalars['String']>;
  targetPhrase: Scalars['String'];
  isKnown: Scalars['Boolean'];
};

export type SchoolDonationRedemptionReward = {
  __typename?: 'SchoolDonationRedemptionReward';
  id: Scalars['ID'];
  schoolId: Scalars['String'];
  schoolName: Scalars['String'];
  referredUser?: Maybe<ReferredUserInfo>;
  createdAt: Scalars['DateTime'];
  redeemedAt: Scalars['DateTime'];
};

export type SchoolDonationRewardInfo = RewardInfo & {
  __typename?: 'SchoolDonationRewardInfo';
  rewardType: Scalars['String'];
  metadata: SchoolDonationRewardMetadata;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SchoolDonationRewardMetadata = {
  __typename?: 'SchoolDonationRewardMetadata';
  schoolId: Scalars['String'];
  schoolName: Scalars['String'];
};

export type SchoolDonationRewardMetadataInput = {
  schoolName: Scalars['String'];
  schoolLocation: Scalars['String'];
};

export type SchoolDonationRewardSummary = {
  __typename?: 'SchoolDonationRewardSummary';
  rewardType: Scalars['String'];
  rewards: Array<SchoolDonationRedemptionReward>;
};

export type Sentence = {
  __typename?: 'Sentence';
  text?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['Int']>;
  fleschKincaidReadingEase?: Maybe<Scalars['Float']>;
  fleschKincaidGradeLevel?: Maybe<Scalars['Float']>;
};

export type SetRedisKeyInput = {
  prefix: Scalars['String'];
  key: Scalars['String'];
  value: Scalars['String'];
};

export type SignUpOptions = {
  redeemCode?: Maybe<Scalars['String']>;
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type SourcePhraseMultipleChoiceExtraPracticeQuestion = ExtraPracticeQuestion & {
  __typename?: 'SourcePhraseMultipleChoiceExtraPracticeQuestion';
  questionType: ExtraPracticeQuestionType;
  sourcePhrase: Scalars['String'];
  targetPhraseAnswer: Scalars['String'];
  targetPhraseOptions: Array<Scalars['String']>;
  answerHomonymRecordId: Scalars['String'];
};

export type SourcePhraseSwapPracticeQuestion = PracticeQuestion & {
  __typename?: 'SourcePhraseSwapPracticeQuestion';
  questionType: PracticeType;
  sourcePhrase: Scalars['String'];
  targetPhraseAnswer: Scalars['String'];
  targetPhraseOptions: Array<Scalars['String']>;
  answerVariationId: Scalars['String'];
};

export type SourcePhraseSwapQuestion = FluencyQuizQuestion & {
  __typename?: 'SourcePhraseSwapQuestion';
  questionType: FluencyQuizType;
  sourcePhrase: Scalars['String'];
  targetPhraseAnswer: Scalars['String'];
  targetPhraseOptions: Array<Scalars['String']>;
};

export type StripeCardInfo = {
  __typename?: 'StripeCardInfo';
  id: Scalars['String'];
  brand: Scalars['String'];
  lastFour: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type StripeCustomerPortal = {
  __typename?: 'StripeCustomerPortal';
  url: Scalars['String'];
};

export type StripePrice = {
  __typename?: 'StripePrice';
  stripePriceId: Scalars['String'];
  currency: Scalars['String'];
  unitAmount: Scalars['Int'];
  interval: Scalars['String'];
  intervalCount: Scalars['Int'];
  trialPeriodDays?: Maybe<Scalars['Int']>;
};

export type StripeSubscription = {
  __typename?: 'StripeSubscription';
  subscriptionId: Scalars['String'];
  status: Scalars['String'];
  billingInterval: Scalars['String'];
  nextBillingDate: Scalars['Int'];
  billingAmountDecimal: Scalars['Float'];
};

export enum StripeSubscriptionStatusFilter {
  Canceled = 'canceled',
}

export enum SubmissionType {
  Contact = 'contact',
  DeleteAccount = 'deleteAccount',
}

export type Subscription = {
  __typename?: 'Subscription';
  _?: Maybe<Scalars['String']>;
};

export enum SubscriptionPaymentProvider {
  Stripe = 'Stripe',
  Apple = 'Apple',
  PayPal = 'PayPal',
  ToucanPromo = 'ToucanPromo',
}

export type SubscriptionPlan = {
  __typename?: 'SubscriptionPlan';
  id: Scalars['ID'];
  productId: Scalars['ID'];
  externalId?: Maybe<ExternalId>;
  externalIds: Array<ExternalPlanId>;
  status: SubscriptionPlanStatus;
  name: Scalars['String'];
  nameTranslationKey: Scalars['String'];
  description: Scalars['String'];
  descriptionTranslationKey: Scalars['String'];
  price: PlanPrice;
  recurringInterval: Interval;
  locale: Scalars['String'];
  trialPeriod?: Maybe<TrialPeriodInfo>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SubscriptionPlanFilters = {
  locale?: Maybe<Scalars['String']>;
  productProvider?: Maybe<ProductProvider>;
};

export enum SubscriptionPlanStatus {
  Active = 'active',
  Archived = 'archived',
}

export type SubscriptionProduct = {
  __typename?: 'SubscriptionProduct';
  id: Scalars['ID'];
  externalIds: Array<ExternalId>;
  name: Scalars['String'];
  nameTranslationKey: Scalars['String'];
  description: Scalars['String'];
  descriptionTranslationKey: Scalars['String'];
  plans: Array<SubscriptionPlan>;
  addOns: Array<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SubscriptionProductPlansArgs = {
  filters?: Maybe<SubscriptionPlanFilters>;
};

export type SubscriptionProductFilters = {
  locale?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
};

export type SwapPracticeQuestion = PracticeQuestion & {
  __typename?: 'SwapPracticeQuestion';
  questionType: PracticeType;
  targetPhrase: Scalars['String'];
  sourcePhraseAnswer: Scalars['String'];
  sourcePhraseOptions: Array<Scalars['String']>;
  answerVariationId: Scalars['String'];
};

export type SwapQuestion = FluencyQuizQuestion & {
  __typename?: 'SwapQuestion';
  questionType: FluencyQuizType;
  targetPhrase: Scalars['String'];
  sourcePhraseAnswer: Scalars['String'];
  sourcePhraseOptions: Array<Scalars['String']>;
};

export type SyncPaypalSubscriptionsInput = {
  limit: Scalars['Int'];
  updatedBefore: Scalars['DateTime'];
};

export type SyncStripeSubscriptionsInput = {
  limit: Scalars['Int'];
  status: StripeSubscriptionStatusFilter;
  updatedBefore: Scalars['DateTime'];
};

export type TargetPhraseMultipleChoice = ContextlessLearning & {
  __typename?: 'TargetPhraseMultipleChoice';
  learningType: ContextlessLearningType;
  targetPhrase: Scalars['String'];
  sourcePhraseAnswer: Scalars['String'];
  sourceRoot: Scalars['String'];
  sourcePrefix?: Maybe<Scalars['String']>;
  sourceSuffix?: Maybe<Scalars['String']>;
  sourcePhraseOptions: Array<Scalars['String']>;
  variationId: Scalars['String'];
};

export type TargetPhraseMultipleChoiceExtraPracticeQuestion = ExtraPracticeQuestion & {
  __typename?: 'TargetPhraseMultipleChoiceExtraPracticeQuestion';
  questionType: ExtraPracticeQuestionType;
  targetPhrase: Scalars['String'];
  sourcePhraseAnswer: Scalars['String'];
  sourcePhraseOptions: Array<Scalars['String']>;
  answerHomonymRecordId: Scalars['String'];
};

export type TitleImage = {
  __typename?: 'TitleImage';
  titleImageUrl: Scalars['String'];
  titleImageAltText: Scalars['String'];
};

export type Token = {
  __typename?: 'Token';
  text?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  pos?: Maybe<Scalars['String']>;
  entityType?: Maybe<Scalars['String']>;
  dependencyArc?: Maybe<DependencyArc>;
};

export type Tokens = {
  __typename?: 'Tokens';
  id: Scalars['String'];
  jwt: Scalars['String'];
  refreshToken: Scalars['String'];
  userId: Scalars['String'];
  accessToken: Scalars['String'];
  expiresInPeriod: Scalars['String'];
  isNewUser?: Maybe<Scalars['Boolean']>;
};

export type Translation = {
  __typename?: 'Translation';
  englishWord?: Maybe<Scalars['String']>;
  lexicon?: Maybe<Array<Maybe<Lexicon>>>;
  isLive?: Maybe<Scalars['Boolean']>;
};

export type TranslationGamePointsInput = {
  language: LanguageId;
  sourceLanguage?: Maybe<LanguageId>;
  translation: Scalars['String'];
  pointDifference?: Maybe<Scalars['Int']>;
};

export type TranslationInaccuracies = {
  __typename?: 'TranslationInaccuracies';
  errorType: Scalars['String'];
  translationId: Scalars['String'];
  language: LanguageId;
  reportedUrl: Scalars['String'];
  translatedPhrase: Scalars['String'];
  word: Scalars['String'];
  sentenceWithoutTranslation: Scalars['String'];
  variationId?: Maybe<Scalars['String']>;
};

export type TranslationInaccuraciesInput = {
  word: Scalars['String'];
  translatedPhrase: Scalars['String'];
  sourcePhrase: Scalars['String'];
  sentenceWithTranslation: Scalars['String'];
  sentenceWithoutTranslation: Scalars['String'];
  language: LanguageId;
  reportedUrl: Scalars['String'];
  errorType: TranslationInaccuracyError;
  translationId: Scalars['String'];
  variationId?: Maybe<Scalars['String']>;
};

export enum TranslationInaccuracyError {
  Misspelling = 'misspelling',
  Grammar = 'grammar',
  Context = 'context',
}

export type TranslationInfo = {
  __typename?: 'TranslationInfo';
  id: Scalars['String'];
  homonyms: Array<Homonym>;
  sourcePhrase: Scalars['String'];
};

export type TranslationPhraseVectorInput = {
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  sourcePhrase: Scalars['String'];
  targetPhrase: Scalars['String'];
};

export enum TranslationStatus {
  Valid = 'Valid',
  Invalid = 'Invalid',
  Inactive = 'Inactive',
}

export type TranslationViewCount = {
  translation: Scalars['String'];
  newViewCount: Scalars['Int'];
};

export type TranslationViewCountInput = {
  language?: Maybe<LanguageId>;
  sourceLanguage?: Maybe<LanguageId>;
  translationViewCounts: Array<TranslationViewCount>;
};

export type TranslationWithGenderFormsAndPrefixes = {
  __typename?: 'TranslationWithGenderFormsAndPrefixes';
  _com?: Maybe<Scalars['String']>;
  _fem?: Maybe<Scalars['String']>;
  _masc?: Maybe<Scalars['String']>;
  a?: Maybe<Scalars['String']>;
  an?: Maybe<Scalars['String']>;
  her?: Maybe<Scalars['String']>;
  his?: Maybe<Scalars['String']>;
  my?: Maybe<Scalars['String']>;
  our?: Maybe<Scalars['String']>;
  the?: Maybe<Scalars['String']>;
  their?: Maybe<Scalars['String']>;
  your?: Maybe<Scalars['String']>;
};

export type TranslationsDataSet = {
  __typename?: 'TranslationsDataSet';
  url: Scalars['String'];
};

export type TranslationsDataSetInput = {
  language: LanguageId;
};

export type TranslationsDataSetV3Input = {
  targetLanguage: LanguageId;
  sourceLanguage: LanguageId;
  useCdn?: Maybe<Scalars['Boolean']>;
};

export type TranslationsDataSetV4Input = {
  targetLanguage: LanguageId;
  sourceLanguage: LanguageId;
};

export type TrialPeriodInfo = {
  __typename?: 'TrialPeriodInfo';
  description: Scalars['String'];
  descriptionTranslationKey: Scalars['String'];
  interval: Interval;
};

export type TrialPeriodInput = {
  description: Scalars['String'];
  descriptionTranslationKey: Scalars['String'];
  interval: IntervalInput;
};

export type UpdateExampleSentencesInput = {
  forceUpdate: Scalars['Boolean'];
};

export type UpdateExtensionStatResult = {
  __typename?: 'UpdateExtensionStatResult';
  success: Scalars['Boolean'];
  extensionStatId: Scalars['ID'];
  extensionStat?: Maybe<ExtensionStat>;
};

export type UpdateLastSeenProgressionScoreInput = {
  variationId: Scalars['String'];
  lastSeenProgressionScore: Scalars['Int'];
};

export type UpdatePromotionInput = {
  description?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  eligibility?: Maybe<PromotionEligibility>;
  period?: Maybe<PromotionDuration>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
};

export type UpdateSubscriptionPlanInput = {
  name?: Maybe<Scalars['String']>;
  nameTranslationKey?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  descriptionTranslationKey?: Maybe<Scalars['String']>;
  trialPeriod?: Maybe<TrialPeriodInput>;
};

export type UpdateSubscriptionProductInput = {
  externalIds?: Maybe<Array<ExternalIdInput>>;
  name?: Maybe<Scalars['String']>;
  nameTranslationKey?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  descriptionTranslationKey?: Maybe<Scalars['String']>;
  addOns?: Maybe<Array<Scalars['String']>>;
  isEnabled?: Maybe<Scalars['Boolean']>;
};

export type UpdateUserPersonalizationInput = {
  language?: Maybe<Scalars['String']>;
  isDarkModeEnabled?: Maybe<Scalars['Boolean']>;
  isContextlessLearningMomentEnabled?: Maybe<Scalars['Boolean']>;
  sourceLanguage?: Maybe<LanguageId>;
};

export type UpdatedUserPersonalization = {
  __typename?: 'UpdatedUserPersonalization';
  selectedLanguage?: Maybe<Scalars['String']>;
  isDarkModeEnabled?: Maybe<Scalars['Boolean']>;
  isContextlessLearningMomentEnabled?: Maybe<Scalars['Boolean']>;
  sourceLanguage?: Maybe<LanguageId>;
};

export type User = {
  __typename?: 'User';
  onboardingChecklist?: Maybe<OnboardingChecklist>;
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  createdAt: Scalars['String'];
  isPro?: Maybe<Scalars['Boolean']>;
  isPremiumSubscribed: Scalars['Boolean'];
  hasBeenPremiumSubscribed: Scalars['Boolean'];
  wordCount?: Maybe<Scalars['String']>;
  selectedLanguage?: Maybe<Scalars['String']>;
  selectedLanguageVariant?: Maybe<Scalars['String']>;
  sourceLanguage?: Maybe<Scalars['String']>;
  hasGrantedExtensionPermissions?: Maybe<Scalars['Boolean']>;
  hasFullyOnboarded?: Maybe<Scalars['Boolean']>;
  isDarkModeEnabled?: Maybe<Scalars['Boolean']>;
  isContextlessLearningMomentEnabled?: Maybe<Scalars['Boolean']>;
  registrationStatus?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  experiments: Array<Experiment>;
  customerID?: Maybe<Scalars['String']>;
  isExternallyAuthed: Scalars['Boolean'];
  hasSavedFirstWord: Scalars['Boolean'];
  translationInaccuracies: Array<TranslationInaccuracies>;
  premiumSubscription?: Maybe<PremiumSubscription>;
  rating?: Maybe<Rating>;
  hasCompletedFluencyCheck: Scalars['Boolean'];
  hasCompletedPracticeQuiz: Scalars['Boolean'];
  hasCompletedContextualizedOnboarding: Scalars['Boolean'];
  isUnsubscribedToEmails: Scalars['Boolean'];
  hasInitialLanguageBeenSelected: Scalars['Boolean'];
  isAlwaysTranslateSavedWordsEnabled: Scalars['Boolean'];
  referral?: Maybe<ReferralInfo>;
  referralRewardSummary?: Maybe<ReferralRewardSummary>;
  countryCode?: Maybe<Scalars['String']>;
  audioPreferences?: Maybe<UserAudioPreferences>;
  learningShortcuts: Array<Maybe<UserLearningShortcut>>;
  learningShortcut?: Maybe<UserLearningShortcut>;
  learningShortcutTopic: UserLearningShortcutTopicProgress;
  difficultyTier: DifficultyTier;
  densityLevel: Scalars['Float'];
  difficultyWeights: Array<DifficultyWeight>;
};

export type UserLearningShortcutArgs = {
  input?: Maybe<UserLearningShortcutInput>;
};

export type UserLearningShortcutTopicArgs = {
  input?: Maybe<UserLearningShortcutTopicInput>;
};

export type UserAccountCredits = {
  __typename?: 'UserAccountCredits';
  userId: Scalars['ID'];
  totalCredits: Scalars['Float'];
  totalAvailableCredits: Scalars['Float'];
  ledger: Array<AccountCreditLedgerRecord>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type UserAudioPreferences = {
  __typename?: 'UserAudioPreferences';
  playbackRate: Scalars['Float'];
  voiceNumber: Scalars['Int'];
};

export type UserBlacklistedDomains = {
  __typename?: 'UserBlacklistedDomains';
  blacklist?: Maybe<Array<Scalars['String']>>;
};

export type UserDetailsNonSensitive = {
  __typename?: 'UserDetailsNonSensitive';
  id: Scalars['ID'];
  name: Scalars['String'];
  registrationStatus: Scalars['String'];
};

export type UserHomonym = {
  __typename?: 'UserHomonym';
  homonymRecordId: Scalars['String'];
  homonymSourcePhrase?: Maybe<Scalars['String']>;
  homonymTargetPhrase?: Maybe<Scalars['String']>;
  progressionScore: Scalars['Int'];
  possibleProgressionScore: Scalars['Int'];
  learnedAt?: Maybe<Scalars['DateTime']>;
};

export type UserLearningShortcut = {
  __typename?: 'UserLearningShortcut';
  learningShortcut: LearningShortcut;
  learningShortcutId: Scalars['String'];
  isActive: Scalars['Boolean'];
  focusLearningShortcutTopic?: Maybe<LearningShortcutTopic>;
  indicator?: Maybe<Scalars['String']>;
  userLearningShortcutProgress: UserLearningShortcutProgress;
};

export type UserLearningShortcutInput = {
  learningShortcutId?: Maybe<Scalars['String']>;
};

export type UserLearningShortcutProgress = {
  __typename?: 'UserLearningShortcutProgress';
  completedLearningShortcutPhraseCount: Scalars['Int'];
  possibleLearningShortcutPhraseCount: Scalars['Int'];
  numberOfWordsLearnedToday: Scalars['Int'];
  userLearningShortcutTopicProgress: Array<UserLearningShortcutTopicProgress>;
  hasCelebrated: Scalars['Boolean'];
};

export type UserLearningShortcutTopicInput = {
  learningShortcutTopicId?: Maybe<Scalars['String']>;
};

export type UserLearningShortcutTopicProgress = {
  __typename?: 'UserLearningShortcutTopicProgress';
  learningShortcutTopic: LearningShortcutTopic;
  completedTopicPhraseCount: Scalars['Int'];
  possibleTopicPhraseCount: Scalars['Int'];
  homonyms: Array<UserHomonym>;
};

export type UserReferral = UserReferralInfo & {
  __typename?: 'UserReferral';
  userId: Scalars['ID'];
  redeemCode: Scalars['String'];
  linkUrl: Scalars['String'];
  sharedTo?: Maybe<Array<EmailShareInfo>>;
  rewardInfo: RewardInfo;
  createdAt: Scalars['DateTime'];
};

export type UserReferralInfo = {
  userId: Scalars['ID'];
  redeemCode: Scalars['String'];
  linkUrl: Scalars['String'];
  sharedTo?: Maybe<Array<EmailShareInfo>>;
  rewardInfo: RewardInfo;
};

export type UserTranslation = {
  __typename?: 'UserTranslation';
  /** @deprecated use the 'variation' graph to access this value */
  translation: Scalars['String'];
  variationId?: Maybe<Scalars['String']>;
  /** @deprecated use the 'variation' graph to access this value */
  sourceLanguage: LanguageId;
  /** @deprecated use the 'variation' graph to access this value */
  targetLanguage: LanguageId;
  /** @deprecated use the 'variation' graph to access this value */
  targetPhrase: Scalars['String'];
  /** @deprecated use the 'variation' graph to access this value */
  sourcePhrase: Scalars['String'];
  /** @deprecated use the 'variation' graph to access this value */
  sourceRoot: Scalars['String'];
  /** @deprecated use the 'variation' graph to access this value */
  sourcePrefix?: Maybe<Scalars['String']>;
  /** @deprecated use the 'variation' graph to access this value */
  sourceSuffix?: Maybe<Scalars['String']>;
  viewCount: Scalars['Int'];
  audioPlayCount: Scalars['Int'];
  gamePoints: Scalars['Int'];
  isKnown: Scalars['Boolean'];
  /** @deprecated use 'removedSourcePhrases' */
  isRemoved: Scalars['Boolean'];
  isSaved: Scalars['Boolean'];
  learnedAt?: Maybe<Scalars['DateTime']>;
  fluentAt?: Maybe<Scalars['DateTime']>;
  progressionScore: Scalars['Int'];
  lastSeenProgressionScore?: Maybe<Scalars['Int']>;
  lastPracticedAt?: Maybe<Scalars['DateTime']>;
  variation?: Maybe<UserTranslationVariation>;
};

export type UserTranslationInput = {
  language: LanguageId;
  sourceLanguage?: Maybe<LanguageId>;
  translation: Scalars['String'];
};

export type UserTranslationPostGameInformation = {
  __typename?: 'UserTranslationPostGameInformation';
  language: LanguageId;
  translation: Scalars['String'];
  viewCount: Scalars['Int'];
  beforeGamePoints: Scalars['Int'];
  afterGamePoints: Scalars['Int'];
};

export type UserTranslationVariation = {
  __typename?: 'UserTranslationVariation';
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  targetPhrase: Scalars['String'];
  sourcePhrase: Scalars['String'];
  sourceRoot: Scalars['String'];
  sourcePrefix?: Maybe<Scalars['String']>;
  sourceSuffix?: Maybe<Scalars['String']>;
};

export type UserTranslationsByPhraseVectorSetInput = {
  phraseVectors: Array<TranslationPhraseVectorInput>;
};

export type UserTranslationsByVariationIdsInput = {
  variationIds: Array<Scalars['String']>;
};

export type UserTranslationsForProgression = {
  __typename?: 'UserTranslationsForProgression';
  totalResults: Scalars['Int'];
  userTranslations: Array<UserTranslation>;
};

export enum UserTranslationsWithinFilterType {
  LearnedAt = 'LearnedAt',
  FluentAt = 'FluentAt',
}

export type UserTranslationsWithinInput = {
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  filterBy: UserTranslationsWithinFilterType;
  before?: Maybe<Scalars['DateTime']>;
  after?: Maybe<Scalars['DateTime']>;
  sortBy?: Maybe<UserTranslationsWithinSortType>;
  lastSeenTranslationCursor?: Maybe<LastSeenTranslationCursorInput>;
  pageSize?: Maybe<Scalars['Int']>;
};

export enum UserTranslationsWithinSortType {
  LastLearned = 'LastLearned',
  FirstLearned = 'FirstLearned',
  LastFluent = 'LastFluent',
  FirstFluent = 'FirstFluent',
}

export type Variation = {
  __typename?: 'Variation';
  id: Scalars['String'];
  isCommon: Scalars['Boolean'];
  targetPhrase: Scalars['String'];
  sourcePrefix?: Maybe<Scalars['String']>;
  variationId: Scalars['String'];
};

export type VectorUserTranslationInput = {
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  sourcePhrase: Scalars['String'];
  targetPhrase: Scalars['String'];
};

export enum WebPageProcessingStatus {
  Queued = 'Queued',
  Inprogress = 'Inprogress',
  Failed = 'Failed',
  Done = 'Done',
  Waiting = 'Waiting',
}

export type WebPageQueryInput = {
  url: Scalars['String'];
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
};

export type WebPageReplacement = {
  __typename?: 'WebPageReplacement';
  address: Scalars['String'];
  translations: Array<Maybe<WebPageTranslation>>;
};

export type WebPageTranslation = {
  __typename?: 'WebPageTranslation';
  sourcePhrase: Scalars['String'];
  targetPhrase: Scalars['String'];
  instanceOfSourcePhrase?: Maybe<Scalars['Int']>;
};

export type WebPageTranslationsResponse = {
  __typename?: 'WebPageTranslationsResponse';
  url: Scalars['String'];
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  processingStatus: WebPageProcessingStatus;
  replacements: Array<WebPageReplacement>;
  translationStatus: TranslationStatus;
};

export type WebPageVisitInput = {
  url: Scalars['String'];
};

export type WebPagesSearchInput = {
  url?: Maybe<Scalars['String']>;
  sourceLanguage?: Maybe<LanguageId>;
  targetLanguage?: Maybe<LanguageId>;
  limit?: Maybe<Scalars['Int']>;
};

export type Word = {
  __typename?: 'Word';
  language?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  value: Scalars['String'];
};

export type WordInfo = {
  __typename?: 'WordInfo';
  word: Scalars['String'];
  translation: Scalars['String'];
  definition?: Maybe<Scalars['String']>;
  partOfSpeech?: Maybe<PartOfSpeech>;
};

export type WordInfoForGameInput = {
  numWordsDesired: Scalars['Int'];
  language: LanguageId;
  sourceLanguage?: Maybe<LanguageId>;
  providedWords?: Maybe<Array<Scalars['String']>>;
  wordListId?: Maybe<Scalars['String']>;
  maxWordLength?: Maybe<Scalars['Int']>;
  partOfSpeech?: Maybe<PartOfSpeech>;
  requireDefinition?: Maybe<Scalars['Boolean']>;
};

export type WordList = {
  __typename?: 'WordList';
  id: Scalars['String'];
  size: Scalars['Int'];
  numberLearned: Scalars['Int'];
  words: Array<WordListWord>;
};

export type WordListInput = {
  language: LanguageId;
  sourceLanguage?: Maybe<LanguageId>;
  sortBy?: Maybe<WordListSortType>;
  query?: Maybe<Scalars['String']>;
  lastSeenTranslationCursor?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
  wordListCursor?: Maybe<Scalars['String']>;
};

export enum WordListSortType {
  LastSeen = 'LastSeen',
  LeastToMostSeen = 'LeastToMostSeen',
  MostToLeastSeen = 'MostToLeastSeen',
  RecentlyAdded = 'RecentlyAdded',
}

export type WordListWord = {
  __typename?: 'WordListWord';
  _id?: Maybe<Scalars['String']>;
  translation: Scalars['String'];
  viewCount: Scalars['Float'];
  gamePoints: Scalars['Int'];
  isKnown: Scalars['Boolean'];
  englishWord: Scalars['String'];
  sourcePhrase: Scalars['String'];
  pronunciation?: Maybe<Scalars['String']>;
  definition?: Maybe<Scalars['String']>;
  partOfSpeech?: Maybe<PartOfSpeech>;
  partOfSpeechDisplay?: Maybe<Scalars['String']>;
  learnedAt?: Maybe<Scalars['DateTime']>;
  progressionScore: Scalars['Int'];
  lastPracticedAt?: Maybe<Scalars['DateTime']>;
  fluentAt?: Maybe<Scalars['DateTime']>;
  targetPhrase: Scalars['String'];
  sourceRoot: Scalars['String'];
  sourcePrefix?: Maybe<Scalars['String']>;
  sourceSuffix?: Maybe<Scalars['String']>;
  variationId?: Maybe<Scalars['String']>;
};

export type ExtensionStat = {
  __typename?: 'extensionStat';
  users?: Maybe<Scalars['Int']>;
  reviews?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum OrderBy {
  CreatedAt = 'createdAt',
  Word = 'word',
}

export type GetExtensionStatsQueryVariables = Exact<{ [key: string]: never }>;

export type GetExtensionStatsQuery = { __typename?: 'Query' } & {
  extensionStat?: Maybe<{ __typename?: 'extensionStat' } & Pick<ExtensionStat, 'users' | 'reviews'>>;
};

export type UpdateExtensionStatsMutationVariables = Exact<{
  attr: ExtensionStatAttributes;
}>;

export type UpdateExtensionStatsMutation = { __typename?: 'Mutation' } & {
  updateExtensionStat: { __typename?: 'UpdateExtensionStatResult' } & Pick<UpdateExtensionStatResult, 'success'> & {
      extensionStat?: Maybe<{ __typename?: 'extensionStat' } & Pick<ExtensionStat, 'users' | 'reviews'>>;
    };
};

export type GetSubscriptionProductsQueryVariables = Exact<{
  filters: SubscriptionProductFilters;
}>;

export type GetSubscriptionProductsQuery = { __typename?: 'Query' } & {
  getSubscriptionProducts: Array<
    { __typename?: 'SubscriptionProduct' } & Pick<
      SubscriptionProduct,
      | 'id'
      | 'addOns'
      | 'description'
      | 'descriptionTranslationKey'
      | 'isEnabled'
      | 'name'
      | 'nameTranslationKey'
      | 'createdAt'
      | 'updatedAt'
    > & {
        externalIds: Array<{ __typename?: 'ExternalId' } & Pick<ExternalId, 'id' | 'productProvider'>>;
        plans: Array<
          { __typename?: 'SubscriptionPlan' } & Pick<
            SubscriptionPlan,
            'id' | 'status' | 'name' | 'description' | 'createdAt' | 'updatedAt'
          > & {
              externalId: { __typename?: 'ExternalId' } & Pick<ExternalId, 'id' | 'productProvider'>;
              trialPeriod?: Maybe<
                { __typename?: 'TrialPeriodInfo' } & Pick<TrialPeriodInfo, 'description'> & {
                    interval: { __typename?: 'Interval' } & Pick<Interval, 'intervalUnit' | 'count'>;
                  }
              >;
            }
        >;
      }
  >;
};

export type GetSubscriptionProductQueryVariables = Exact<{
  productId: Scalars['ID'];
}>;

export type GetSubscriptionProductQuery = { __typename?: 'Query' } & {
  getSubscriptionProduct: { __typename?: 'SubscriptionProduct' } & Pick<
    SubscriptionProduct,
    | 'id'
    | 'addOns'
    | 'description'
    | 'descriptionTranslationKey'
    | 'isEnabled'
    | 'name'
    | 'nameTranslationKey'
    | 'createdAt'
    | 'updatedAt'
  > & {
      externalIds: Array<{ __typename?: 'ExternalId' } & Pick<ExternalId, 'id' | 'productProvider'>>;
      plans: Array<
        { __typename?: 'SubscriptionPlan' } & Pick<
          SubscriptionPlan,
          | 'id'
          | 'status'
          | 'name'
          | 'nameTranslationKey'
          | 'description'
          | 'descriptionTranslationKey'
          | 'createdAt'
          | 'updatedAt'
        > & {
            externalId: { __typename?: 'ExternalId' } & Pick<ExternalId, 'id' | 'productProvider'>;
            trialPeriod?: Maybe<
              { __typename?: 'TrialPeriodInfo' } & Pick<
                TrialPeriodInfo,
                'description' | 'descriptionTranslationKey'
              > & { interval: { __typename?: 'Interval' } & Pick<Interval, 'intervalUnit' | 'count'> }
            >;
          }
      >;
    };
};

export type CreateSubscriptionProductMutationVariables = Exact<{
  input: CreateSubscriptionProductInput;
}>;

export type CreateSubscriptionProductMutation = { __typename?: 'Mutation' } & {
  createSubscriptionProduct: { __typename?: 'SubscriptionProduct' } & Pick<
    SubscriptionProduct,
    | 'id'
    | 'name'
    | 'nameTranslationKey'
    | 'description'
    | 'descriptionTranslationKey'
    | 'addOns'
    | 'isEnabled'
    | 'createdAt'
    | 'updatedAt'
  > & {
      externalIds: Array<{ __typename?: 'ExternalId' } & Pick<ExternalId, 'id' | 'productProvider'>>;
      plans: Array<
        { __typename?: 'SubscriptionPlan' } & Pick<
          SubscriptionPlan,
          'id' | 'status' | 'name' | 'description' | 'createdAt' | 'updatedAt'
        > & {
            externalId: { __typename?: 'ExternalId' } & Pick<ExternalId, 'id' | 'productProvider'>;
            trialPeriod?: Maybe<
              { __typename?: 'TrialPeriodInfo' } & Pick<TrialPeriodInfo, 'description'> & {
                  interval: { __typename?: 'Interval' } & Pick<Interval, 'intervalUnit' | 'count'>;
                }
            >;
          }
      >;
    };
};

export type UpdateSubscriptionProductMutationVariables = Exact<{
  productId: Scalars['ID'];
  input: UpdateSubscriptionProductInput;
}>;

export type UpdateSubscriptionProductMutation = { __typename?: 'Mutation' } & {
  updateSubscriptionProduct: { __typename?: 'SubscriptionProduct' } & Pick<
    SubscriptionProduct,
    | 'id'
    | 'name'
    | 'nameTranslationKey'
    | 'description'
    | 'descriptionTranslationKey'
    | 'addOns'
    | 'isEnabled'
    | 'createdAt'
    | 'updatedAt'
  > & {
      externalIds: Array<{ __typename?: 'ExternalId' } & Pick<ExternalId, 'id' | 'productProvider'>>;
      plans: Array<
        { __typename?: 'SubscriptionPlan' } & Pick<
          SubscriptionPlan,
          'id' | 'status' | 'name' | 'description' | 'createdAt' | 'updatedAt'
        >
      >;
    };
};

export type CreateSubscriptionPlanMutationVariables = Exact<{
  productId: Scalars['ID'];
  input: CreateSubscriptionPlanInput;
}>;

export type CreateSubscriptionPlanMutation = { __typename?: 'Mutation' } & {
  createSubscriptionPlan: { __typename?: 'SubscriptionProduct' } & Pick<
    SubscriptionProduct,
    'id' | 'isEnabled' | 'createdAt' | 'updatedAt'
  > & {
      plans: Array<
        { __typename?: 'SubscriptionPlan' } & Pick<
          SubscriptionPlan,
          'id' | 'status' | 'name' | 'description' | 'createdAt' | 'updatedAt'
        > & {
            externalId: { __typename?: 'ExternalId' } & Pick<ExternalId, 'id' | 'productProvider'>;
            price: { __typename?: 'PlanPrice' } & Pick<PlanPrice, 'amount' | 'currencyCode'>;
            recurringInterval: { __typename?: 'Interval' } & Pick<Interval, 'intervalUnit' | 'count'>;
            trialPeriod?: Maybe<
              { __typename?: 'TrialPeriodInfo' } & Pick<TrialPeriodInfo, 'description'> & {
                  interval: { __typename?: 'Interval' } & Pick<Interval, 'intervalUnit' | 'count'>;
                }
            >;
          }
      >;
    };
};

export type UpdateSubscriptionPlanMutationVariables = Exact<{
  planId: Scalars['ID'];
  input: UpdateSubscriptionPlanInput;
}>;

export type UpdateSubscriptionPlanMutation = { __typename?: 'Mutation' } & {
  updateSubscriptionPlan: { __typename?: 'SubscriptionProduct' } & Pick<
    SubscriptionProduct,
    'id' | 'isEnabled' | 'createdAt' | 'updatedAt'
  > & {
      plans: Array<
        { __typename?: 'SubscriptionPlan' } & Pick<
          SubscriptionPlan,
          | 'id'
          | 'status'
          | 'name'
          | 'nameTranslationKey'
          | 'description'
          | 'descriptionTranslationKey'
          | 'createdAt'
          | 'updatedAt'
        > & {
            externalId: { __typename?: 'ExternalId' } & Pick<ExternalId, 'id' | 'productProvider'>;
            price: { __typename?: 'PlanPrice' } & Pick<PlanPrice, 'amount' | 'currencyCode'>;
            recurringInterval: { __typename?: 'Interval' } & Pick<Interval, 'intervalUnit' | 'count'>;
            trialPeriod?: Maybe<
              { __typename?: 'TrialPeriodInfo' } & Pick<
                TrialPeriodInfo,
                'description' | 'descriptionTranslationKey'
              > & { interval: { __typename?: 'Interval' } & Pick<Interval, 'intervalUnit' | 'count'> }
            >;
          }
      >;
    };
};

export type ArchiveSubscriptionPlanMutationVariables = Exact<{
  productId: Scalars['ID'];
  planId: Scalars['ID'];
}>;

export type ArchiveSubscriptionPlanMutation = { __typename?: 'Mutation' } & {
  archiveSubscriptionPlan: { __typename?: 'SubscriptionProduct' } & Pick<
    SubscriptionProduct,
    'id' | 'isEnabled' | 'createdAt' | 'updatedAt'
  > & {
      plans: Array<
        { __typename?: 'SubscriptionPlan' } & Pick<
          SubscriptionPlan,
          'id' | 'status' | 'name' | 'description' | 'createdAt' | 'updatedAt'
        >
      >;
    };
};

export type WebPagesQueryVariables = Exact<{
  filter?: Maybe<WebPagesSearchInput>;
}>;

export type WebPagesQuery = { __typename?: 'Query' } & {
  webPageTranslations: Array<
    { __typename?: 'WebPageTranslationsResponse' } & Pick<
      WebPageTranslationsResponse,
      'url' | 'translationStatus' | 'processingStatus'
    >
  >;
};

export type WebPageResultQueryVariables = Exact<{
  input: WebPageQueryInput;
}>;

export type WebPageResultQuery = { __typename?: 'Query' } & {
  webPageTranslation: { __typename?: 'WebPageTranslationsResponse' } & Pick<
    WebPageTranslationsResponse,
    'url' | 'translationStatus' | 'processingStatus'
  > & {
      replacements: Array<
        { __typename?: 'WebPageReplacement' } & Pick<WebPageReplacement, 'address'> & {
            translations: Array<
              Maybe<
                { __typename?: 'WebPageTranslation' } & Pick<
                  WebPageTranslation,
                  'sourcePhrase' | 'instanceOfSourcePhrase' | 'targetPhrase'
                >
              >
            >;
          }
      >;
    };
};

export type CrawlerWebPageMutationVariables = Exact<{
  input: CrawlWebPageInput;
}>;

export type CrawlerWebPageMutation = { __typename?: 'Mutation' } & {
  crawlWebPage: { __typename?: 'WebPageTranslationsResponse' } & Pick<WebPageTranslationsResponse, 'url'>;
};

export type GetMeQueryVariables = Exact<{ [key: string]: never }>;

export type GetMeQuery = { __typename?: 'Query' } & {
  me?: Maybe<{ __typename?: 'User' } & Pick<User, 'id' | 'email' | 'name' | 'createdAt' | 'role'>>;
};

export type GetUserInfoQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetUserInfoQuery = { __typename?: 'Query' } & {
  user?: Maybe<
    { __typename?: 'User' } & Pick<
      User,
      'id' | 'email' | 'name' | 'selectedLanguage' | 'sourceLanguage' | 'isPremiumSubscribed'
    >
  >;
};

export type GetUserProfileQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetUserProfileQuery = { __typename?: 'Query' } & {
  user?: Maybe<
    { __typename?: 'User' } & Pick<
      User,
      | 'id'
      | 'email'
      | 'name'
      | 'selectedLanguage'
      | 'sourceLanguage'
      | 'isPremiumSubscribed'
      | 'createdAt'
      | 'role'
      | 'difficultyTier'
    > & { rating?: Maybe<{ __typename?: 'Rating' } & Pick<Rating, 'ratedAt' | 'ratingValue'>> }
  >;
};

export type GetUsersQueryVariables = Exact<{
  input: GetUsersInput;
}>;

export type GetUsersQuery = { __typename?: 'Query' } & {
  getUsers: Array<{ __typename?: 'User' } & Pick<User, 'id' | 'name' | 'email' | 'selectedLanguage'>>;
};

export type GetUserInaccuraciesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetUserInaccuraciesQuery = { __typename?: 'Query' } & {
  user?: Maybe<
    { __typename?: 'User' } & {
      translationInaccuracies: Array<
        { __typename?: 'TranslationInaccuracies' } & Pick<
          TranslationInaccuracies,
          | 'word'
          | 'language'
          | 'sentenceWithoutTranslation'
          | 'translatedPhrase'
          | 'reportedUrl'
          | 'errorType'
          | 'variationId'
        >
      >;
    }
  >;
};

export type GetUserSubscriptionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetUserSubscriptionQuery = { __typename?: 'Query' } & Pick<Query, 'getAdminPromoCodes'> & {
    user?: Maybe<
      { __typename?: 'User' } & Pick<User, 'isPro' | 'email'> & {
          premiumSubscription?: Maybe<
            { __typename?: 'PremiumSubscription' } & Pick<
              PremiumSubscription,
              'planDisplayName' | 'currentPeriodEnd' | 'stripeCustomerId' | 'stripePriceId' | 'isAdmin' | 'provider'
            >
          >;
        }
    >;
    getUserAccountCreditsInfo: { __typename?: 'UserAccountCredits' } & Pick<
      UserAccountCredits,
      'userId' | 'totalCredits' | 'totalAvailableCredits' | 'createdAt' | 'updatedAt'
    > & {
        ledger: Array<
          { __typename?: 'AccountCreditLedgerRecord' } & Pick<
            AccountCreditLedgerRecord,
            'amount' | 'description' | 'createdAt'
          >
        >;
      };
  };

export type CreatePremiumSubscriptionForUserWithAdminCodeMutationVariables = Exact<{
  userId: Scalars['String'];
  promoCode: Scalars['String'];
}>;

export type CreatePremiumSubscriptionForUserWithAdminCodeMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'createPremiumSubscriptionForUserWithAdminCode'
>;

export type DeleteEntirelyBasedOnIdMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteEntirelyBasedOnIdMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'deleteEntirelyBasedOnId'>;

export const GetExtensionStatsDocument = gql`
  query getExtensionStats {
    extensionStat {
      users
      reviews
    }
  }
`;

/**
 * __useGetExtensionStatsQuery__
 *
 * To run a query within a React component, call `useGetExtensionStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExtensionStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExtensionStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetExtensionStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetExtensionStatsQuery, GetExtensionStatsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExtensionStatsQuery, GetExtensionStatsQueryVariables>(GetExtensionStatsDocument, options);
}
export function useGetExtensionStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetExtensionStatsQuery, GetExtensionStatsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExtensionStatsQuery, GetExtensionStatsQueryVariables>(
    GetExtensionStatsDocument,
    options,
  );
}
export type GetExtensionStatsQueryHookResult = ReturnType<typeof useGetExtensionStatsQuery>;
export type GetExtensionStatsLazyQueryHookResult = ReturnType<typeof useGetExtensionStatsLazyQuery>;
export type GetExtensionStatsQueryResult = Apollo.QueryResult<GetExtensionStatsQuery, GetExtensionStatsQueryVariables>;
export const UpdateExtensionStatsDocument = gql`
  mutation updateExtensionStats($attr: ExtensionStatAttributes!) {
    updateExtensionStat(attributes: $attr) {
      extensionStat {
        users
        reviews
      }
      success
    }
  }
`;
export type UpdateExtensionStatsMutationFn = Apollo.MutationFunction<
  UpdateExtensionStatsMutation,
  UpdateExtensionStatsMutationVariables
>;

/**
 * __useUpdateExtensionStatsMutation__
 *
 * To run a mutation, you first call `useUpdateExtensionStatsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExtensionStatsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExtensionStatsMutation, { data, loading, error }] = useUpdateExtensionStatsMutation({
 *   variables: {
 *      attr: // value for 'attr'
 *   },
 * });
 */
export function useUpdateExtensionStatsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateExtensionStatsMutation, UpdateExtensionStatsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateExtensionStatsMutation, UpdateExtensionStatsMutationVariables>(
    UpdateExtensionStatsDocument,
    options,
  );
}
export type UpdateExtensionStatsMutationHookResult = ReturnType<typeof useUpdateExtensionStatsMutation>;
export type UpdateExtensionStatsMutationResult = Apollo.MutationResult<UpdateExtensionStatsMutation>;
export type UpdateExtensionStatsMutationOptions = Apollo.BaseMutationOptions<
  UpdateExtensionStatsMutation,
  UpdateExtensionStatsMutationVariables
>;
export const GetSubscriptionProductsDocument = gql`
  query getSubscriptionProducts($filters: SubscriptionProductFilters!) {
    getSubscriptionProducts(filters: $filters) {
      id
      addOns
      description
      descriptionTranslationKey
      externalIds {
        id
        productProvider
      }
      isEnabled
      name
      nameTranslationKey
      plans {
        id
        status
        name
        description
        externalId {
          id
          productProvider
        }
        trialPeriod {
          interval {
            intervalUnit
            count
          }
          description
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetSubscriptionProductsQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionProductsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetSubscriptionProductsQuery(
  baseOptions: Apollo.QueryHookOptions<GetSubscriptionProductsQuery, GetSubscriptionProductsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSubscriptionProductsQuery, GetSubscriptionProductsQueryVariables>(
    GetSubscriptionProductsDocument,
    options,
  );
}
export function useGetSubscriptionProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSubscriptionProductsQuery, GetSubscriptionProductsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSubscriptionProductsQuery, GetSubscriptionProductsQueryVariables>(
    GetSubscriptionProductsDocument,
    options,
  );
}
export type GetSubscriptionProductsQueryHookResult = ReturnType<typeof useGetSubscriptionProductsQuery>;
export type GetSubscriptionProductsLazyQueryHookResult = ReturnType<typeof useGetSubscriptionProductsLazyQuery>;
export type GetSubscriptionProductsQueryResult = Apollo.QueryResult<
  GetSubscriptionProductsQuery,
  GetSubscriptionProductsQueryVariables
>;
export const GetSubscriptionProductDocument = gql`
  query getSubscriptionProduct($productId: ID!) {
    getSubscriptionProduct(productId: $productId) {
      id
      addOns
      description
      descriptionTranslationKey
      externalIds {
        id
        productProvider
        hasTrialPeriod
      }
      price {
        amount
        currencyCode
      }
      recurringInterval {
        intervalUnit
        count
      }
      isEnabled
      name
      nameTranslationKey
      plans {
        id
        status
        name
        nameTranslationKey
        description
        descriptionTranslationKey
        externalId {
          id
          productProvider
        }
        trialPeriod {
          interval {
            intervalUnit
            count
          }
          description
          descriptionTranslationKey
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetSubscriptionProductQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionProductQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetSubscriptionProductQuery(
  baseOptions: Apollo.QueryHookOptions<GetSubscriptionProductQuery, GetSubscriptionProductQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSubscriptionProductQuery, GetSubscriptionProductQueryVariables>(
    GetSubscriptionProductDocument,
    options,
  );
}
export function useGetSubscriptionProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSubscriptionProductQuery, GetSubscriptionProductQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSubscriptionProductQuery, GetSubscriptionProductQueryVariables>(
    GetSubscriptionProductDocument,
    options,
  );
}
export type GetSubscriptionProductQueryHookResult = ReturnType<typeof useGetSubscriptionProductQuery>;
export type GetSubscriptionProductLazyQueryHookResult = ReturnType<typeof useGetSubscriptionProductLazyQuery>;
export type GetSubscriptionProductQueryResult = Apollo.QueryResult<
  GetSubscriptionProductQuery,
  GetSubscriptionProductQueryVariables
>;
export const CreateSubscriptionProductDocument = gql`
  mutation createSubscriptionProduct($input: CreateSubscriptionProductInput!) {
    createSubscriptionProduct(input: $input) {
      id
      name
      nameTranslationKey
      description
      descriptionTranslationKey
      externalIds {
        id
        productProvider
        hasTrialPeriod
      }
      price {
        amount
        currencyCode
      }
      recurringInterval {
        intervalUnit
        count
      }
      addOns
      plans {
        id
        status
        name
        description
        externalId {
          id
          productProvider
        }
        trialPeriod {
          interval {
            intervalUnit
            count
          }
          description
        }
        createdAt
        updatedAt
      }
      isEnabled
      createdAt
      updatedAt
    }
  }
`;
export type CreateSubscriptionProductMutationFn = Apollo.MutationFunction<
  CreateSubscriptionProductMutation,
  CreateSubscriptionProductMutationVariables
>;

/**
 * __useCreateSubscriptionProductMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionProductMutation, { data, loading, error }] = useCreateSubscriptionProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubscriptionProductMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSubscriptionProductMutation,
    CreateSubscriptionProductMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateSubscriptionProductMutation, CreateSubscriptionProductMutationVariables>(
    CreateSubscriptionProductDocument,
    options,
  );
}
export type CreateSubscriptionProductMutationHookResult = ReturnType<typeof useCreateSubscriptionProductMutation>;
export type CreateSubscriptionProductMutationResult = Apollo.MutationResult<CreateSubscriptionProductMutation>;
export type CreateSubscriptionProductMutationOptions = Apollo.BaseMutationOptions<
  CreateSubscriptionProductMutation,
  CreateSubscriptionProductMutationVariables
>;
export const UpdateSubscriptionProductDocument = gql`
  mutation updateSubscriptionProduct($productId: ID!, $input: UpdateSubscriptionProductInput!) {
    updateSubscriptionProduct(productId: $productId, input: $input) {
      id
      name
      nameTranslationKey
      description
      descriptionTranslationKey
      externalIds {
        id
        productProvider
      }
      addOns
      plans {
        id
        status
        name
        description
        createdAt
        updatedAt
      }
      isEnabled
      createdAt
      updatedAt
    }
  }
`;
export type UpdateSubscriptionProductMutationFn = Apollo.MutationFunction<
  UpdateSubscriptionProductMutation,
  UpdateSubscriptionProductMutationVariables
>;

/**
 * __useUpdateSubscriptionProductMutation__
 *
 * To run a mutation, you first call `useUpdateSubscriptionProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubscriptionProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubscriptionProductMutation, { data, loading, error }] = useUpdateSubscriptionProductMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubscriptionProductMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSubscriptionProductMutation,
    UpdateSubscriptionProductMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSubscriptionProductMutation, UpdateSubscriptionProductMutationVariables>(
    UpdateSubscriptionProductDocument,
    options,
  );
}
export type UpdateSubscriptionProductMutationHookResult = ReturnType<typeof useUpdateSubscriptionProductMutation>;
export type UpdateSubscriptionProductMutationResult = Apollo.MutationResult<UpdateSubscriptionProductMutation>;
export type UpdateSubscriptionProductMutationOptions = Apollo.BaseMutationOptions<
  UpdateSubscriptionProductMutation,
  UpdateSubscriptionProductMutationVariables
>;
export const CreateSubscriptionPlanDocument = gql`
  mutation createSubscriptionPlan($productId: ID!, $input: CreateSubscriptionPlanInput!) {
    createSubscriptionPlan(productId: $productId, input: $input) {
      id
      plans {
        id
        status
        name
        description
        externalId {
          id
          productProvider
        }
        price {
          amount
          currencyCode
        }
        recurringInterval {
          intervalUnit
          count
        }
        trialPeriod {
          interval {
            intervalUnit
            count
          }
          description
        }
        createdAt
        updatedAt
      }
      isEnabled
      createdAt
      updatedAt
    }
  }
`;
export type CreateSubscriptionPlanMutationFn = Apollo.MutationFunction<
  CreateSubscriptionPlanMutation,
  CreateSubscriptionPlanMutationVariables
>;

/**
 * __useCreateSubscriptionPlanMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionPlanMutation, { data, loading, error }] = useCreateSubscriptionPlanMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubscriptionPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateSubscriptionPlanMutation, CreateSubscriptionPlanMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateSubscriptionPlanMutation, CreateSubscriptionPlanMutationVariables>(
    CreateSubscriptionPlanDocument,
    options,
  );
}
export type CreateSubscriptionPlanMutationHookResult = ReturnType<typeof useCreateSubscriptionPlanMutation>;
export type CreateSubscriptionPlanMutationResult = Apollo.MutationResult<CreateSubscriptionPlanMutation>;
export type CreateSubscriptionPlanMutationOptions = Apollo.BaseMutationOptions<
  CreateSubscriptionPlanMutation,
  CreateSubscriptionPlanMutationVariables
>;
export const UpdateSubscriptionPlanDocument = gql`
  mutation updateSubscriptionPlan($planId: ID!, $input: UpdateSubscriptionPlanInput!) {
    updateSubscriptionPlan(planId: $planId, input: $input) {
      id
      plans {
        id
        status
        name
        nameTranslationKey
        description
        descriptionTranslationKey
        externalId {
          id
          productProvider
        }
        price {
          amount
          currencyCode
        }
        recurringInterval {
          intervalUnit
          count
        }
        trialPeriod {
          interval {
            intervalUnit
            count
          }
          description
          descriptionTranslationKey
        }
        createdAt
        updatedAt
      }
      isEnabled
      createdAt
      updatedAt
    }
  }
`;
export type UpdateSubscriptionPlanMutationFn = Apollo.MutationFunction<
  UpdateSubscriptionPlanMutation,
  UpdateSubscriptionPlanMutationVariables
>;

/**
 * __useUpdateSubscriptionPlanMutation__
 *
 * To run a mutation, you first call `useUpdateSubscriptionPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubscriptionPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubscriptionPlanMutation, { data, loading, error }] = useUpdateSubscriptionPlanMutation({
 *   variables: {
 *      planId: // value for 'planId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubscriptionPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSubscriptionPlanMutation, UpdateSubscriptionPlanMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSubscriptionPlanMutation, UpdateSubscriptionPlanMutationVariables>(
    UpdateSubscriptionPlanDocument,
    options,
  );
}
export type UpdateSubscriptionPlanMutationHookResult = ReturnType<typeof useUpdateSubscriptionPlanMutation>;
export type UpdateSubscriptionPlanMutationResult = Apollo.MutationResult<UpdateSubscriptionPlanMutation>;
export type UpdateSubscriptionPlanMutationOptions = Apollo.BaseMutationOptions<
  UpdateSubscriptionPlanMutation,
  UpdateSubscriptionPlanMutationVariables
>;
export const ArchiveSubscriptionPlanDocument = gql`
  mutation archiveSubscriptionPlan($productId: ID!, $planId: ID!) {
    archiveSubscriptionPlan(productId: $productId, planId: $planId) {
      id
      plans {
        id
        status
        name
        description
        createdAt
        updatedAt
      }
      isEnabled
      createdAt
      updatedAt
    }
  }
`;
export type ArchiveSubscriptionPlanMutationFn = Apollo.MutationFunction<
  ArchiveSubscriptionPlanMutation,
  ArchiveSubscriptionPlanMutationVariables
>;

/**
 * __useArchiveSubscriptionPlanMutation__
 *
 * To run a mutation, you first call `useArchiveSubscriptionPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveSubscriptionPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveSubscriptionPlanMutation, { data, loading, error }] = useArchiveSubscriptionPlanMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useArchiveSubscriptionPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchiveSubscriptionPlanMutation, ArchiveSubscriptionPlanMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveSubscriptionPlanMutation, ArchiveSubscriptionPlanMutationVariables>(
    ArchiveSubscriptionPlanDocument,
    options,
  );
}
export type ArchiveSubscriptionPlanMutationHookResult = ReturnType<typeof useArchiveSubscriptionPlanMutation>;
export type ArchiveSubscriptionPlanMutationResult = Apollo.MutationResult<ArchiveSubscriptionPlanMutation>;
export type ArchiveSubscriptionPlanMutationOptions = Apollo.BaseMutationOptions<
  ArchiveSubscriptionPlanMutation,
  ArchiveSubscriptionPlanMutationVariables
>;
export const WebPagesDocument = gql`
  query webPages($filter: WebPagesSearchInput) {
    webPageTranslations(filter: $filter) {
      url
      translationStatus
      processingStatus
    }
  }
`;

/**
 * __useWebPagesQuery__
 *
 * To run a query within a React component, call `useWebPagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebPagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebPagesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useWebPagesQuery(baseOptions?: Apollo.QueryHookOptions<WebPagesQuery, WebPagesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WebPagesQuery, WebPagesQueryVariables>(WebPagesDocument, options);
}
export function useWebPagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebPagesQuery, WebPagesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WebPagesQuery, WebPagesQueryVariables>(WebPagesDocument, options);
}
export type WebPagesQueryHookResult = ReturnType<typeof useWebPagesQuery>;
export type WebPagesLazyQueryHookResult = ReturnType<typeof useWebPagesLazyQuery>;
export type WebPagesQueryResult = Apollo.QueryResult<WebPagesQuery, WebPagesQueryVariables>;
export const WebPageResultDocument = gql`
  query webPageResult($input: WebPageQueryInput!) {
    webPageTranslation(input: $input) {
      url
      translationStatus
      processingStatus
      replacements {
        translations {
          sourcePhrase
          instanceOfSourcePhrase
          targetPhrase
        }
        address
      }
    }
  }
`;

/**
 * __useWebPageResultQuery__
 *
 * To run a query within a React component, call `useWebPageResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebPageResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebPageResultQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWebPageResultQuery(
  baseOptions: Apollo.QueryHookOptions<WebPageResultQuery, WebPageResultQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WebPageResultQuery, WebPageResultQueryVariables>(WebPageResultDocument, options);
}
export function useWebPageResultLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WebPageResultQuery, WebPageResultQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WebPageResultQuery, WebPageResultQueryVariables>(WebPageResultDocument, options);
}
export type WebPageResultQueryHookResult = ReturnType<typeof useWebPageResultQuery>;
export type WebPageResultLazyQueryHookResult = ReturnType<typeof useWebPageResultLazyQuery>;
export type WebPageResultQueryResult = Apollo.QueryResult<WebPageResultQuery, WebPageResultQueryVariables>;
export const CrawlerWebPageDocument = gql`
  mutation crawlerWebPage($input: CrawlWebPageInput!) {
    crawlWebPage(input: $input) {
      url
    }
  }
`;
export type CrawlerWebPageMutationFn = Apollo.MutationFunction<CrawlerWebPageMutation, CrawlerWebPageMutationVariables>;

/**
 * __useCrawlerWebPageMutation__
 *
 * To run a mutation, you first call `useCrawlerWebPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCrawlerWebPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [crawlerWebPageMutation, { data, loading, error }] = useCrawlerWebPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCrawlerWebPageMutation(
  baseOptions?: Apollo.MutationHookOptions<CrawlerWebPageMutation, CrawlerWebPageMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CrawlerWebPageMutation, CrawlerWebPageMutationVariables>(CrawlerWebPageDocument, options);
}
export type CrawlerWebPageMutationHookResult = ReturnType<typeof useCrawlerWebPageMutation>;
export type CrawlerWebPageMutationResult = Apollo.MutationResult<CrawlerWebPageMutation>;
export type CrawlerWebPageMutationOptions = Apollo.BaseMutationOptions<
  CrawlerWebPageMutation,
  CrawlerWebPageMutationVariables
>;
export const GetMeDocument = gql`
  query getMe {
    me {
      id
      email
      name
      createdAt
      role
    }
  }
`;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
}
export function useGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
}
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<GetMeQuery, GetMeQueryVariables>;
export const GetUserInfoDocument = gql`
  query getUserInfo($id: ID!) {
    user(id: $id) {
      id
      email
      name
      selectedLanguage
      sourceLanguage
      isPremiumSubscribed
    }
  }
`;

/**
 * __useGetUserInfoQuery__
 *
 * To run a query within a React component, call `useGetUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserInfoQuery(baseOptions: Apollo.QueryHookOptions<GetUserInfoQuery, GetUserInfoQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(GetUserInfoDocument, options);
}
export function useGetUserInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserInfoQuery, GetUserInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(GetUserInfoDocument, options);
}
export type GetUserInfoQueryHookResult = ReturnType<typeof useGetUserInfoQuery>;
export type GetUserInfoLazyQueryHookResult = ReturnType<typeof useGetUserInfoLazyQuery>;
export type GetUserInfoQueryResult = Apollo.QueryResult<GetUserInfoQuery, GetUserInfoQueryVariables>;
export const GetUserProfileDocument = gql`
  query getUserProfile($id: ID!) {
    user(id: $id) {
      id
      email
      name
      selectedLanguage
      sourceLanguage
      isPremiumSubscribed
      createdAt
      role
      difficultyTier
      rating {
        ratedAt
        ratingValue
      }
    }
  }
`;

/**
 * __useGetUserProfileQuery__
 *
 * To run a query within a React component, call `useGetUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserProfileQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(GetUserProfileDocument, options);
}
export function useGetUserProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(GetUserProfileDocument, options);
}
export type GetUserProfileQueryHookResult = ReturnType<typeof useGetUserProfileQuery>;
export type GetUserProfileLazyQueryHookResult = ReturnType<typeof useGetUserProfileLazyQuery>;
export type GetUserProfileQueryResult = Apollo.QueryResult<GetUserProfileQuery, GetUserProfileQueryVariables>;
export const GetUsersDocument = gql`
  query getUsers($input: GetUsersInput!) {
    getUsers(input: $input) {
      id
      name
      email
      selectedLanguage
    }
  }
`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const GetUserInaccuraciesDocument = gql`
  query getUserInaccuracies($id: ID!) {
    user(id: $id) {
      translationInaccuracies {
        word
        language
        sentenceWithoutTranslation
        translatedPhrase
        reportedUrl
        errorType
        variationId
      }
    }
  }
`;

/**
 * __useGetUserInaccuraciesQuery__
 *
 * To run a query within a React component, call `useGetUserInaccuraciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInaccuraciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInaccuraciesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserInaccuraciesQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserInaccuraciesQuery, GetUserInaccuraciesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserInaccuraciesQuery, GetUserInaccuraciesQueryVariables>(
    GetUserInaccuraciesDocument,
    options,
  );
}
export function useGetUserInaccuraciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserInaccuraciesQuery, GetUserInaccuraciesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserInaccuraciesQuery, GetUserInaccuraciesQueryVariables>(
    GetUserInaccuraciesDocument,
    options,
  );
}
export type GetUserInaccuraciesQueryHookResult = ReturnType<typeof useGetUserInaccuraciesQuery>;
export type GetUserInaccuraciesLazyQueryHookResult = ReturnType<typeof useGetUserInaccuraciesLazyQuery>;
export type GetUserInaccuraciesQueryResult = Apollo.QueryResult<
  GetUserInaccuraciesQuery,
  GetUserInaccuraciesQueryVariables
>;
export const GetUserSubscriptionDocument = gql`
  query getUserSubscription($id: ID!) {
    user(id: $id) {
      isPro
      email
      premiumSubscription {
        planDisplayName
        currentPeriodEnd
        stripeCustomerId
        stripePriceId
        isAdmin
        provider
      }
    }
    getAdminPromoCodes
    getUserAccountCreditsInfo(userId: $id) {
      userId
      totalCredits
      totalAvailableCredits
      ledger {
        amount
        description
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetUserSubscriptionQuery__
 *
 * To run a query within a React component, call `useGetUserSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSubscriptionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserSubscriptionQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserSubscriptionQuery, GetUserSubscriptionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserSubscriptionQuery, GetUserSubscriptionQueryVariables>(
    GetUserSubscriptionDocument,
    options,
  );
}
export function useGetUserSubscriptionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserSubscriptionQuery, GetUserSubscriptionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserSubscriptionQuery, GetUserSubscriptionQueryVariables>(
    GetUserSubscriptionDocument,
    options,
  );
}
export type GetUserSubscriptionQueryHookResult = ReturnType<typeof useGetUserSubscriptionQuery>;
export type GetUserSubscriptionLazyQueryHookResult = ReturnType<typeof useGetUserSubscriptionLazyQuery>;
export type GetUserSubscriptionQueryResult = Apollo.QueryResult<
  GetUserSubscriptionQuery,
  GetUserSubscriptionQueryVariables
>;
export const CreatePremiumSubscriptionForUserWithAdminCodeDocument = gql`
  mutation createPremiumSubscriptionForUserWithAdminCode($userId: String!, $promoCode: String!) {
    createPremiumSubscriptionForUserWithAdminCode(userId: $userId, promoCode: $promoCode)
  }
`;
export type CreatePremiumSubscriptionForUserWithAdminCodeMutationFn = Apollo.MutationFunction<
  CreatePremiumSubscriptionForUserWithAdminCodeMutation,
  CreatePremiumSubscriptionForUserWithAdminCodeMutationVariables
>;

/**
 * __useCreatePremiumSubscriptionForUserWithAdminCodeMutation__
 *
 * To run a mutation, you first call `useCreatePremiumSubscriptionForUserWithAdminCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePremiumSubscriptionForUserWithAdminCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPremiumSubscriptionForUserWithAdminCodeMutation, { data, loading, error }] = useCreatePremiumSubscriptionForUserWithAdminCodeMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      promoCode: // value for 'promoCode'
 *   },
 * });
 */
export function useCreatePremiumSubscriptionForUserWithAdminCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePremiumSubscriptionForUserWithAdminCodeMutation,
    CreatePremiumSubscriptionForUserWithAdminCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePremiumSubscriptionForUserWithAdminCodeMutation,
    CreatePremiumSubscriptionForUserWithAdminCodeMutationVariables
  >(CreatePremiumSubscriptionForUserWithAdminCodeDocument, options);
}
export type CreatePremiumSubscriptionForUserWithAdminCodeMutationHookResult = ReturnType<
  typeof useCreatePremiumSubscriptionForUserWithAdminCodeMutation
>;
export type CreatePremiumSubscriptionForUserWithAdminCodeMutationResult =
  Apollo.MutationResult<CreatePremiumSubscriptionForUserWithAdminCodeMutation>;
export type CreatePremiumSubscriptionForUserWithAdminCodeMutationOptions = Apollo.BaseMutationOptions<
  CreatePremiumSubscriptionForUserWithAdminCodeMutation,
  CreatePremiumSubscriptionForUserWithAdminCodeMutationVariables
>;
export const DeleteEntirelyBasedOnIdDocument = gql`
  mutation deleteEntirelyBasedOnId($id: String!) {
    deleteEntirelyBasedOnId(id: $id)
  }
`;
export type DeleteEntirelyBasedOnIdMutationFn = Apollo.MutationFunction<
  DeleteEntirelyBasedOnIdMutation,
  DeleteEntirelyBasedOnIdMutationVariables
>;

/**
 * __useDeleteEntirelyBasedOnIdMutation__
 *
 * To run a mutation, you first call `useDeleteEntirelyBasedOnIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEntirelyBasedOnIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEntirelyBasedOnIdMutation, { data, loading, error }] = useDeleteEntirelyBasedOnIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEntirelyBasedOnIdMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteEntirelyBasedOnIdMutation, DeleteEntirelyBasedOnIdMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteEntirelyBasedOnIdMutation, DeleteEntirelyBasedOnIdMutationVariables>(
    DeleteEntirelyBasedOnIdDocument,
    options,
  );
}
export type DeleteEntirelyBasedOnIdMutationHookResult = ReturnType<typeof useDeleteEntirelyBasedOnIdMutation>;
export type DeleteEntirelyBasedOnIdMutationResult = Apollo.MutationResult<DeleteEntirelyBasedOnIdMutation>;
export type DeleteEntirelyBasedOnIdMutationOptions = Apollo.BaseMutationOptions<
  DeleteEntirelyBasedOnIdMutation,
  DeleteEntirelyBasedOnIdMutationVariables
>;

