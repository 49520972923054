import React, { useEffect, useState } from 'react';

import { Experiment, Variants } from '@amplitude/experiment-js-client';
import { Box } from '@mui/material';
import { useParams } from 'react-router';

import { Typography } from 'src/components/Typography';

import { configuration } from '../../../lib/config';
import { Break } from '../../Break';
import { Table } from '../../Table';

export const Experiments = () => {
  const { id } = useParams<{ id: string }>();
  const [experiments, setExperiments] = useState<Variants>({});

  useEffect(() => {
    const fetchExperiments = async () => {
      const experimentClient = Experiment.initialize(configuration.amplitudeExperimentDeployment);
      await experimentClient.fetch({ user_id: id });

      setExperiments(experimentClient.all());
    };

    // [WOOF-2164] TODO: Add Sentry
    // eslint-disable-next-line no-console
    fetchExperiments().catch(console.error);
  }, [id]);

  const formattedExperiments = Object.keys(experiments).map(key => [key, experiments[key].value]);

  const table = experiments
    ? [['Experiment Name', 'Variant Id'], ...formattedExperiments].map(([key, value], i) => [
        <Typography variant="sm" isBold={i === 0}>
          {key}
        </Typography>,
        <Typography variant="sm" isBold={i === 0}>
          {value}
        </Typography>,
      ])
    : [];
  return (
    <Box>
      <Box px={4} py={2}>
        <Typography variant="md" font="secondary">
          Experiments
        </Typography>
      </Box>
      <Break size={0.1} />
      <Box display="flex" py={2} px={4} pb={3} flexDirection="column">
        <Table rows={table} />
      </Box>
    </Box>
  );
};
