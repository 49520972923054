import { memo } from 'react';

import { Box } from '@mui/material';
import { Switch, Route } from 'react-router';

import { RoutePath } from 'src/enums/routePath';

import { ExtensionStats } from './ExtensionStats';

export const Content = memo(() => (
  <Switch>
    <Route path={RoutePath.ContentExtensionStats}>
      <Box display="flex" py={2} px={4} pb={3} flexDirection="column">
        <ExtensionStats />
      </Box>
    </Route>
  </Switch>
));
