import { useParams } from 'react-router';

import { Tabs, Tab } from 'src/components/Tabs';
import { RoutePath } from 'src/enums/routePath';

export const ProductTabs = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <Tabs layoutId="product">
      <Tab to={RoutePath.ProductInfo.replace(':id', id)} label="Info" icon="book" />
      <Tab to={RoutePath.ProductPlans.replace(':id', id)} label="Plans" icon="usd-circle" />
    </Tabs>
  );
};
