import { LanguageId } from 'src/server/schema';

export const languageMap: Record<string, LanguageId> = {
  spanish: LanguageId.Es,
  english: LanguageId.En,
  german: LanguageId.De,
  portuguese: LanguageId.Pt,
  korean: LanguageId.Ko,
  japanese: LanguageId.Ja,
  italian: LanguageId.It,
  french: LanguageId.Fr,
};

export const toLanguageCode = (language: string): LanguageId | undefined => languageMap[language];
