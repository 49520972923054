import { memo, useState, useEffect } from 'react';

import ErrorIcon from '@mui/icons-material/Error';
import { Box, CircularProgress, Button } from '@mui/material';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { format } from 'date-fns';
import { Switch, useParams, Route, useHistory, useLocation } from 'react-router';

import { Avatar } from 'src/components/Avatar';
import { Break } from 'src/components/Break';
import { RoutePath } from 'src/enums/routePath';
import { useDeleteEntirelyBasedOnIdMutation, useGetUserProfileQuery } from 'src/server/schema';

import { Table } from '../../Table';
import { Typography } from '../../Typography';
import { NoneSelected } from '../NoneSelected';

import { Experiments } from './Experiments';
import { Languages } from './Languages';
import { Rating } from './Rating';
import { Reports } from './Reports';
import { Saved } from './Saved';
import { Subscription } from './Subscription';
import { UserTabs } from './UserTabs';

interface UserParams {
  id: string;
}

export const User = memo(() => {
  const { id } = useParams<UserParams>();
  const { data, loading, error } = useGetUserProfileQuery({ variables: { id } });
  const name = data?.user?.name ?? 'Anon';
  const email = data?.user?.email ?? id ?? '';
  const language = data?.user?.selectedLanguage ?? undefined;
  const isPremiumSubscribed = data?.user?.isPremiumSubscribed ?? undefined;
  const createAt = parseInt(data?.user?.createdAt ?? Date.now().toString(), 10);
  const role = data?.user?.role ?? 'user';
  const sourceLanguage = data?.user?.sourceLanguage ?? undefined;
  const targetLanguage = data?.user?.selectedLanguage ?? undefined;
  const rating = data?.user?.rating ?? null;
  const difficulty = data?.user?.difficultyTier ?? null;
  const history = useHistory();
  const location = useLocation();
  const [isUserNotFoundToastOpen, setIsUserNotFoundToastOpen] = useState(false);
  const [deleteEntirelyBasedOnIdMutation] = useDeleteEntirelyBasedOnIdMutation({
    variables: { id },
  });

  const wasUserNotFound = !loading && (error || !data || !data.user);

  useEffect(() => {
    if (wasUserNotFound) {
      setIsUserNotFoundToastOpen(true);
    }
  }, [wasUserNotFound]);

  // If we can't find the user in our DB, inform the admin and display no user information
  if (wasUserNotFound) {
    return (
      <>
        <NoneSelected />
        <Snackbar
          open={isUserNotFoundToastOpen}
          autoHideDuration={4000}
          onClose={() => setIsUserNotFoundToastOpen(false)}
        >
          <Alert
            style={{ display: 'flex', alignItems: 'center' }}
            elevation={6}
            icon={<ErrorIcon fontSize="inherit" />}
            variant="filled"
            severity="error"
            sx={{ width: '100%' }}
            onClose={() => setIsUserNotFoundToastOpen(false)}
          >
            The user you are trying to access does not exist.
          </Alert>
        </Snackbar>
      </>
    );
  }

  const userPath = RoutePath.User.replace(':id', id);

  // If an admin accesses a user's area, preselect the info tab for them
  if (location.pathname === userPath || location.pathname === `${userPath}/`) {
    history.push(RoutePath.UserInfo.replace(':id', id));
  }

  if (loading) {
    return (
      <Box display="flex" py={2} px={4} flexDirection="column">
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box flex="0" pr={4}>
            <Avatar email="" size={72} />
          </Box>
          <Box flex="1">
            <CircularProgress />
          </Box>
        </Box>
      </Box>
    );
  }

  const table = [
    ['Property', 'Value'],
    ['Id', id],
    ['Created At', `Created ${format(createAt, 'MMM do yyyy')}`],
    ['Role', role],
    ['Difficulty', difficulty],
    ['Subscriber', isPremiumSubscribed ? 'Yes' : 'No'],
  ].map(([key, value], i) => [
    <Typography variant="sm" isBold={i === 0}>
      {key}
    </Typography>,
    <Typography variant="sm" isBold={i === 0}>
      {value}
    </Typography>,
  ]);

  return (
    <>
      <Box display="flex" pt={2} px={4} flexDirection="column">
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box flex="0" pr={4}>
            <Avatar email={email} language={language} size={72} />
          </Box>
          <Box flex="1">
            <Typography variant="lg" font="secondary" isBold>
              {name}
            </Typography>
            <Typography variant="md">{email}</Typography>
          </Box>
        </Box>
        <Box>
          <UserTabs />
        </Box>
      </Box>
      <Break />
      <Switch>
        <Route path={RoutePath.UserInfo}>
          <Box display="flex" py={2} px={4} pb={3} flexDirection="column">
            <Table rows={table} />
          </Box>
          <Break />
          <Box display="flex" py={2} px={4} pb={3} flexDirection="column">
            <Button
              variant="contained"
              color="error"
              onClick={async () => {
                const result = await deleteEntirelyBasedOnIdMutation({
                  variables: { id },
                });
                if (result.data?.deleteEntirelyBasedOnId === true) {
                  // eslint-disable-next-line no-alert
                  window.alert('User has been deleted');
                  window.location.reload();
                }
              }}
            >
              Delete User
            </Button>
          </Box>
          <Box display="flex" py={2} px={4} flexDirection="row">
            <Languages sourceLanguage={sourceLanguage} targetLanguage={targetLanguage} />
          </Box>
          <Break />
          {rating && (
            <>
              <Box display="flex" py={2} px={4} flexDirection="row">
                <Rating value={rating.ratingValue} ratedAt={rating.ratedAt} />
              </Box>
              <Break />
            </>
          )}
        </Route>
        <Route path={RoutePath.UserSubscription}>
          <Subscription />
        </Route>
        <Route path={RoutePath.UserSaved}>
          <Saved />
        </Route>
        <Route path={RoutePath.UserReports}>
          <Reports />
        </Route>
        <Route path={RoutePath.UserErrors}>
          <Box px={4} py={2}>
            <Typography variant="md">Not yet implemented</Typography>
          </Box>
        </Route>
        <Route path={RoutePath.UserExperiments}>
          <Experiments />
        </Route>
      </Switch>
    </>
  );
});
