import { ApolloClient, InMemoryCache, from, gql } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createHttpLink } from '@apollo/client/link/http';
import { queryApi } from 'finch-graphql';

import { getConfig, ToucanEnvironments } from 'src/lib/config';

import Pack from '../../package.json';

type Result = ReturnType<Parameters<typeof onError>[0]>;

const errorLink = onError(({ networkError }): Result => {
  if (networkError && 'statusCode' in networkError) {
    // eslint-disable-next-line no-console
    console.log(`${networkError.name} [${networkError.statusCode}]: ${networkError.message}`);
  }
  return undefined;
});

const requestAuthDoc = gql`
  mutation requestAuth {
    requestAuthorization {
      token
    }
  }
`;

const cache = new InMemoryCache();

export const createApolloInstance = (env: ToucanEnvironments) => {
  const config = getConfig(env);
  const httpLink = createHttpLink({
    uri: config.apiEndpoint,
  });

  /**
   * Admin authLink
   * This authLink does not store the auth token or have a mechanism for logging in. This is
   * by design. The way you login to admin is to have a admin user from the extension and then
   * the extension passes authentication to admin.
   */
  const authLink = setContext(async (_, { headers }: { headers?: { [key: string]: string } }) => {
    // NOTE: Pull token from extension zero auth here.
    const { data, errors } = await queryApi<{ requestAuthorization: { token: string } }>(
      requestAuthDoc,
      {},
      { id: config.extensionId, messageKey: config.messageKey },
    );

    if (errors && errors?.length) {
      throw new Error(errors[0].message);
    }

    const authToken = data?.requestAuthorization?.token;

    if (!authToken) {
      throw new Error('Unable to login');
    }

    return {
      headers: {
        ...headers,
        authorization: authToken ? `Bearer ${authToken}` : headers?.authorization ?? '',
      },
    };
  });

  const link = from([errorLink, authLink, httpLink]);
  return new ApolloClient({
    // Provide required constructor fields
    cache,
    link,

    // Provide some optional constructor fields
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
      query: {
        fetchPolicy: 'no-cache',
      },
    },
    name: Pack.name,
    version: Pack.version,
  });
};
