import React from 'react';

import { css } from '@emotion/css';
import { ToucanColors, ToucanComponents } from '@jointoucan/toucan-design';
import { Box } from '@mui/material';

import { Typography } from '../../Typography';

const { useColorScheme, ToucanIcon } = ToucanComponents;

interface ContentCardProps {
  id: string;
  onClick?: (id: string) => void;
  active?: boolean;
  subtitle?: string;
  title: string;
}

export const ContentCard: React.FC<ContentCardProps> = ({ id, onClick, active, subtitle, title }) => {
  const { isDarkMode } = useColorScheme();
  const borderColor = isDarkMode ? ToucanColors.gray[600] : ToucanColors.gray[100];
  const activeColor = isDarkMode ? ToucanColors.gray[600] : ToucanColors.gray[100];

  return (
    <Box
      onClick={() => {
        onClick?.(id);
      }}
      display="flex"
      flexDirection="row"
      alignItems="center"
      px={4}
      py={2}
      boxShadow={`inset 0px -0.5px 0px ${borderColor}, inset 0px 0.5px 0px ${borderColor}, inset -1px 0px 0px ${borderColor}`}
      bgcolor={active ? activeColor : 'transparent'}
      className={css({ cursor: 'pointer' })}
    >
      <Box flex="1">
        <Typography variant="md" font="secondary" className={css({ whiteSpace: 'nowrap' })}>
          {title}
        </Typography>
        <Typography variant="xs">{subtitle}</Typography>
      </Box>
      <ToucanIcon icon="chevron-right" className={css({ color: borderColor })} />
    </Box>
  );
};
