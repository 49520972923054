import { Box } from '@mui/material';

import { Break } from 'src/components/Break';
import { Typography } from 'src/components/Typography';

export const Saved = () => (
  <Box>
    <Box px={4} py={2}>
      <Typography variant="md" font="secondary">
        Saved words
      </Typography>
    </Box>
    <Break size={0.1} />

    <Box px={4} py={2}>
      <Typography variant="sm">User saved words not implemented</Typography>
    </Box>
  </Box>
);
