import { FC } from 'react';

import { Box } from '@mui/material';

import { Break } from '../Break';

import { EnvSwitcher } from './EnvSwitcher';
import { HeaderTabs } from './HeaderTabs';

export const Header: FC = () => (
  <>
    <Box px={4} display="flex" flexDirection="row" alignItems="flex-end">
      <HeaderTabs />
      <Box flex="1" />
      <EnvSwitcher />
    </Box>
    <Break />
  </>
);
