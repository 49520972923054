import * as React from 'react';
import { useState } from 'react';

import { ToucanColors, ToucanComponents } from '@jointoucan/toucan-design';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';
import { useParams } from 'react-router';

import { Table } from 'src/components/Table';
import { Typography } from 'src/components/Typography';
import { SubscriptionPlan, SubscriptionPlanStatus, useArchiveSubscriptionPlanMutation } from 'src/server/schema';

import { formatCurrency } from '../../../../lib/utils';

const { Button, useColorScheme } = ToucanComponents;

interface PlansAccordionsProps {
  plans: SubscriptionPlan[];
  onEditList: (plan: SubscriptionPlan) => void;
}

export const PlansList = ({ plans = [], onEditList }: PlansAccordionsProps) => {
  const { isDarkMode } = useColorScheme();
  const { id: productId } = useParams<{ id: string }>();
  const [expanded, setExpanded] = useState<string | false>('panel1');

  const [archiveSubscriptionPlanMutation, { error }] = useArchiveSubscriptionPlanMutation();

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const onClickEditPlan = (plan: SubscriptionPlan): void => {
    onEditList(plan);
  };

  const onClickArchivePlan = async (plan: SubscriptionPlan): Promise<void> => {
    try {
      await archiveSubscriptionPlanMutation({
        variables: {
          productId,
          planId: plan.id,
        },
      });

      if (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const getPlanRowTitle = (plan: SubscriptionPlan): string => {
    const countryCode = plan.locale.toUpperCase();
    const formattedPrice = formatCurrency(plan.price.amount, plan.price.currencyCode);
    const formattedInterval =
      plan.recurringInterval.count > 1
        ? `${plan.recurringInterval.count} ${plan.recurringInterval.intervalUnit}s`
        : plan.recurringInterval.intervalUnit;

    return `[${countryCode}] ${plan.name} - (${formattedPrice} / ${formattedInterval})`;
  };

  if (!plans.length) {
    return (
      <Box p={4}>
        <Typography variant="sm">No plans available</Typography>
      </Box>
    );
  }

  return (
    <Box p={2}>
      {plans.map((plan, index) => {
        const planRows = Object.entries(plan)
          .filter(([key]) => key !== '__typename')
          .map(([key, value]) => {
            const strValue = typeof value === 'string' ? value : JSON.stringify(value, null, 2);

            return [<Typography variant="sm">{key}</Typography>, <Typography variant="sm">{strValue}</Typography>];
          });

        planRows.unshift([
          <Typography variant="sm" isBold>
            Property
          </Typography>,
          <Typography variant="sm" isBold>
            Value
          </Typography>,
        ]);

        return (
          <Accordion
            disableGutters
            elevation={0}
            square
            key={plan.id.toString()}
            expanded={expanded === `panel${index + 1}`}
            onChange={handleChange(`panel${index + 1}`)}
            sx={theme => ({
              backgroundColor: isDarkMode ? ToucanColors.gray[600] : ToucanColors.white,
              border: isDarkMode ? `1px solid ${ToucanColors.gray[500]}` : `1px solid ${theme.palette.divider}`,
              '&:not(:last-child)': {
                borderBottom: 0,
              },
              '&:before': {
                display: 'none',
              },
            })}
          >
            <AccordionSummary
              expandIcon={
                <ArrowForwardIosSharpIcon
                  sx={{
                    color: isDarkMode ? ToucanColors.white : undefined,
                    fontSize: '0.9rem',
                  }}
                />
              }
              sx={theme => ({
                backgroundColor: isDarkMode ? ToucanColors.gray[600] : ToucanColors.gray[100],
                flexDirection: 'row-reverse',
                '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                  transform: 'rotate(90deg)',
                },
                '& .MuiAccordionSummary-content': {
                  marginLeft: theme.spacing(1),
                },
              })}
            >
              <Typography variant="sm" isBold color={isDarkMode ? ToucanColors.white : ToucanColors.gray[600]}>
                {getPlanRowTitle(plan)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={theme => ({
                padding: theme.spacing(2),
                borderTop: '1px solid rgba(0, 0, 0, .125)',
              })}
            >
              <Box display="flex" justifyContent="flex-end">
                <Button
                  size="small"
                  onClick={() => onClickEditPlan(plan)}
                  isDisabled={plan.status === SubscriptionPlanStatus.Archived}
                >
                  Edit
                </Button>
                <Box ml={1}>
                  <Button
                    size="small"
                    onClick={() => onClickArchivePlan(plan)}
                    isDisabled={plan.status === SubscriptionPlanStatus.Archived}
                  >
                    Archive
                  </Button>
                </Box>
              </Box>
              <Table rows={planRows} />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
};
