import { createContext, FC, useContext, useEffect } from 'react';

import { ApolloError } from '@apollo/client';

import { ToucanEnvironments } from 'src/lib/config';
import { useGetMeQuery } from 'src/server/schema';

interface AuthContextProps {
  isAuthorized: boolean;
  error?: ApolloError;
  role?: string;
  id?: string;
  email?: string;
  name?: string;
  env: ToucanEnvironments;
  onEnvChange: (env: ToucanEnvironments) => void;
}

const AuthContext = createContext<AuthContextProps>({
  isAuthorized: false,
  env: ToucanEnvironments.Staging,
  onEnvChange: () => {},
});

export const useAuthorization = () => useContext(AuthContext);

export const Authorization: FC<{ env: ToucanEnvironments; onEnvChange: (env: ToucanEnvironments) => void }> = ({
  children,
  env,
  onEnvChange,
}) => {
  const { data, loading, error, refetch } = useGetMeQuery();

  useEffect(() => {
    refetch();
  }, [env]);

  const role = data?.me?.role;
  const id = data?.me?.id;
  const email = data?.me?.email ?? undefined;
  const name = data?.me?.name ?? undefined;

  if (loading) return null;

  return (
    <AuthContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        isAuthorized: role === 'admin',
        error,
        role,
        id,
        email: email ?? undefined,
        name: name ?? undefined,
        env,
        onEnvChange,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
