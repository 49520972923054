export enum ToucanEnvironments {
  Development = 'development',
  Staging = 'staging',
  Production = 'production',
  Test = 'test',
}
export const toucanEnv = process.env.REACT_APP_TOUCAN_ENV as ToucanEnvironments;

interface Configuration {
  extensionId: string;
  messageKey: string;
  apiEndpoint: string;
  amplitudeExperimentDeployment: string;
}

const messageKey = 'Tanager-message';

/**
 * configurations should only hold env specific keys
 * most secret keys should live in env variables
 */
export const configurations: Record<ToucanEnvironments, Configuration> = {
  [ToucanEnvironments.Production]: {
    apiEndpoint: 'https://toucanapi.com/graphql',
    extensionId: 'lokjgaehpcnlmkebpmjiofccpklbmoci',
    messageKey,
    amplitudeExperimentDeployment: 'client-hw34wxmmAkHi20H6SgJooW1JWXWomPJq',
  },
  [ToucanEnvironments.Staging]: {
    apiEndpoint: 'https://stage.toucanapi.com/graphql',
    extensionId: 'cljaifpdjhboeidmhflhcmefpdoohfbl',
    messageKey,
    amplitudeExperimentDeployment: 'client-0FWqSBbBCL02gNhyainGdQT2Je3wuYJ6',
  },
  [ToucanEnvironments.Development]: {
    apiEndpoint: 'http://localhost:4000/graphql',
    extensionId: 'aodfpolajpmokkkmkagaimpdmoobhkfe',
    messageKey,
    amplitudeExperimentDeployment: 'client-0FWqSBbBCL02gNhyainGdQT2Je3wuYJ6',
  },
  [ToucanEnvironments.Test]: {
    apiEndpoint: 'http://localhost:4000/graphql',
    extensionId: 'aodfpolajpmokkkmkagaimpdmoobhkfe',
    messageKey,
    amplitudeExperimentDeployment: '',
  },
};

export const getConfig = (env: ToucanEnvironments) => configurations[env] ?? configurations.staging;

export const configuration = configurations[toucanEnv] ?? configurations.staging;
