import { useState, useEffect } from 'react';

import { ToucanColors, ToucanComponents } from '@jointoucan/toucan-design';
import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Paper,
} from '@mui/material';
import { startCase } from 'lodash';
import { Controller, useForm, useFieldArray } from 'react-hook-form';

import { Typography } from 'src/components/Typography';
import { getDialogStyling } from 'src/functions/getDialogStyling';
import {
  UpdateSubscriptionProductInput,
  ProductProvider,
  useUpdateSubscriptionProductMutation,
} from 'src/server/schema';

import { SubscriptionProductInfo } from './Product';

const { TextField, Button, Select, ToucanIcon, useColorScheme } = ToucanComponents;

export type ProductDraft = Omit<UpdateSubscriptionProductInput, 'addOns' | 'isEnabled'>;

interface EditProductDialogProps extends Omit<DialogProps, 'onSubmit'> {
  onCancel: () => void;
  onSubmit: ({ productId }: { productId?: string }) => Promise<void>;
  product: SubscriptionProductInfo;
}

export const EditProductDialog = ({ onCancel, onSubmit, product, ...props }: EditProductDialogProps) => {
  const [updateSubscriptionProduct, { loading, error }] = useUpdateSubscriptionProductMutation();
  const { isDarkMode, textError } = useColorScheme();
  const [globalErrorMessage, setGlobalErrorMessage] = useState<string | null>(null);

  const {
    formState: { errors, isDirty },
    handleSubmit: updateSubmitHandler,
    control,
    register,
    reset,
    setValue,
    watch,
  } = useForm<ProductDraft>({
    defaultValues: {
      description: product.description,
      descriptionTranslationKey: product.descriptionTranslationKey,
      name: product.name,
      nameTranslationKey: product.nameTranslationKey,
      externalIds: product.externalIds,
    },
    shouldUnregister: true,
  });

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'externalIds',
  });

  const externalIdsFormValue = watch('externalIds');

  useEffect(() => {
    if (!externalIdsFormValue || externalIdsFormValue.length < 2) {
      return;
    }

    const firstExternalIdProvider = externalIdsFormValue[0].productProvider;

    if (externalIdsFormValue.length === 2 && firstExternalIdProvider) {
      setValue(
        'externalIds.1.productProvider',
        firstExternalIdProvider === ProductProvider.Stripe ? ProductProvider.PayPal : ProductProvider.Stripe,
      );
    }
  }, [externalIdsFormValue]);

  const handleSubmitted = updateSubmitHandler(async draft => {
    try {
      setGlobalErrorMessage(null);

      const { data } = await updateSubscriptionProduct({
        variables: {
          input: {
            description: draft.description,
            descriptionTranslationKey: draft.descriptionTranslationKey,
            name: draft.name,
            nameTranslationKey: draft.nameTranslationKey,
            externalIds: draft.externalIds!.map(externalIdInfo => ({
              id: externalIdInfo.id,
              productProvider: externalIdInfo.productProvider,
            })),
          },
          productId: product.id,
        },
      });

      if (error) {
        throw new Error(error.message);
      }

      if (!data) {
        throw new Error(`[EDIT_PRODUCT_DIALOG] No product data returned from update mutation`);
      }

      const updatedProduct = data.updateSubscriptionProduct;
      await onSubmit({ productId: updatedProduct.id });

      reset({
        description: updatedProduct.description,
        descriptionTranslationKey: updatedProduct.descriptionTranslationKey,
        name: updatedProduct.name,
        nameTranslationKey: updatedProduct.nameTranslationKey,
        externalIds: updatedProduct.externalIds,
      });
    } catch (e: any) {
      const message = e && e.message ? e.message : 'Request failed';
      setGlobalErrorMessage(message);
    }
  });

  return (
    <Dialog onClose={onCancel} maxWidth="sm" fullWidth sx={getDialogStyling(isDarkMode)} {...props}>
      <DialogTitle component="div">
        <Typography variant="lg" font="secondary">
          Edit a Product
        </Typography>
        <Box mt={1}>
          <Typography variant="sm" color={ToucanColors.gray[400]}>
            Change third-party product details
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mt={1.25} display="flex" gap={1}>
          <Box flex={1}>
            <TextField
              label="Name"
              hasError={!!errors.name}
              helperText={errors.name?.message}
              {...register('name', {
                required: 'Name is required',
              })}
            />
          </Box>
          <Box flex={1}>
            <TextField
              label="Name Translation Key"
              hasError={!!errors.nameTranslationKey}
              helperText={errors.nameTranslationKey?.message}
              {...register('nameTranslationKey', {
                required: 'Name Translation Key is required',
              })}
            />
          </Box>
        </Box>

        <Box mt={1.25} display="flex" gap={1}>
          <Box flex={1}>
            <TextField
              label="Description"
              hasError={!!errors.description}
              helperText={errors.description?.message}
              {...register('description', {
                required: 'Description is required',
              })}
            />
          </Box>
          <Box flex={1}>
            <TextField
              label="Description Translation Key"
              hasError={!!errors.descriptionTranslationKey}
              helperText={errors.descriptionTranslationKey?.message}
              {...register('descriptionTranslationKey', {
                required: 'Description Translation Key is required',
              })}
            />
          </Box>
        </Box>

        <Paper sx={{ mt: 2, p: 2 }} variant="outlined">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="md" isBold>
              External IDs
            </Typography>
            {(!externalIdsFormValue || externalIdsFormValue.length < 2) && (
              <Button size="small" onClick={() => append({ id: '', productProvider: '' as ProductProvider })}>
                + Add new External ID
              </Button>
            )}
          </Box>

          {fields.map(({ id }, index) => {
            const isEditable = index > product.externalIds.length - 1;

            return (
              <Box key={id} mt={1.25} display="flex" gap={1.25}>
                <Box flex={1} sx={{ '> div': { width: '100%' } }}>
                  <Controller
                    name={`externalIds.${index}.productProvider`}
                    control={control}
                    rules={{ required: 'Product provider is required' }}
                    render={({ field }) =>
                      // NOTE: React hook form doesn't support disabling controlled
                      // select components at this time.. hence the conditional + TextField
                      //  https://github.com/react-hook-form/react-hook-form/issues/2826
                      isEditable ? (
                        <Select
                          {...field}
                          disabled={!isEditable}
                          label="Provider"
                          emptyLabel="Select One"
                          isEmptyOptionVisible
                          hasError={!!errors.externalIds?.[index]?.productProvider}
                          helperText={errors.externalIds?.[index]?.productProvider?.message}
                          options={Object.values(ProductProvider)
                            .filter(provider => {
                              // Filter out providers that are already in the form
                              if (!externalIdsFormValue) {
                                return true;
                              }
                              const isProviderAlreadyInForm = externalIdsFormValue.find(
                                externalIdInfo => externalIdInfo.productProvider === provider,
                              );
                              return (
                                !isProviderAlreadyInForm || provider === externalIdsFormValue[index].productProvider
                              );
                            })
                            .map(value => ({
                              label: startCase(value),
                              value,
                            }))}
                        />
                      ) : (
                        <TextField
                          {...field}
                          disabled={!isEditable}
                          label="Provider"
                          hasError={!!errors.externalIds?.[index]?.productProvider}
                          helperText={errors.externalIds?.[index]?.productProvider?.message}
                        />
                      )
                    }
                  />
                </Box>
                <Box flex={1}>
                  <TextField
                    disabled={!isEditable}
                    label="ID"
                    hasError={!!errors.externalIds?.[index]?.id}
                    helperText={errors.externalIds?.[index]?.id?.message}
                    {...register(`externalIds.${index}.id`, {
                      required: 'ID is required',
                    })}
                  />
                </Box>
                {isEditable && (
                  <Box
                    alignItems="center"
                    display="flex"
                    flex={0.1}
                    justifyContent="center"
                    onClick={() => remove(index)}
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    <ToucanIcon icon="times" color={textError} />
                  </Box>
                )}
              </Box>
            );
          })}
        </Paper>

        {globalErrorMessage && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {globalErrorMessage}
          </Alert>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          px: 2.5,
          pt: 0,
          pb: 2,
        }}
      >
        <Button type="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={handleSubmitted} disabled={loading || !isDirty} endIcon={loading ?? <CircularProgress />}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
