import { ChangeEvent, memo, useEffect, useState } from 'react';

import { ToucanColors, ToucanComponents } from '@jointoucan/toucan-design';
import { Box, debounce } from '@mui/material';
import { useHistory } from 'react-router';

import { RoutePath } from 'src/enums/routePath';
import { useRouteParams } from 'src/hooks/useRouterParams';
import { useGetUsersQuery } from 'src/server/schema';

import { Typography } from '../../Typography';

import { UserCard } from './UserCard';

const { useColorScheme, TextField } = ToucanComponents;

export const UserSidebar = memo(() => {
  const { isDarkMode } = useColorScheme();
  const { id: selectedId } = useRouteParams<{ id: string }>(RoutePath.User);
  const [search, setSearch] = useState<string | undefined>();
  const [searchValue, setSearchValue] = useState<string>('');
  const history = useHistory();
  const { data, loading } = useGetUsersQuery({ variables: { input: { limit: 20, search } } });

  const users = data?.getUsers ?? [];

  const onUserSelect = (id: string) => {
    history.push(RoutePath.UserInfo.replace(':id', id));
  };

  const [onSetSearch] = useState(() =>
    debounce((value: string) => {
      setSearch(value || undefined);
    }, 500),
  );

  useEffect(() => {
    onSetSearch(searchValue);
  }, [searchValue, onSetSearch]);

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const borderColor = isDarkMode ? ToucanColors.gray[600] : ToucanColors.gray[100];

  return (
    <>
      <Box px={4} py={2} boxShadow={`inset 0px -1px 0px ${borderColor}`}>
        <TextField
          size="medium"
          label="Search by email"
          isDarkMode={isDarkMode}
          value={searchValue}
          onChange={onSearchChange}
        />
      </Box>
      {users.map(({ id, email, name, selectedLanguage }) => (
        <UserCard
          id={id}
          email={email ?? undefined}
          name={name ?? 'Anonymous Toucan'}
          language={selectedLanguage ?? undefined}
          key={id}
          onClick={onUserSelect}
          active={id === selectedId}
        />
      ))}
      {!loading && users.length === 0 && (
        <Box px={4} py={2}>
          <Typography variant="md" font="secondary">
            No users found
          </Typography>
        </Box>
      )}
      {loading && users.length === 0 && (
        <Box px={4} py={2}>
          <Typography variant="md" font="secondary">
            Loading search...
          </Typography>
        </Box>
      )}
    </>
  );
});
