import { css } from '@emotion/css';
import { ToucanColors, ToucanComponents } from '@jointoucan/toucan-design';
import { Sell } from '@mui/icons-material';
import { Box } from '@mui/material';

import { SubscriptionProduct } from 'src/server/schema';

import { Typography } from '../../Typography';

const { useColorScheme, ToucanIcon } = ToucanComponents;

export interface ProductCardProps {
  isActive?: boolean;
  onClick?: (product: SubscriptionProduct) => void;
  product: SubscriptionProduct;
}

export const ProductCard = ({ isActive = false, onClick = () => {}, product }: ProductCardProps) => {
  const { isDarkMode } = useColorScheme();

  const borderColor = isDarkMode ? ToucanColors.gray[600] : ToucanColors.gray[100];
  const activeColor = isDarkMode ? ToucanColors.gray[600] : ToucanColors.gray[100];

  return (
    <Box
      bgcolor={isActive ? activeColor : 'transparent'}
      alignItems="center"
      boxShadow={`inset 0px -0.5px 0px ${borderColor}, inset 0px 0.5px 0px ${borderColor}, inset -1px 0px 0px ${borderColor}`}
      display="flex"
      flexDirection="row"
      onClick={() => onClick(product)}
      py={2}
      px={4}
      className={css({ cursor: 'pointer' })}
    >
      <Sell sx={{ color: isDarkMode ? ToucanColors.white : undefined }} />
      <Box flex="1" px={2}>
        <Typography variant="md" font="secondary" className={css({ whiteSpace: 'nowrap' })}>
          {product.name}
        </Typography>
        <Typography variant="xs">{product.description}</Typography>
      </Box>
      <ToucanIcon
        icon="chevron-right"
        className={css({ color: isDarkMode ? ToucanColors.white : ToucanColors.gray[100] })}
      />
    </Box>
  );
};
