import React from 'react';

import { ToucanColors, ToucanComponents, TypographyProps } from '@jointoucan/toucan-design';

const { Typography: ToucanTypography, useColorScheme } = ToucanComponents;

export const Typography: React.FC<TypographyProps> = ({ color, ...props }) => {
  const { isDarkMode } = useColorScheme();
  return <ToucanTypography color={color ?? (isDarkMode ? ToucanColors.white : ToucanColors.gray[500])} {...props} />;
};
