import React, { FC } from 'react';

import { ToucanComponents } from '@jointoucan/toucan-design';
import { Box } from '@mui/material';
import { format } from 'date-fns';

import { AccountCreditLedgerRecord } from '../../../server/schema';
import { Table } from '../../Table';

const { Typography, Modal } = ToucanComponents;

interface AccountCreditsLedgerModalProps {
  isOpen: boolean;
  ledger?: AccountCreditLedgerRecord[];
  onClose: () => void;
}

export const AccountCreditsModal: FC<AccountCreditsLedgerModalProps> = ({ isOpen, ledger = [], onClose }) => {
  const sortedLedger: AccountCreditLedgerRecord[] = ledger
    ? [...ledger].sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
    : [];
  const rows = [
    ['Created At', 'Credit Amount', 'Description'],
    ...sortedLedger.map(({ createdAt, amount, description }) => [
      format(new Date(createdAt), 'MM-dd-yyyy hh:mm:SS a'),
      amount,
      description,
    ]),
  ];

  return (
    <Modal isOpen={isOpen} isCloseButtonVisible onClose={onClose}>
      <Box>
        <Box py={2}>
          <Typography variant="md" font="secondary" isBold>
            Ledger
          </Typography>
        </Box>
        <Table rows={rows} />
      </Box>
    </Modal>
  );
};
