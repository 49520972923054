import { useState } from 'react';

import { css } from '@emotion/css';
import { ToucanColors, ToucanComponents } from '@jointoucan/toucan-design';
import { Box, Popover } from '@mui/material';

import { ToucanEnvironments } from 'src/lib/config';

import { useAuthorization } from '../Authorization';
import { Avatar } from '../Avatar';
import { Typography } from '../Typography';

const { useColorScheme } = ToucanComponents;

export const EnvSwitcher = () => {
  const { isDarkMode } = useColorScheme();
  const [showPopover, setPopoverVisibility] = useState(false);
  const environments: ToucanEnvironments[] = Object.values(ToucanEnvironments);
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const { env: currentEnv, onEnvChange, email, name } = useAuthorization();

  const ref = (el: HTMLElement | null) => setAnchor(el);

  const onUpdateEnv = (env: ToucanEnvironments) => {
    // Update env
    onEnvChange(env);
    setPopoverVisibility(false);
  };

  return environments.length > 1 ? (
    <>
      <Box
        component="button"
        tabIndex={-1}
        py={1}
        onClick={() => setPopoverVisibility(true)}
        display="inline-flex"
        flexDirection="row"
        alignItems="center"
        className={css({
          cursor: 'pointer',
          border: 'none',
          background: 'transparent',
          textAlign: 'right',
        })}
        // @ts-ignore
        ref={ref}
      >
        <Box>
          <Typography variant="sm" isBold>
            {name ?? 'Not logged in'}
          </Typography>
          <Typography variant="xs">{currentEnv}</Typography>
        </Box>
        <Box pl={2}>
          <Avatar email={email} size={45} />
        </Box>
      </Box>

      {anchor && (
        <Popover
          id="display-language-switcher"
          open={showPopover}
          anchorEl={anchor}
          onClose={() => {
            setPopoverVisibility(false);
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          className={css({
            '> .MuiPopover-paper': {
              maxWidth: '300px',
              backgroundColor: isDarkMode ? ToucanColors.gray[600] : ToucanColors.gray[100],
              border: `1px solid ${isDarkMode ? ToucanColors.gray[500] : ToucanColors.white}`,
              borderRadius: '8px',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
            },
          })}
        >
          <Box>
            <Box py={2} px={2} borderBottom={`1px solid ${isDarkMode ? ToucanColors.gray[500] : ToucanColors.white}`}>
              <Typography variant="xs" isBold>
                Change Environment
              </Typography>
            </Box>
            <Box py={1} borderBottom={`1px solid ${isDarkMode ? ToucanColors.gray[500] : ToucanColors.white}`}>
              {environments.map(env => (
                <Box
                  py={1}
                  px={2}
                  key={env}
                  display="flex"
                  flexDirection="row"
                  onClick={() => {
                    onUpdateEnv(env as ToucanEnvironments);
                  }}
                  className={css({
                    cursor: 'pointer',
                  })}
                >
                  <Typography variant="xs" isBold>
                    {env}
                  </Typography>
                </Box>
              ))}
            </Box>
            <Box
              py={2}
              px={2}
              borderBottom={`1px solid ${isDarkMode ? ToucanColors.gray[500] : ToucanColors.white}`}
              className={css({
                backgroundColor: isDarkMode ? ToucanColors.teal[500] : ToucanColors.teal[200],
              })}
            >
              <Typography variant="xs">Your login is based on the user of your local extension</Typography>
            </Box>
          </Box>
        </Popover>
      )}
    </>
  ) : null;
};
