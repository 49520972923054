import { FC } from 'react';

import { ToucanColors, ToucanComponents } from '@jointoucan/toucan-design';
import { Box, BoxProps } from '@mui/material';

const { useColorScheme } = ToucanComponents;

export const Break: FC<{ size?: number } & BoxProps> = ({ size = 1, ...boxProps }) => {
  const { isDarkMode } = useColorScheme();
  return <Box pb={size} bgcolor={isDarkMode ? ToucanColors.gray[600] : ToucanColors.gray[100]} {...boxProps} />;
};
