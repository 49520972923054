import React from 'react';

import { css } from '@emotion/css';
import { ToucanColors, ToucanComponents } from '@jointoucan/toucan-design';
import { Box } from '@mui/material';

import { Avatar } from '../../Avatar';
import { Typography } from '../../Typography';

const { useColorScheme, ToucanIcon } = ToucanComponents;

interface UserCardProps {
  id: string;
  onClick?: (id: string) => void;
  active?: boolean;
  email?: string;
  language?: string;
  name: string;
}

export const UserCard: React.FC<UserCardProps> = ({ id, onClick, active, email, language, name }) => {
  const { isDarkMode } = useColorScheme();

  const borderColor = isDarkMode ? ToucanColors.gray[600] : ToucanColors.gray[100];
  const activeColor = isDarkMode ? ToucanColors.gray[600] : ToucanColors.gray[100];

  return (
    <Box
      onClick={() => {
        onClick?.(id);
      }}
      display="flex"
      flexDirection="row"
      alignItems="center"
      px={4}
      py={2}
      boxShadow={`inset 0px -0.5px 0px ${borderColor}, inset 0px 0.5px 0px ${borderColor}, inset -1px 0px 0px ${borderColor}`}
      bgcolor={active ? activeColor : 'transparent'}
      className={css({ cursor: 'pointer' })}
    >
      <Avatar email={email} language={language} size={56} />
      <Box flex="1" px={2}>
        <Typography variant="md" font="secondary" className={css({ whiteSpace: 'nowrap' })}>
          {name}
        </Typography>
        <Typography variant="xs">{email}</Typography>
      </Box>
      <ToucanIcon icon="chevron-right" className={css({ color: borderColor })} />
    </Box>
  );
};
