import { FC } from 'react';

import { css, cx } from '@emotion/css';

import { toLanguageCode } from 'src/lib/language';
import { LanguageId } from 'src/server/schema';

interface FlagProps {
  language: string | LanguageId;
  size?: number;
  className?: string;
}

export const Flag: FC<FlagProps> = ({ language, size = 32, className }) => {
  const languageCode = language.length === 2 ? language : toLanguageCode(language);
  return (
    <img
      src={`/images/flags/round/${languageCode}.svg`}
      alt={`${languageCode} flag`}
      className={cx(
        css({
          width: `${size}px`,
          height: `${size}px`,
        }),
        className,
      )}
    />
  );
};
