import React from 'react';

import { css } from '@emotion/css';
import { ToucanColors, ToucanComponents } from '@jointoucan/toucan-design';
import { Box } from '@mui/material';

import { getFaviconUrl } from 'src/lib/favico';
import { getTranslationPalette } from 'src/lib/translation';
import { TranslationStatus, WebPageProcessingStatus } from 'src/server/schema';

import { Typography } from '../../Typography';

const { useColorScheme, ToucanIcon } = ToucanComponents;

interface TranslationCardProps {
  id: string;
  onClick?: (id: string) => void;
  active?: boolean;
  url: string;
  translationStatus: TranslationStatus;
  processingStatus: WebPageProcessingStatus;
}

export const TranslationCard: React.FC<TranslationCardProps> = ({
  id,
  onClick,
  active,
  url,
  translationStatus,
  processingStatus,
}) => {
  const { isDarkMode } = useColorScheme();
  const { hostname, pathname } = new URL(url);

  const borderColor = isDarkMode ? ToucanColors.gray[600] : ToucanColors.gray[100];
  const activeColor = isDarkMode ? ToucanColors.gray[600] : ToucanColors.gray[100];

  return (
    <Box
      onClick={() => {
        onClick?.(id);
      }}
      display="flex"
      flexDirection="row"
      alignItems="center"
      px={4}
      py={2}
      boxShadow={`inset 0px -0.5px 0px ${borderColor}, inset 0px 0.5px 0px ${borderColor}, inset -1px 0px 0px ${borderColor}`}
      bgcolor={active ? activeColor : 'transparent'}
      className={css({ cursor: 'pointer' })}
    >
      <Box position="relative" borderRadius={56} width="56px" height="56px">
        <Box
          component="img"
          /* @ts-ignore */
          src={getFaviconUrl(hostname, 56 * 2)}
          borderRadius={56}
          width="56px"
          height="56px"
          mr={1}
        />
        <Box
          borderRadius={12}
          width="12px"
          height="12px"
          position="absolute"
          bottom={0}
          right={5}
          bgcolor={getTranslationPalette({ translationStatus, processingStatus })[300]}
          zIndex={0}
        />
      </Box>
      <Box flex="1" px={2} overflow="hidden">
        <Box display="flex" alignItems="center">
          <Typography variant="xs">{hostname}</Typography>
        </Box>
        <Typography
          variant="sm"
          font="secondary"
          className={css({ whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: 'auto', overflow: 'hidden' })}
        >
          {pathname.substr(1)}
        </Typography>
      </Box>
      <ToucanIcon icon="chevron-right" className={css({ color: borderColor })} />
    </Box>
  );
};
