import React, { ChangeEvent, useState } from 'react';

import { css } from '@emotion/css';
import { ToucanColors, ToucanComponents } from '@jointoucan/toucan-design';
import { Box } from '@mui/material';
import { format } from 'date-fns';
import { useParams } from 'react-router';
import { parse } from 'tinyduration';

import { Break } from 'src/components/Break';
import { Typography } from 'src/components/Typography';
import {
  useGetUserSubscriptionQuery,
  useCreatePremiumSubscriptionForUserWithAdminCodeMutation,
} from 'src/server/schema';

import { Table } from '../../Table';

import { AccountCreditsModal } from './AccountCreditsLedgerModal';

const { Button, Select, useColorScheme } = ToucanComponents;

const MAX_DATE = new Date(8640000000000000);

const promotionDurationMap: Record<string, string> = {
  P1W: '1 Week',
  P30D: '30 Days',
  P1M: '1 Month',
  P2M: '2 Months',
  P3M: '3 Months',
  P4M: '4 Months',
  P5M: '5 Months',
  P6M: '6 Months',
  P7M: '7 Months',
  P8M: '8 Months',
  P9M: '9 Months',
  P10M: '10 Months',
  P11M: '11 Months',
  P1Y: '1 Year',
};

const generatePromoCodeLabel = (promoCode: string) => {
  const [, duration] = promoCode.split('-');
  return promotionDurationMap[duration] ?? duration;
};

const durationInMilliseconds = (input: string) => {
  const { years = 0, months = 0, weeks = 0, days = 0 } = parse(input);

  const dayInMilliseconds = 24 * 60 * 60 * 1000;
  return (
    years * 365 * dayInMilliseconds +
    months * 30 * dayInMilliseconds +
    weeks * 7 * dayInMilliseconds +
    days * dayInMilliseconds
  );
};

type CreateSubscriptionProps = {
  onSubmit: (promoCode: string | undefined) => Promise<void>;
  loading: boolean;
  errorMessage?: string;
  promoCodes: string[];
};
const CreateSubscription = ({ onSubmit, loading, errorMessage, promoCodes }: CreateSubscriptionProps) => {
  const { isDarkMode } = useColorScheme();
  const [promoCode, setPromoCode] = useState<string>();

  return (
    <Box>
      <Box pb={2}>
        <Typography variant="sm">User has no current subscription.</Typography>
      </Box>
      <Break size={1} mx={-4} />
      <Box py={2} pb={1} display="flex" flexDirection="column">
        <Box pb={2}>
          <Typography variant="md" font="secondary">
            Gift subscription to learner.
          </Typography>
        </Box>

        {errorMessage ? (
          <Box pb={2}>
            <Typography
              variant="sm"
              color={ToucanColors.red[300]}
            >{`An error occurred when creating subscription: ${errorMessage}`}</Typography>
          </Box>
        ) : (
          ''
        )}
        <Box
          className={css({
            label: {
              color: isDarkMode ? ToucanColors.white : ToucanColors.gray[400],
            },
          })}
        >
          <Select
            className={css({
              width: '275px',
              color: isDarkMode ? ToucanColors.white : ToucanColors.gray[400],
            })}
            label="Duration"
            value={promoCode}
            isDarkmode={isDarkMode}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setPromoCode(e.target.value);
            }}
            options={promoCodes.map(option => ({ label: generatePromoCodeLabel(option), value: option }))}
          />
        </Box>
        <Box mt={2}>
          <Button isDisabled={!promoCode || loading} onClick={async () => onSubmit(promoCode)}>
            {loading ? 'Gifting...' : `Gift Subscription`}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export const Subscription = () => {
  const { id } = useParams<{ id: string }>();
  const [showAccountCreditsModal, setShowAccountCreditsModal] = useState<boolean>(false);
  const {
    data,
    loading: subscriptionLoading,
    refetch,
  } = useGetUserSubscriptionQuery({ variables: { id }, errorPolicy: 'all' });
  const [createPremiumSubscriptionForUserWithAdminCode, { loading, error }] =
    useCreatePremiumSubscriptionForUserWithAdminCodeMutation();
  const isPro = data?.user?.isPro;
  let subscription = data?.user?.premiumSubscription;
  const promoCodes: string[] = data?.getAdminPromoCodes ?? [];
  const accountCreditsInfo = data?.getUserAccountCreditsInfo;

  const sortedPromoCodes = ([] as string[]).concat(promoCodes).sort((a, b) => {
    const durationA = durationInMilliseconds(a.split('-')[1]);
    const durationB = durationInMilliseconds(b.split('-')[1]);
    return durationA - durationB;
  });

  // Overload V1 subscriptions into premium subscription type for display
  if (!subscription && isPro) {
    subscription = {
      planDisplayName: 'V1 Pro Subscription',
      currentPeriodEnd: MAX_DATE.toISOString(),
      // No record for V1 users, but if we've been using isPro for CX purposes,
      // given the few users still on pro I think we can assume admin
      isAdmin: true,
    };
  }

  const onSubmitCreateSubscription = async (promoCode: string | undefined) => {
    const userId = id;
    if (!promoCode || loading) {
      return;
    }

    await createPremiumSubscriptionForUserWithAdminCode({
      variables: { userId, promoCode },
    });
    await refetch();
  };

  const subscriptionDetailsTable = subscription
    ? [
        ['Property', 'Value'],
        ['Provider', subscription.provider ?? 'Unknown'],
        [
          'Plan Name',
          (subscription.planDisplayName ?? 'Apple subscription') + (subscription.isAdmin ? ' (Admin)' : ''),
        ],
        [
          'Subscription ends',
          `${isPro ? 'Indefinite' : format(new Date(subscription.currentPeriodEnd), 'MMM do yyyy')}`,
        ],
        ['Stripe Customer', subscription.stripeCustomerId ?? 'No Stripe customer'],
        ['Stripe Price ID', subscription.stripePriceId ?? 'No Stripe price'],
      ].map(([key, value], i) => [
        <Typography variant="sm" isBold={i === 0}>
          {key}
        </Typography>,
        <Typography variant="sm" isBold={i === 0}>
          {value}
        </Typography>,
      ])
    : [];

  return (
    <Box>
      <Box>
        <Box px={4} py={2}>
          <Typography variant="md" font="secondary">
            Account Credits
          </Typography>
        </Box>
        <Break size={0.1} />
        <Box px={4} py={2}>
          <Typography variant="sm">Total Credits: {accountCreditsInfo?.totalCredits ?? 'N/A'}</Typography>
        </Box>
        <Box px={4}>
          <Typography variant="sm">
            Total Available Credits: {accountCreditsInfo?.totalAvailableCredits ?? 'N/A'}
          </Typography>
        </Box>
        <Box px={4} py={2}>
          <Button
            onClick={() => {
              setShowAccountCreditsModal(true);
            }}
          >
            Show Ledger
          </Button>
        </Box>
        <AccountCreditsModal
          isOpen={showAccountCreditsModal}
          ledger={accountCreditsInfo?.ledger}
          onClose={() => {
            setShowAccountCreditsModal(false);
          }}
        />
      </Box>
      <Break size={1} mx={-4} />
      <Box px={4} py={2}>
        <Typography variant="md" font="secondary">
          Users Active Subscription
        </Typography>
      </Box>
      <Break size={0.1} />
      {!subscriptionLoading && (
        <Box px={4} py={2}>
          {subscription ? (
            <Box>
              <Table rows={subscriptionDetailsTable} />
            </Box>
          ) : (
            <CreateSubscription
              onSubmit={onSubmitCreateSubscription}
              loading={loading}
              errorMessage={error?.message}
              promoCodes={sortedPromoCodes}
            />
          )}
        </Box>
      )}
    </Box>
  );
};
