import { FC } from 'react';

import { Box } from '@mui/material';

import { Flag } from 'src/components/Flag';
import { Typography } from 'src/components/Typography';
import { toLanguageCode } from 'src/lib/language';

interface LanguagesProps {
  sourceLanguage?: string;
  targetLanguage?: string;
  sourceTitle?: string;
  targetTitle?: string;
}

interface LanguageRowProps {
  language: string;
  title: string;
  subtitle: string;
}

export const LanguageRow: FC<LanguageRowProps> = ({ language, title, subtitle }) => (
  <Box display="flex" flex="1" alignItems="center">
    <Box flex="0" pr={2}>
      <Flag language={language} size={64} />
    </Box>
    <Box flex="1">
      <Typography variant="md" font="secondary">
        {title}
      </Typography>
      <Typography variant="sm" isBold>
        {subtitle}
      </Typography>
    </Box>
  </Box>
);

export const Languages: FC<LanguagesProps> = ({
  sourceLanguage,
  targetLanguage,
  sourceTitle = 'speaks',
  targetTitle = 'learning',
}) => (
  <Box display="flex" flexDirection="row" flex="1">
    {sourceLanguage && <LanguageRow language={sourceLanguage} title={sourceLanguage} subtitle={sourceTitle} />}
    {targetLanguage && (
      <LanguageRow
        language={targetLanguage}
        title={targetLanguage.length > 2 ? toLanguageCode(targetLanguage) ?? '' : targetLanguage}
        subtitle={targetTitle}
      />
    )}
  </Box>
);
