import React, { memo } from 'react';

import { Global } from '@emotion/react';
import { ToucanColors, ToucanComponents, ToucanTheme } from '@jointoucan/toucan-design';
import { Box, CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import { useAuthorization } from './Authorization';
import { Typography } from './Typography';

const { useColorScheme } = ToucanComponents;

interface LayoutProps {
  sidebarContent?: React.ReactNode;
  headerContent?: React.ReactNode;
}

const Unauthorized = () => (
  <Box display="flex" flex="1" justifyContent="center" alignItems="center">
    <Box minWidth="500px" flex="0">
      <Box pb={2}>
        <Typography variant="lg" font="secondary">
          Unable to access this page.
        </Typography>
      </Box>
      <Typography>
        Sorry you do not have access to this page. To access this page please login to your extension.
      </Typography>
    </Box>
  </Box>
);

export const Layout: React.FC<LayoutProps> = memo(({ children, sidebarContent, headerContent }) => {
  const { isDarkMode } = useColorScheme();
  const { isAuthorized } = useAuthorization();

  return (
    // NOTE: Our design lib theme does not properly update its mode, hence the useColorScheme needed,
    // so no theme.palette.mode checks in child components
    <ThemeProvider theme={ToucanTheme}>
      <link
        rel="stylesheet"
        type="text/css"
        href="https://fonts.googleapis.com/css?family=Karla:400,700|Lora:700&display=swap"
      />
      <CssBaseline />
      <Global
        styles={{
          'body, html, #root': {
            height: '100%',
            minHeight: '100%',
            maxHeight: '100%',
            width: '100%',
            minWidth: '100%',
            maxWidth: '100%',
            background: isDarkMode ? ToucanColors.gray[500] : ToucanColors.white,
          },
          '#root': {
            display: 'flex',
            flexDirection: 'row',
          },
          '*::-webkit-scrollbar': {
            width: '4px',
            height: '4px',
          },
          '*::-webkit-scrollbar-track': {
            backgroundColor: isDarkMode ? ToucanColors.gray[600] : ToucanColors.white,
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: isDarkMode ? ToucanColors.gray[400] : ToucanColors.gray[300],
            outline: `2px solid ${isDarkMode ? ToucanColors.gray[600] : ToucanColors.white}`,
            borderRadius: '4px',
          },
          '*::-webkit-scrollbar-corner': {
            backgroundColor: isDarkMode ? ToucanColors.gray[600] : ToucanColors.white,
          },
          '& [lang="ja"] *, & [lang="ko"] *, & [lang="zh"] *': {
            wordBreak: 'keep-all',
          },
        }}
      />
      <Box display="flex" flexDirection="column" width="100%">
        <Box
          component="header"
          position="sticky"
          top={0}
          bgcolor={isDarkMode ? ToucanColors.gray[500] : ToucanColors.white}
          zIndex={10}
        >
          {headerContent}
        </Box>
        <Box display="flex" flexDirection="row" width="100%" height="calc(100vh - 69px)">
          <Box component="aside" display="flex" flexDirection="column" flex="0">
            {isAuthorized ? sidebarContent : null}
          </Box>
          <Box component="main" display="flex" flexDirection="column" flex="1" height="100%" overflow="scroll">
            {isAuthorized ? children : <Unauthorized />}
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
});
