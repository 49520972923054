import { memo, useEffect, useState } from 'react';

import { ToucanColors, ToucanComponents } from '@jointoucan/toucan-design';
import { Box, debounce } from '@mui/material';
import { useHistory, matchPath, useLocation } from 'react-router';

import { RoutePath } from 'src/enums/routePath';
import { isProcessing } from 'src/lib/translation';
import { LanguageId, useCrawlerWebPageMutation, useWebPagesQuery } from 'src/server/schema';

import { Typography } from '../../Typography';

import { CrawlModal } from './CrawlModal';
import { TranslationCard } from './TranslationCard';

const { useColorScheme, Button } = ToucanComponents;

function useRouteParams<T extends {}>(path: string) {
  const { pathname } = useLocation();
  const match = matchPath(pathname, { path });
  return (match?.params || {}) as T;
}

export const TranslationSidebar = memo(() => {
  const { isDarkMode } = useColorScheme();
  const { url: encodedURL } = useRouteParams<{ url: string }>(RoutePath.Translation);
  const [search, setSearch] = useState<string | undefined>();
  const [crawlModalOpen, setCrawlModalOpen] = useState(false);
  const [searchValue] = useState<string>('');
  const history = useHistory();
  const [crawlerWebPage, { loading: crawlLoading, data: crawlData }] = useCrawlerWebPageMutation();
  const { data, loading, refetch, startPolling, stopPolling } = useWebPagesQuery({
    variables: {
      filter: {
        url: search,
        limit: 100,
      },
    },
  });

  let selectedUrl = '';
  if (encodedURL) {
    selectedUrl = decodeURIComponent(encodedURL);
  }

  const webPages = data?.webPageTranslations ?? [];
  const processingTranslation = webPages.find(webPage => isProcessing(webPage.processingStatus));

  const onWebPageSelected = (url: string) => {
    history.push(RoutePath.Translation.replace(':url', encodeURIComponent(url)));
  };

  const [onSetSearch] = useState(() =>
    debounce((value: string) => {
      setSearch(value || undefined);
    }, 500),
  );

  const crawlWebPage = async (url: string) => {
    await crawlerWebPage({
      variables: {
        input: {
          // removes any hash or query string from the URL
          url,
          sourceLanguage: LanguageId.En,
          targetLanguage: LanguageId.Es,
          isHeadless: true,
        },
      },
    });
    await refetch();
  };

  useEffect(() => {
    onSetSearch(searchValue);
  }, [searchValue, onSetSearch]);

  useEffect(() => {
    if (processingTranslation) {
      startPolling(3000);
    }
    return () => {
      stopPolling();
    };
  }, [processingTranslation, startPolling, stopPolling]);

  const borderColor = isDarkMode ? ToucanColors.gray[600] : ToucanColors.gray[100];

  return (
    <Box
      width="35vw"
      display="flex"
      flexDirection="column"
      boxShadow={`inset -1px 0px 0px ${borderColor}`}
      height="100%"
      overflow="scroll"
    >
      <Box px={4} pb={2} pt={1} display="flex" position="sticky" top={0} bgcolor={borderColor} zIndex="1">
        <Box flex="1">
          <Typography variant="md" font="secondary">
            Translations
          </Typography>
        </Box>
        <Button size="small" onClick={() => setCrawlModalOpen(true)}>
          Add New +
        </Button>
        <CrawlModal
          crawlLoading={crawlLoading || !!processingTranslation}
          crawlData={crawlData ?? undefined}
          crawlWebPage={crawlWebPage}
          open={crawlModalOpen}
          onRequestClose={() => setCrawlModalOpen(false)}
        />
      </Box>
      {processingTranslation && (
        <Box px={4} py={2} display="flex" position="sticky" top={57} bgcolor={ToucanColors.yellow[400]} zIndex="1">
          <Typography variant="sm" color={ToucanColors.yellow[600]}>
            Page currently being crawled, polling for updated status every <strong>3 seconds</strong>.
          </Typography>
        </Box>
      )}
      {webPages.map(({ url, translationStatus, processingStatus }) => (
        <TranslationCard
          translationStatus={translationStatus}
          processingStatus={processingStatus}
          id={url}
          url={url}
          key={url}
          active={selectedUrl === url}
          onClick={onWebPageSelected}
        />
      ))}
      {!loading && webPages.length === 0 && (
        <Box px={4} py={2}>
          <Typography variant="md" font="secondary">
            No web pages found
          </Typography>
        </Box>
      )}
      {loading && webPages.length === 0 && (
        <Box px={4} py={2}>
          <Typography variant="md" font="secondary">
            Loading search...
          </Typography>
        </Box>
      )}
    </Box>
  );
});
