import { ChangeEvent, memo, useState } from 'react';

import { css } from '@emotion/css';
import { ToucanColors, ToucanComponents } from '@jointoucan/toucan-design';
import { Box } from '@mui/material';

export type VoiceNumber = 0 | 1;

const { useColorScheme, TextField, Button } = ToucanComponents;
export enum Language {
  French = 'french',
  German = 'german',
  Italian = 'italian',
  Japanese = 'japanese',
  Korean = 'korean',
  Portuguese = 'portuguese',
  Spanish = 'spanish',
  English = 'english',
  Chinese = 'chinese',
  Arabic = 'arabic',
  Hebrew = 'hebrew',
  Hindi = 'hindi',
}

const TTS_URI = `https://texttospeech.googleapis.com/v1/text:synthesize?key=AIzaSyDb3tSUb-1kVtcu_LhLDAWdnmxvhWzOmCk`;
const SAMPLE_RATES = 16000;

type VoiceType = {
  language: Language;
  languageCode: string;
  name: [string, string];
};

const voices: Array<VoiceType> = [
  {
    language: Language.French,
    languageCode: 'fr-FR',
    name: ['fr-FR-Neural2-A', 'fr-FR-Neural2-B'],
  },
  {
    language: Language.German,
    languageCode: 'de-DE',
    name: ['de-DE-Neural2-A', 'de-DE-Neural2-B'],
  },
  {
    language: Language.Italian,
    languageCode: 'it-IT',
    name: ['it-IT-Neural2-A', 'it-IT-Neural2-C'],
  },
  {
    language: Language.Japanese,
    languageCode: 'ja-JP',
    name: ['ja-JP-Neural2-B', 'ja-JP-Neural2-C'],
  },
  {
    language: Language.Korean,
    languageCode: 'ko-KR',
    name: ['ko-KR-Neural2-A', 'ko-KR-Neural2-C'],
  },
  {
    language: Language.Portuguese,
    languageCode: 'pt-BR',
    name: ['pt-PT-Wavenet-A', 'pt-PT-Wavenet-B'],
  },
  {
    language: Language.Spanish,
    languageCode: 'es-ES',
    name: ['es-ES-Neural2-A', 'es-ES-Neural2-B'],
  },
  {
    language: Language.English,
    languageCode: 'en-US',
    name: ['en-US-Neural2-H', 'en-US-Neural2-D'],
  },
  {
    language: Language.Chinese,
    languageCode: 'cmn-CN',
    name: ['cmn-CN-Wavenet-A', 'cmn-CN-Wavenet-B'],
  },
  {
    language: Language.Arabic,
    languageCode: 'ar-XA',
    name: ['ar-XA-Wavenet-A', 'ar-XA-Wavenet-B'],
  },
  {
    language: Language.Hindi,
    languageCode: 'hi-IN',
    name: ['hi-IN-Neural2-A', 'hi-IN-Neural2-B'],
  },
];

const getVoice = (language: Language) => voices.find(languageVoice => languageVoice.language === language);

const createRequestOptions = (
  text: string,
  voice: VoiceType | undefined,
  playbackRate: number,
  voiceNumber: VoiceNumber,
) => {
  if (!voice) {
    return null;
  }

  return {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      input: {
        text,
      },
      voice: {
        languageCode: voice.languageCode,
        name: voice.name[voiceNumber],
        ssmlGender: voiceNumber === 0 ? 'FEMALE' : 'MALE',
      },
      audioConfig: {
        audioEncoding: 'LINEAR16',
        sampleRateHertz: SAMPLE_RATES,
        speakingRate: playbackRate,
      },
    }),
  };
};

export const AudioSidebar = memo(() => {
  const { isDarkMode } = useColorScheme();
  const [searchValue, setSearchValue] = useState<string>('');

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const handleCreateClicked = async () => {
    // @ts-ignore
    const selectedLanguage = document.getElementById('language-selection').value ?? '';
    const voice = getVoice(selectedLanguage);
    const playbackRate = 1;
    // @ts-ignore
    const voiceNumber = Number(document.getElementById('gender-selection').value ?? 0) as VoiceNumber;
    const options = createRequestOptions(searchValue, voice, playbackRate, voiceNumber);
    if (!options) {
      throw new Error(`Unable to fetch audio file for "${searchValue}" in "${selectedLanguage}"`);
    }
    const resp = await fetch(TTS_URI, options);

    if (!resp.ok) {
      throw new Error(`${resp.status} = ${resp.statusText}`);
    }

    const json = await resp.json();
    const { audioContent } = json;

    const contentType = 'audio/mpeg';
    const blob = b64toBlob(audioContent, contentType);
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', `${selectedLanguage}-${searchValue}.mp3`);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();
    // Clean up and remove the link
    link.parentNode?.removeChild(link);

    return null;
  };

  const borderColor = isDarkMode ? ToucanColors.gray[600] : ToucanColors.gray[100];
  const languages = [
    { label: 'English', value: 'english' },
    { label: 'German', value: 'german' },
    { label: 'Italian', value: 'italian' },
    { label: 'Japanese', value: 'japanese' },
    { label: 'Korean', value: 'korean' },
    { label: 'Portuguese', value: 'portuguese' },
    { label: 'Spanish', value: 'spanish' },
    { label: 'Chinese', value: 'chinese' },
    { label: 'Arabic', value: 'arabic' },
    { label: 'Hindi', value: 'hindi' },
  ];
  const genders = [
    { label: 'Female', value: 0 },
    { label: 'Male', value: 1 },
  ];

  const languageOptions = languages.map(language => <option value={language.value}>{language.label}</option>);
  const genderOptions = genders.map(gender => <option value={gender.value}>{gender.label}</option>);

  return (
    <Box px={4} py={2} boxShadow={`inset 0px -1px 0px ${borderColor}`} display="flex" flexDirection="column">
      <TextField size="medium" label="Text" isDarkMode={isDarkMode} value={searchValue} onChange={onSearchChange} />
      <select id="language-selection" className={css({ 'margin-top': '10px', height: '40px' })}>
        {languageOptions}
      </select>
      <select id="gender-selection" className={css({ 'margin-top': '10px', height: '40px' })}>
        {genderOptions}
      </select>
      <Button size="medium" onClick={handleCreateClicked} variant="contained" className={css({ 'margin-top': '10px' })}>
        Generate Audio
      </Button>
    </Box>
  );
});
